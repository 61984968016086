import { faPencil, faTrash, faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Editor from "../Component/CKeditor";
import ImageUploader from "../Component/ImageUploader";
import Sidebar from "../Component/Sidebar";
import Swal from "sweetalert2";
import axios from "axios";
import { baseUrl } from "../../assets/Utils/Ip";
import { useRef } from "react";
import { MultiSelect } from "react-multi-select-component";

export default function Addproduct() {
  const navigate = useNavigate();

  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");
  const [isChecked, setisChecked] = useState(false);
  const imageRef = useRef();
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);

  const [category, setCategory] = useState([]);

  const [selected, setSelected] = useState([]);
  const [variation, setVariation] = useState([]);

  const [product, setProduct] = useState({
    product_name: "",
    category_id: "",
    description_1: "",
    description_2: "",
    regular_price: "",
    sale_price: "",
    shadeItems: "",
    quantity: "",
    tips: "",
    gallery_images: [],
  });

  // selected quantity
  const handleCheckBox = (e) => {
    setisChecked(e.target.checked);
  };

  //sub categories useEffect
  const getAllCategory = async () => {
    try {
      const res = await axios.get(`${baseUrl}/get-all-category`);
      setCategory(res.data);
    } catch (error) {}
  };

  //sub getVariation useEffect
  const getVariation = async () => {
    try {
      const res = await axios.get(`${baseUrl}/get-all-shade`);

      const transformedData = res.data.map(
        ({ shade_id: value, shade_name: label, ...rest }) => ({
          value,
          label,
          ...rest, // include the remaining properties
        })
      );

      setVariation(transformedData);
    } catch (error) {
      // Handle error
    }
  };

  const extractIds = () => {
    let list = [];

    selected.map((item) => {
      list.push(item.value);
    });

    return list;
  };

  // validation
  let regex;

  const handleName = (value) => {
    // regex = new RegExp("^[A-Za-z0-9\\s-]+$");
    if (value.length > 32) {
      return;
    }

    setProduct({ ...product, product_name: value });
  };
  const descriptionone = (value) => {
    // regex = new RegExp("^[A-Za-z0-9\\s]+$");
    if (value.length > 200) {
      return;
    }

    setProduct({ ...product, description_1: value });
  };

  const regular = (value) => {
    // regex = new RegExp("^[0-9]+(\\.[0-9]+)?$");
    regex = new RegExp("^[0-9]*(\\.[0-9]+)?$");

    if (value.length > 10) {
      return;
    }
    if (regex.test(value) || value === "") {
      setProduct({ ...product, regular_price: value });
    }
  };

  const sale = (value) => {
    regex = new RegExp("^[0-9]+(\\.[0-9]+)?$");

    if (value.length > 10) {
      return;
    }
    if (regex.test(value) || value === "") {
      setProduct({ ...product, sale_price: value });
    }
  };

  const quantity = (value) => {
    regex = new RegExp("^[0-9]+$");
    if (value.length > 5) {
      return;
    }
    if (regex.test(value) || value === "") {
      setProduct({ ...product, quantity: value });
    }
  };

  const validateImages = async () => {
    const MAX_IMAGES = 10;
    const MAX_IMAGE_SIZE_MB = 5;
    const MIN_IMAGES = 3;
    const REQUIRED_RESOLUTION = { width: 800, height: 600 };

    if (product.gallery_images.length > MAX_IMAGES) {
      Swal.fire({
        icon: "error",
        title: `Maximum ${MAX_IMAGES} Images Required`,
        text: `Kindly add ${MAX_IMAGES} images`,
      });
      return false;
    }
    if (product.gallery_images.length < MIN_IMAGES) {
      console.log("errorr 3")
      Swal.fire({
        icon: "error",
        title: `Minimum ${MIN_IMAGES} Images Required`,
        text: `Kindly add at least ${MIN_IMAGES} images`,
      });
      return false;
    }

    const checkImage = async (image) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = URL.createObjectURL(image);

        img.onload = function () {
          if (
            img.width > REQUIRED_RESOLUTION.width ||
            img.height > REQUIRED_RESOLUTION.height
          ) {
            Swal.fire({
              icon: "error",
              title: "Invalid Image Resolution",
              text: `Image resolution should be ${REQUIRED_RESOLUTION.width}x${REQUIRED_RESOLUTION.height}`,
            });
            resolve(false);
          } else {
            resolve(true);
          }
        };
      });
    };

    for (const image of product.gallery_images) {
      if (
        // image.type &&
        image.type.includes &&
        !image.type.includes("image/png")
      ) {
        Swal.fire({
          icon: "error",
          title: "Invalid Image Format",
          text: "Images should be in PNG format",
        });
        return false;
      }

      // Check size
      if (image.size / (1024 * 1024) > MAX_IMAGE_SIZE_MB) {
        Swal.fire({
          icon: "error",
          title: "Image Size Exceeded",
          text: `Image size should not be greater than ${MAX_IMAGE_SIZE_MB} MB`,
        });
        return false;
      }

      // Check resolution
      const resolutionCheck = await checkImage(image);
      if (!resolutionCheck) {
        return false;
      }
    }

    return true;
  };

  const validatePrices = () => {
    if (parseFloat(product.sale_price) >= parseFloat(product.regular_price)) {
      Swal.fire({
        icon: "error",
        title: "Price not set properly",
        text: "Sale price should be less than regular price",
      });
      return false;
    }

    if (
      parseFloat(product.regular_price) > 1000 ||
      parseFloat(product.regular_price) < 0 ||
      parseFloat(product.sale_price) > 1000 ||
      parseFloat(product.sale_price) < 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Invalid Price",
        text: "Price should be within 0 - 1000",
      });
      return false;
    }

    return true;
  };

  const handleTips = (e) => {
    if (e.target.value.length > 255) {
      return;
    } else {
      setProduct({ ...product, tips: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const imagesValid = await validateImages();
    
    if (!selectedImage) {
      Swal.fire({
        icon: "error",
        title: "Featured image is required",
        text: "",
      });
      return;
    }
    else if (!imagesValid || !validatePrices()) {
      return;
    }
    
    try {
      const formData = new FormData();
      const items = extractIds();

      if(!product?.product_name || !product?.category_id || !product?.description_1 || !data || !product?.regular_price )
      {
        Swal.fire({
          icon: "error",
          title: "Empty Fields",
          text: "Kindly Fill All The Fields",
        });
      }

      if(isChecked && items?.length === 0)
      {
        Swal.fire({
          icon: "error",
          title: "Empty Field",
          text: "Kindly add variations",
        });
      }

      formData.append("product_name", product.product_name);
      formData.append("category_id", product.category_id);
      formData.append("description_1", product.description_1);
      formData.append("description_2", data);
      formData.append("regular_price", product.regular_price);
      formData.append("sale_price", product.sale_price);
      formData.append("shadeItems", items);
      formData.append("quantity", product.quantity);
      formData.append("tips", product.tips);

      product.gallery_images.forEach((elem, ind) => {
        formData.append(`gallery_images`, product.gallery_images[ind]);
      });

      formData.append("product_image", selectedImage);

  
      if (parseFloat(product.sale_price) >= parseFloat(product.regular_price)) {
        Swal.fire({
          icon: "error",
          title: "Price not set properly",
          text: "Sale price should be less than regular price",
        });
      } else {
        await axios.post(`${baseUrl}/create-product`, formData);

        Swal.fire({
          icon: "success",
          title: "Product Added",
          text: "Your Product Added successfully",
        });
        
        navigate("/AdminProduct");
      }
    } catch (error) {
      if (error?.response?.data.message === "main Image are required") {
        Swal.fire({
          icon: "error",
          title: "Required Fields",
          text: "Main image is required",
        });
      } else if (error?.response?.status === 420) {
        Swal.fire({
          icon: "error",
          title: "Product with the same name exists",
          text: "",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Product with the same name exists",
          text: "Please recheck and fill all fields",
        });
      }
    }
  };

  const handleImageUpload = async (images) => {

    
    const loadImage = (image) => {
        return new Promise((resolve) => {
            if (image instanceof File) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    resolve({
                        dataURL: event.target.result,
                        file: image,
                    });
                };
                reader.readAsDataURL(image);
            } else if (typeof image === 'string') {
                resolve({
                    dataURL: image,
                    file: null, // You might need to adjust this based on your requirements
                });
            }
        });
    };

    try {
        const loadedImages = await Promise.all(
            images.map((image) => loadImage(image))
        );

        // Filter out any entries where file is null (i.e., base64 strings)
        const filteredImages = loadedImages.filter(img => img.file);

        setProduct({
          ...product,
          gallery_images: [...product.gallery_images, ...filteredImages.map((img) => img.file)],
        
      });
    } catch (error) {
        console.error("Error loading images:", error);
    }
};

  const handleRemoveImage = (index) => {
    const imgs = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(imgs);

    // Remove the image from the gallery_images array in the product state
    setProduct({
      ...product,
      gallery_images: product.gallery_images.filter((_, i) => i !== index),
    });
  };

  const validateAndSetImage = (file) => {
    // Check if the file is an image
    if (!file.type || !file.type.startsWith("image/")) {
      Swal.fire({
        icon: "error",
        title: "Invalid File Type",
        text: "Please select a valid image file (PNG)",
      });
      return;
    }

    // Check if the file is in PNG format
    if (!file.type.includes("image/png")) {
      Swal.fire({
        icon: "error",
        title: "Invalid Image Format",
        text: "Image should be in PNG format",
      });
      return;
    }

    // Define the required resolution
    const requiredResolution = { width: 800, height: 600 };

    // Create an image element to check the resolution
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = function () {
      // Check if the image meets the required resolution
      if (
        img.width > requiredResolution.width ||
        img.height > requiredResolution.height
      ) {
        Swal.fire({
          icon: "error",
          title: "Invalid Image Resolution",
          text: `Image resolution should be ${requiredResolution.width}x${requiredResolution.height}`,
        });
      } else {
        // Image is valid, set it
        setSelectedImage(file);
      }
    };
  };

  useEffect(() => {
    getAllCategory();
    setEditorLoaded(true);
    getVariation();
  }, []);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);


  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-59">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Add Products</h1>
                <div className="boxs-flex">
                  {/* <Link
                    className="p-3 justify-content-center bg-yellow w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to=""
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <FontAwesomeIcon color="red" icon={faTrash} />
                  </Link> */}
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/AdminProduct"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <div className="add-categorie-list mt-5">
              <FontAwesomeIcon color="#fff" icon={faPencil} />
              <span className="mx-2">Add Product</span>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-8">
                  <div className="grid-add d-grid align-items-center my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      <span className="text-warning">*</span>Product Name
                    </label>
                    <input
                      className="py-1 px-2"
                      type="text"
                      name="product_name"
                      value={product.product_name}
                      onChange={(e) => {
                        handleName(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <hr className="text-light my-4" />
                  <div className="grid-add d-grid align-items-center my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Select Category
                    </label>
                    <select
                      className="py-1 px-2 border"
                      name="brand"
                      id="brand"
                      value={product.category_id}
                      onChange={(e) =>
                        setProduct({ ...product, category_id: e.target.value })
                      }
                      required
                    >
                      <option value="">Select Category</option>
                      {category &&
                        category.map((category) => (
                          <option
                            key={category.category_id}
                            value={category.category_id}
                          >
                            {category.category_name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="grid-add d-grid my-4">
                    <label
                      className="text-light d-block text-end p-"
                      htmlFor=""
                    >
                      Short Description
                    </label>
                    {/* <input type="text" /> */}
                    <input
                      className="py-1 px-2"
                      type="text"
                      value={product.description_1}
                      onChange={(e) => {
                        descriptionone(e.target.value);
                      }}
                    />
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Long Description
                    </label>
                    <Editor
                      name="description"
                      onChange={(data) => {
                        setData(data);
                      }}
                      editorLoaded={editorLoaded}
                    />
                  </div>

                  <div className="grid-add d-grid my-4">
                    <label
                      className="text-light d-block text-end p-"
                      htmlFor=""
                    >
                      Tips (Comma Seperated)
                    </label>
                    {/* <input type="text" /> */}
                    <input
                      className="py-1 px-2"
                      type="text"
                      value={product.tips}
                      onChange={(e) => handleTips(e)}
                    />
                  </div>
                  <hr className="text-light my-4" />
                  <div className="grid-add d-grid my-md-4 my-3 variation-parent-resp">
                    <label className="text-light d-block text-end" htmlFor="">
                      Variation
                    </label>
                    <div className="text-start d-lg-block w-max-w-tab">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckBox}
                        // required
                      />
                    </div>
                  </div>

                  {isChecked ? (
                    <div className="grid-add d-grid my-4">
                      <label
                        className="text-light d-block text-end"
                        htmlFor=""
                        style={{ opacity: "0" }}
                      >
                        Variation
                      </label>
                      <MultiSelect
                        options={variation}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <hr className="text-light my-4" />
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      <span className="text-warning">*</span> Regular Price
                    </label>
                    <input
                      type="text"
                      name="regular_price"
                      value={product.regular_price}
                      onChange={(e) => {
                        regular(e.target.value);
                      }}
                    />
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Sale Price
                    </label>
                    <input
                      type="text"
                      name="sale_price"
                      value={product.sale_price}
                      onChange={(e) => {
                        sale(e.target.value);
                      }}
                    />
                  </div>

                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Quantity
                    </label>
                    <input
                      type="text"
                      className="py-1 px-2"
                      name="quantity"
                      value={product.quantity}
                      onChange={(e) => {
                        quantity(e.target.value);
                      }}
                    />
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label htmlFor=""></label>
                    <input
                      className="fw-bold add-save-btn rounded-3 border-0 ms-auto"
                      type="submit"
                      value="Save"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row">
                    <div className="border-bottom border-secondary col-lg-12 mb-3 pb-2">
                      {imageUrl && selectedImage && (
                        <div className="mb-3" textAlign="center">
                          <img
                            ref={imageRef}
                            src={imageUrl}
                            alt={selectedImage.name}
                            width="100%"
                            className="border border-secondary p-1 rounded-3 prod-admin-img"
                          />
                        </div>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        id="select-featured-image"
                        onChange={(e) => validateAndSetImage(e.target.files[0])}
                      />
                      <label
                        htmlFor="select-featured-image"
                        className="bg-gradient cursor-pointer mb-2 px-3 py-3 rounded-4 text-white "
                      >
                        {!imageUrl && !selectedImage
                          ? "Upload Featured Image"
                          : "Change Featured Image"}
                      </label>
                      {/* <p>{dimensionsimg}</p> */}
                    </div>

                    <div className="col-lg-12">
                      <ImageUploader
                        onImagesChange={handleImageUpload}
                        title="Upload Multiple Images"
                        onRemoveImage={handleRemoveImage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        size="lg"
      >
        <div
          className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100 w-100"
          size="lg"
        >
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light my-3">
                You are about to delete this Category
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        size="lg"
      >
        <div
          className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100 w-100"
          size="lg"
        >
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light my-3">
                You are about to delete this Category
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
