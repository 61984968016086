import React from "react";
import AboutSectLogo from "../assets/img/AboutLogo.png";
import { useLocation, Link } from "react-router-dom";

function Partner(props) {
  const location = useLocation();

  return (
    <>
      <section className=" home-about-us-section">
        <div className="container-1640">
          <div className="row prnt-prtnr-row">
            <div className="col-md-6">
              <div className="main-img-about-brdr p-5">
                <div className="shawdow"></div>
                <div className="sec-img-about-brdr p-5 md:p-3 d-flex align-items-center justify-content-center">
                  <img src={AboutSectLogo} alt="" />
                </div>
                <div className="shawdow-bottom"></div>
              </div>
            </div>
            {/* about-us-section-box */}
            <div className="col-md-6 p-0">
              <div className="about-sec-col-text">
                <h1 className="font-bebas">ABOUT US</h1>
                <h2
                  className={`font-bebas ${props.addNone ? props.addNone : ""}`}
                >
                  OUR BEAUTY STYLIST
                </h2>
                {location.pathname === "/About" ? (
                  <p className="font-poppins">
                    At Nisa London Limited, we commit to bringing the best to
                    our clients. Manufacturing of each product involves
                    high-performing, halal-certified and cruelty-free
                    ingredients. Our premium range of beauty products values
                    people regardless of age, ethnicity, skin colour and
                    socioeconomic background. Our cosmetic range is for
                    everyone.
                  </p>
                ) : (
                  <p className="font-poppins">
                    Nisa London makeup with skincare UK commits to bringing the
                    <br />
                    best to our clients. Manufacturing of each product involves
                    <br />
                    high-performing, halal-certified and cruelty-free
                    ingredients.
                  </p>
                )}

                <div className="d-flex justify-content-lg-center justify-content-md-start justify-content-center">
                  <Link
                    className={`btn-master-home-page about-home-ReadMore ${
                      props.addNone ? props.addNone : ""
                    }`}
                    to="/about"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Partner;
