import React from "react";
import { useEffect, useState } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import OrderCompleteLogo from "../assets/img/AboutLogo.png";

function OrderComplete() {
  const [currentDateTime, setCurrentDateTime] = useState(null);
  const [data, setData] = useState();
  const [id, setID] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location) {
      setData(location.state.data);
      setID(location.state.id);
    } else {
      navigate("/");
    }
  }, []);

  //date time genrate
  useEffect(() => {
    // Function to get the current date and time
    const getCurrentDateTime = () => {
      const now = new Date();
      const formattedDateTime = now.toLocaleString(); // You can customize the format as needed
      setCurrentDateTime(formattedDateTime);
    };

    getCurrentDateTime(); // Call the function when the component mounts

    // If you want to update the date and time every second, you can use setInterval
    const intervalId = setInterval(getCurrentDateTime, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <Header />
      <div className="bef-aft">
        <div className="container-1640 pt-5">
          <section className="completeordersec border-top">
            <div className="thankyou">
              <img src={OrderCompleteLogo} alt="" />
              <h1 class="carttitle mt-3">Thank you for your purchase!</h1>
              <p>Please find your order confirmation below</p>

              <div className="row">
                <h1
                  class="carttitle"
                  style={{ marginBottom: "0px", marginTop: "50px" }}
                >
                  Order #{id}
                </h1>
                <p className="mb-5">
                  Placed on {currentDateTime?.slice(0, 10)}
                </p>
                <div className="col-lg-6" style={{ padding: "0 30px 0 0" }}>
                  <div>
                    <h1 class="carttitle text-start">Billing Information</h1>
                    <p className="text-start">
                      {data?.fullname} <br /> {data?.email} <br />
                      {data?.contact} <br /> {data?.billing} <br />{" "}
                      {data?.state}
                    </p>
                    <h1
                      class="carttitle text-start"
                      style={{ marginTop: "50px" }}
                    >
                      Payment Method
                    </h1>
                    <p className="text-start">Credit Card </p>
                  </div>
                </div>

                <div className="col-lg-6" style={{ padding: "0 0px 0 30px" }}>
                  <div>
                    <h1 class="carttitle text-start">Shipping Information</h1>
                    <p className="text-start">
                      {data?.fullname} <br /> {data?.email} <br />
                      {data?.contact} <br /> {data?.shipping_address} <br />{" "}
                      {data?.state}
                    </p>
                  </div>
                </div>
              </div>
              <div className="wishes">
                <h1 class="carttitle text-center">Warmest wishes</h1>
                <p>Nisa Cosmetics London</p>
                <p style={{ margin: "0" }}>
                  <Link to="/">https://www.nisacosmeticslondon.com</Link>
                </p>
              </div>
            </div>
          </section>
        </div>
        <Footer />{" "}
      </div>
    </div>
  );
}

export default OrderComplete;
