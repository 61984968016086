import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import popularpro2 from "../assets/img/bestSellingProduct2.png";
import { faCartShopping, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { baseUrl } from "../assets/Utils/Ip";
import axios from "axios";
import GetStars from "./GetStars";
import { Link } from "react-router-dom";
function PopularProduct() {
  const [bestproducts, setBestproducts] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/best-selling-products`);

        setBestproducts(response.data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    slidesToScroll: 1,
    arrows: true,
    autoplay: false, // Enable auto sliding
    autoplaySpeed: 5000, // Set the time interval in milliseconds (e.g., 2000ms = 2 seconds)
  };

  return (
    <section className="sec-Promotions sec-Promotions-responsive popularproduct">
      <div className="container-1640 z-10 pb-5">
        <div className="home-product-page-sect-txt">
          <h1 className="font-bebas">Best selling</h1>
          <h2 className="font-bebas">HALAL MAKEUP</h2>
        </div>
        <div className="row prnt-prdt-card-row">
          <div className="col-lg-12">
            {bestproducts?.length > 4 ? (
              <Slider {...settings}>
                {bestproducts?.map((product) => (
                  <div key={product.id} className="prnt-prdt-box">
                    <div className="prnt-prdct-txt pt-5">
                      <h1 className="font-bebas">{product.product_name}</h1>
                      <div className="star-prnt">
                        <GetStars value={product.avg_rating} />
                      </div>
                    </div>
                    <div className="prdct-price">
                      <h3 className="font-bebas">£{product.sale_price}</h3>
                      <Link to={`/Buynow/${product.product_id}`}>
                        <FontAwesomeIcon icon={faCartShopping} />
                      </Link>
                    </div>
                    <div className="prdct-img">
                      <img src={`${baseUrl}/${product.main_image}`} alt="" />
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="d-flex justify-content-evenly">
                {bestproducts?.map((product) => (
                  <div key={product.id} className="prnt-prdt-box">
                    <div className="prnt-prdct-txt pt-5">
                      <h1 className="font-bebas">{product.product_name}</h1>
                      <div className="star-prnt">
                        <GetStars value={product.avg_rating} />
                      </div>
                    </div>
                    <div className="prdct-price">
                      <h3 className="font-bebas">
                        £
                        {product.sale_price !== "" && product.sale_price !== "0"
                          ? product.sale_price
                          : product.regular_price}
                      </h3>
                      <Link to={`/Buynow/${product.product_id}`}>
                        <FontAwesomeIcon icon={faCartShopping} />
                      </Link>
                    </div>
                    <div className="prdct-img">
                      <img src={`${baseUrl}/${product.main_image}`} alt="" />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PopularProduct;
