import React, { useEffect, useState } from "react";
import popularpro2 from "../assets/img/bestSellingProduct2.png";
import Header from "../Components/Header/Header";
import arrowLeft from "../assets/img/arrowLeft.png";
import arrowRight from "../assets/img/arrowRight.png";
// import shoptria from "../assets/images/shoptria.png";
// import shoptria2 from "../assets/images/shoptria2.png";
// import line from "../assets/images/pathline.png";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faChevronRight,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import Subscribe from "../Components/Subscribe";
import Loader from "../Components/Loader";

import axios from "axios";
import { baseUrl } from "../assets/Utils/Ip";

function Shop() {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedProductId, setSelectedProductId] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(500);
  const [category, setCategory] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [options, setOptions] = useState([]);
  const [products, setProducts] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [length, setLength] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handleSliderChange = (values) => {
    setMinPrice(values[0]);
    setMaxPrice(values[1]);
  };
  const handleCategoryChange = (category_id) => {
    setSelectedCategories((prevSelected) => {
      if (prevSelected.includes(category_id)) {
        return prevSelected.filter((id) => id !== category_id);
      } else {
        return [...prevSelected, category_id];
      }
    });
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selectedValueAsInt = parseFloat(selectedValue, 10);
    const updatedProductIds = [selectedValueAsInt];
    setSelectedProductId(updatedProductIds);
  };

  const getProducts = async () => {
    try {
      const response = await axios.get(`${baseUrl}/get-all-products`);
      if (response?.status === 200) {
        const product = response?.data?.results.filter(item => item.category_name !== 'giftset')
        setProducts(product);

        setLength(product.length);
      }
    } catch (err) {}
  };

  // pagination

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= length) {
      setCurrentPage(newPage);
    }
  };

  function generatePaginationNumbers(itemsPerPage, length, currentPage) {
    const pages = Math.ceil(length / itemsPerPage);
    const numbers = [];

    for (let i = 1; i <= pages; i++) {
      const isActive = i === currentPage;
      const className = isActive ? "active" : "";

      numbers.push(
        <Link key={i} className={className} onClick={() => setCurrentPage(i)}>
          {i}
        </Link>
      );
    }

    return numbers;
  }

  const filterProducts = () => {
    if (!products || !products.length) {
      return [];
    }

    // Filter products based on selected categories and brands
    const filteredProducts = products.filter((product) => {
      // Check if the product's category is among the selected categories
      const isInSelectedCategories =
        selectedCategories.length === 0 ||
        selectedCategories.includes(product.category_id);

      // Check if the product's brand is among the selected brands

      const isMostViewed =
        selectedProductId.length === 0 ||
        selectedProductId.includes(product.product_id);

      let isWithinPriceRange;
      if (product?.sale_price !== "") {
        isWithinPriceRange =
          product?.sale_price >= minPrice && product?.sale_price <= maxPrice;
      } else {
        isWithinPriceRange =
          product?.regular_price >= minPrice &&
          product?.regular_price <= maxPrice;
      }

      // Return true if both category and brand filters match
      return isInSelectedCategories && isWithinPriceRange && isMostViewed;
    });

    return filteredProducts;
  };

  const filterProduct = filterProducts();

  const paginatedProducts = filterProduct?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    const getCategory = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-all-category`);
        if (response?.status === 200) {
          const catgory = response.data.filter(item => item.category_name !== 'giftset')
          setCategory(catgory);
        }
      } catch (err) {}
    };
    getCategory();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/best-selling-products`);
        const data = response.data;

        // Assuming the API response is an array of options
        setOptions(data);
      } catch (error) {}
    };

    fetchData();
    getProducts();
  }, []);

  useEffect(() => {
    if (location.state) {
      handleCategoryChange(location.state);
    }
  }, [location.state]);

  // mobile filter usestate
  const [showFilters, setShowFilters] = useState(false);

  const handleFilterHeadingClick = () => {
    setShowFilters(!showFilters);
  };

  // mobile filter usestate

  return (
    <div>
      <Loader />

      {/* <Warning /> */}

      <div className="brand-hover">
        <Header />
      </div>

      <section className="secshop font-bebas">
        <div className="container-1640 position-relative z-10">
          <div className="toplinecon ">
            <div className="hamicons">
              <ul>
                <li>
                  <Link className="theme-text-yellow-light" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="theme-text-yellow-light">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                </li>
                <li>
                  <Link className="theme-text-yellow-light" to="/shop">
                    Shop
                  </Link>
                </li>
              </ul>
            </div>
            <div className="pagin">
              {/* <div className="bx">
                <FontAwesomeIcon
                  className="text-light fs-3"
                  icon={faMicrosoft}
                />
                <img src={shoptria} alt="" />
                <img src={line} alt="" />
                <span className="text-light fs-5"> | </span>
                <img src={shoptria2} alt="" />
                <FontAwesomeIcon className="text-light fs-3" icon={faList} />
              </div> */}
              <div>
                <select
                  aria-label="Default select example"
                  onChange={(e) => {
                    setCurrentPage(1);
                    setItemsPerPage(e.target.value);
                  }}
                  value={itemsPerPage}
                >
                  <option value={3} selected>
                    3
                  </option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
              </div>
            </div>
          </div>

          <div className="filter-mean">
            <div className="filterleft position-relative">
              <div className="filters position-sticky top-30 mb-5 d-md-block d-none">
                <h2 className="p-2 text-center bg-golden-gradient rounded-top-4 filter-heading-resp">
                  Filters
                </h2>
                <div className="range">
                  <Slider
                    range
                    min={0}
                    max={500}
                    defaultValue={[minPrice, maxPrice]}
                    onChange={handleSliderChange}
                  />
                  <p>
                    <span className="theme-text-yellow-light">
                      {" "}
                      Min: £{minPrice}
                    </span>{" "}
                    <span className="theme-text-yellow-light">
                      {" "}
                      Max: £{maxPrice}
                    </span>
                  </p>
                </div>
                <div className="select-apn">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={handleSelectChange}
                    value={selectedProductId}
                  >
                    <option
                      style={{
                        color: "black",
                      }}
                    >
                      Popular Products
                    </option>
                    {options?.map((option, index) => (
                      <option
                        key={index}
                        value={option.product_id}
                        style={{
                          color: "black",
                        }}
                      >
                        {option.product_name}{" "}
                        {/* Change 'label' to the actual property you want to display */}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="categories">
                  <h2 className="theme-text-color">Category</h2>
                  {category?.map((category) => (
                    <div key={category.category_id} className="categoriesitem">
                      <div class="form-group">
                        <input
                          type="checkbox"
                          id={category.category_id}
                          checked={selectedCategories.includes(
                            category.category_id
                          )}
                          onChange={() =>
                            handleCategoryChange(category.category_id)
                          }
                        />
                        <label htmlFor={category.category_id}></label>
                      </div>
                      <div className="catecon">
                        <p>{category?.category_name}</p>
                        <p>{category?.product_count}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="p-lg-4 p-3 text-center bg-golden-gradient rounded-bottom-4"></div>
              </div>
              <div className="filters position-absolute top-30 mb-md-5 d-md-none d-block">
                <h2
                  className="p-2 text-center bg-golden-gradient rounded-top-4 filter-heading-resp"
                  onClick={handleFilterHeadingClick}
                >
                  Filters
                </h2>

                {showFilters && (
                  <>
                    <div className="range">
                      <Slider
                        range
                        min={0}
                        max={1000}
                        defaultValue={[minPrice, maxPrice]}
                        onChange={handleSliderChange}
                      />
                      <p>
                        <span className="theme-text-yellow-light">
                          {" "}
                          Min: £{minPrice}
                        </span>{" "}
                        <span className="theme-text-yellow-light">
                          {" "}
                          Max: £{maxPrice}
                        </span>
                      </p>
                    </div>
                    <div className="select-apn">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={handleSelectChange}
                        value={selectedProductId}
                      >
                        <option
                          style={{
                            color: "black",
                          }}
                        >
                          Popular Products
                        </option>
                        {options?.map((option, index) => (
                          <option
                            key={index}
                            value={option.product_id}
                            style={{
                              color: "black",
                            }}
                          >
                            {option.product_name}{" "}
                            {/* Change 'label' to the actual property you want to display */}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="categories">
                      <h2 className="theme-text-color">Category</h2>
                      {category?.map((category) => (
                        <div
                          key={category.category_id}
                          className="categoriesitem"
                        >
                          <div class="form-group">
                            <input
                              type="checkbox"
                              id={category.category_id}
                              checked={selectedCategories.includes(
                                category.category_id
                              )}
                              onChange={() =>
                                handleCategoryChange(category.category_id)
                              }
                            />
                            <label htmlFor={category.category_id}></label>
                          </div>
                          <div className="catecon">
                            <p>{category?.category_name}</p>
                            <p>{category?.product_count}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="p-lg-4 p-3 text-center bg-golden-gradient rounded-bottom-4"></div>
                  </>
                )}
              </div>
            </div>
            <div className="filterright filter-product bg-particles">
              <div className="row">
                {paginatedProducts?.map((product) => (
                  <div className="col-lg-4 col-6 mb-5" key={product.product_id}>
                    <Link to={`/buynow/${product.product_id}`}>
                      <div className="prnt-prdt-box shop-page-height">
                        <div className="prnt-prdct-txt">
                          <h1 className="font-bebas">{product.product_name}</h1>
                          <div className="star-prnt">
                            <div className="star-prnt">
                              {[
                                ...Array(
                                  product.avg_rating !== null
                                    ? Math.floor(parseFloat(product.avg_rating))
                                    : 0
                                ),
                              ].map((_, index) => (
                                <FontAwesomeIcon key={index} icon={faStar} />
                              ))}
                            </div>
                          </div>
                        </div>
                        <Link
                          to={`/buynow/${product.product_id}`}
                          className="theme-text-color position-relative z-1"
                        >
                          <div className="prdct-price">
                            <h3 className="font-bebas">
                              £
                              {product.sale_price === ""
                                ? product.regular_price
                                : product.sale_price}
                            </h3>
                            <FontAwesomeIcon icon={faCartShopping} />
                          </div>
                        </Link>
                        <div className="prdct-img">
                          <img
                            src={`${baseUrl}/${product.main_image}`}
                            alt=""
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>

              <div class="d-flex justify-content-end mx-5 mx-lg-0 mb-5 pagiban">
                <div class="pagination">
                  <Link
                    to=""
                    onClick={() => {
                      currentPage !== 1
                        ? setCurrentPage(currentPage - 1)
                        : setCurrentPage(currentPage);
                    }}
                  >
                    <img src={arrowLeft} width="25px" alt="" />
                  </Link>

                  {generatePaginationNumbers(itemsPerPage, length, currentPage)}

                  <Link
                    to=""
                    onClick={() => {
                      currentPage !== Math.ceil(length / itemsPerPage)
                        ? setCurrentPage(currentPage + 1)
                        : setCurrentPage(currentPage);
                    }}
                  >
                    <img src={arrowRight} width="25px" alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Subscribe />
      <Footer top="5" />
    </div>
  );
}

export default Shop;
