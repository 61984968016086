import React, { useEffect, useState } from "react";
import Sidebar from "../Component/Sidebar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListUl,
  faPencil,
  faPlus,
  faTrash,
  faReply,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { baseUrl } from "../../assets/Utils/Ip";
import Swal from "sweetalert2";

export default function Variation() {
  const token = localStorage.getItem("root");
  const [variation, setVariation] = useState([]);

  const [selectedvariation, setSelectedvariation] = useState([]); // Step 1
  const [length, setLength] = useState(0);
  const [deletedId, setDeletedID] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  // let itemsPerPage = 4;
  const getvariation = async () => {
    try {
      const res = await axios.get(`${baseUrl}/get-all-shade`);
      setVariation(res?.data);
      setLength(res.data.results.length);
    } catch (error) {}
  };

  function handleDeleteCategory(id) {
    // Delete button click event handler
    let config = {
      method: "DELETE",
      maxBodyLength: Infinity,
      url: `${baseUrl}/variation-delete/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setVariation((prevProd) =>
          prevProd.filter((variation) => variation.shade_id !== id)
        );
        Swal.fire({
          icon: "success", // Use "success" for the icon
          title: "Variation deleted succesfully",
          text: "Deleted Successfully",
          customClass: {
            // Assign your custom class to the popup container
            container: "my-custom-popup",
          },
        });
        // Handle success, for example, update the UI or show a success message
      })
      .catch((error) => {
        // Handle error, show an error message or take appropriate action
      });
  }

  const handleSelectAll = () => {
    setSelectedvariation(
      variation?.map((variation) => variation?.shade_id)
    ); // Step 2
  };

  const handleDeselectAll = () => {
    setSelectedvariation([]); // Step 3
  };

  const handleBrandSelect = (variationId) => {
    // Step 4
    if (selectedvariation.includes(variationId)) {
      setSelectedvariation(
        selectedvariation.filter((id) => id !== variationId)
      );
    } else {
      setSelectedvariation([...selectedvariation, variationId]);
    }
  };

  const deleteAll = () => {
    const categoryIdsToDelete = selectedvariation; // Replace with the category IDs you want to delete

    if (categoryIdsToDelete.length === 0) {
      Swal.fire({
        icon: "error", // Use "success" for the icon
        title: "Kindly select an item before deletion",
        text: "",
        customClass: {
          // Assign your custom class to the popup container
          container: "my-custom-popup",
        },
      });

      return;
    }

    axios
      .delete(`${baseUrl}/delete-all-variation`, {
        data: { shadeIds: categoryIdsToDelete },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle any further actions after successful deletion.
        Swal.fire({
          icon: "success", // Use "success" for the icon
          title: "All Variations",
          text: "Deleted Successfully",
          customClass: {
            // Assign your custom class to the popup container
            container: "my-custom-popup",
          },
        });

        setVariation((prevVariations) =>
          prevVariations.filter(
            (variation) => !categoryIdsToDelete.includes(variation.shade_id)
          )
        );
      })
      .catch((error) => {
        // Handle errors or display an error message.
      });
  };

  useEffect(() => {
    getvariation();
  }, []);

  // Pagination start
  const [currentPage, setCurrentPage] = useState(1);

  // Check if variation is defined
  const variationExist = variation && variation.length > 0;

  // If variation is not defined, initialize it as an empty array
  const variationsArray = variationExist ? variation : [];

  const indexOfLastvariation = currentPage * itemsPerPage;
  const indexOfFirstvariation = indexOfLastvariation - itemsPerPage;
  const currentvariations = variationsArray.slice(
    indexOfFirstvariation,
    indexOfLastvariation
  );

  const paginate = (pageNumber) => {
    // console.log('Setting current page to:', pageNumber);
    setCurrentPage(pageNumber);
  };
  // Pagination end

  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side variationsec">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Variation</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-yellow w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/addShade"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Link>
                  <Link
                    className="p-3 justify-content-center bg-yellow w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to=""
                  >
                    <FontAwesomeIcon
                      color="red"
                      icon={faTrash}
                      onClick={deleteAll}
                    />
                  </Link>
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/Categories"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>

            <div className="d-flex justify-content-end mt-4">
              <Link
                className="add-categorie-btn text-dark rounded-2 px-5 py-2 font-bebas"
                to="/Addshade"
              >
                Add shade
              </Link>

              <Link
                className="add-categorie-btn text-dark rounded-2 px-5 py-2 font-bebas"
                to="/DeletedVariation"
                style={{ marginLeft: "10px" }}
              >
                Deleted shade
              </Link>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <div className="add-categorie-list">
                <FontAwesomeIcon color="#fff" icon={faListUl} />
                <span className="mx-2">Variation List</span>
              </div>
              <div className="pagin">
                <select
                  aria-label="Default select example"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(e.target.value)}
                >
                  <option value={5} selected>
                    5
                  </option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
              </div>
            </div>
            <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                <div className="categories-parent-table-box">
                  <div className="brand-categorie-table-box w-100 align-items-center rounded-3 my-3">
                    <div className="text-center">
                      <input
                        type="checkbox"
                        onChange={() =>
                          selectedvariation?.length < variation?.length
                            ? handleSelectAll()
                            : handleDeselectAll()
                        }
                        checked={
                          selectedvariation.length === variation?.length
                        }
                      />
                    </div>
                    <div className=" d-flex align-items-center p-3">
                      <p className="mx-4">Color</p>
                    </div>

                    <div className="border-end p-3 ">
                      <p>Shade Name</p>
                    </div>
                    <div className="p-3 text-end">
                      <p>Action</p>
                    </div>
                  </div>
                  {currentvariations?.map((variation) => (
                    <div
                      key={variation.shade_id}
                      className="brand-categorie-table-box-sec text-light w-100 align-items-center rounded-3 my-2"
                    >
                      <div className="text-center">
                        <input
                          type="checkbox"
                          onChange={() => handleBrandSelect(variation.shade_id)}
                          checked={selectedvariation.includes(
                            variation.shade_id
                          )}
                        />
                      </div>
                      <div className=" d-flex align-items-center justify-content-between py-1 ps-5 pd-1">

                          {variation.shade.startsWith("[")?JSON.parse(variation.shade).map((shadeObj, index) => (
                          <span
                          key={index}
                          className="shadecolor ml"
                          style={{ background: shadeObj.ColorCode}}
                          ></span>
                          )):
                          <span
                          className="shadecolor ml"
                          style={{ background: variation.shade }}
                        ></span>
                          }
                      </div>

                      <div className="border-end p-3">
                        <p>{variation.shade_name}</p>
                      </div>
                      <div className="p-2 px-3 text-end">
                        {/* Button trigger modal */}
                        <button
                          type="button"
                          className="btn bg-yellow me-2"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setDeletedID(variation.shade_id)}
                        >
                          <FontAwesomeIcon color="red" icon={faTrash} />
                        </button>
                        <Link
                          to={`/Editshade/${variation.shade_id}`}
                          className="btn bg-yellow"
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Pagination start */}
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex align-items-end">
                <div className="Pagination mt-4 ">
                  <div className="nmbering d-flex">
                    {Array.from({
                      length: Math.ceil(variation.length / itemsPerPage),
                    }).map((_, index) => (
                      <span
                        key={index}
                        className={
                          currentPage === index + 1 ? "active" : "pginte-curser"
                        }
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </span>
                    ))}
                  </div>
                  <div className="linepag">
                    <span></span>
                  </div>
                </div>
                <div className="left-right-arrow-pag">
                  <span
                    onClick={() => currentPage > 1 && paginate(currentPage - 1)}
                  >
                    <i
                      className="fa-solid fa-angle-left"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                  <span
                    onClick={() =>
                      currentPage <
                        Math.ceil(variation.length / itemsPerPage) &&
                      paginate(currentPage + 1)
                    }
                  >
                    <i
                      className="fa-solid fa-angle-right"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
            {/* Pagination end */}
            {/* Pagination end */}

            {/*  */}
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        size="lg"
      >
        <div
          className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100 w-100"
          size="lg"
        >
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light my-3">
                You are about to delete this variation
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => handleDeleteCategory(deletedId)}
              >
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
