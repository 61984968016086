import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import boxInnerParticle from "../assets/img/box-inner-particles.png";
import boxInnerParticle1 from "../assets/img/box-inner-particles1.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Subscribe from "../Components/Subscribe";
import Loader from "../Components/Loader";
import { useFormik } from "formik";
import axios from "axios";
import { baseUrl } from "../assets/Utils/Ip";
import Swal from "sweetalert2";

function Contact() {
  const validate = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = "Please enter your name";
    } else if (values.firstName.length > 15) {
      errors.firstName = "Must be 15 characters or less";
    }

    if (!values.message) {
      errors.message = "Please enter your message";
    } else if (values.message.length < 20) {
      errors.message = "Must be atleast 20 characters ";
    }

    if (!values.email) {
      errors.email = "Please enter your email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.phone) {
      errors.phone = "Please enter your Phone Number";
    } else if (!/^\d{11}$/.test(values.phone)) {
      errors.phone = "Must be atleast 11 characters";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",

      lastName: "",

      email: "",

      phone: "",
    },

    validate,

    onSubmit: async (values) => {
      const response = await axios.post(`${baseUrl}/contact`, {
        name: values.firstName,
        email: values.email,
        number: values.phone,
        message: values.message,
      });

      Swal.fire({
        icon: "success", // Use "success" for the icon
        title: "Contact",
        text: "Your Contact is submitted",
        customClass: {
          // Assign your custom class to the popup container
          container: "my-custom-popup",
        },
      });

      values.firstName = "";
      values.email = "";
      values.phone = "";
      values.message = "";
    },
  });

  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

    // Update the value in the formik state
    formik.setFieldValue("phone", numericValue);
  };

  return (
    <div>
      <Loader />
      {/* <Warning /> */}
      <div className="contact-hover">
        <Header />
        <section className="EXQUISITE font-bebas py-md-5 py-3">
          <div className="position-relative">
            <span className="bg-particle"></span>
            <div className="container-1640 position-relative z-2">
              <div className="w-75 p-4 bg-theme-box mx-auto my-5 position-relative">
                <img
                  className="position-absolute start-0 top-0 w-75"
                  src={boxInnerParticle}
                  alt=""
                />
                <img
                  className="position-absolute end-0 bottom-0 w-75"
                  src={boxInnerParticle1}
                  alt=""
                />
                <div className="border-golden text-center p-lg-5">
                  <h1 className="theme-text-color m-0 line-height fs-70 pt-md-5 pt-3">
                    Need Help?
                  </h1>
                  <h2 className="theme-text-yellow-light fs-50">
                    Let's Get in Touch With Us
                  </h2>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="py-lg-5 pt-0 pb-5  font-poppins position-relative z-10 counter-of-w-50-contact w-50 mx-auto">
                      <input
                        type="text"
                        placeholder="Name"
                        id="firstName"
                        name="firstName"
                        className="border-1 p-2 w-100 border-golden placeholder-color bg-transparent theme-text-yellow-light my-4 d-block"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                      />
                      {formik.errors.firstName ? (
                        <p className="error-style">{formik.errors.firstName}</p>
                      ) : null}
                      <input
                        placeholder="Email"
                        className="border-1 p-2 w-100 border-golden placeholder-color bg-transparent theme-text-yellow-light my-4 d-block"
                        name="email"
                        type="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.errors.email ? (
                        <p className="error-style">{formik.errors.email}</p>
                      ) : null}
                      <input
                        type="text"
                        placeholder="Phone"
                        name="phone"
                        maxLength="11"
                        className="border-1 arrow-input p-2 w-100 border-golden placeholder-color bg-transparent theme-text-yellow-light my-4 d-block"
                        onChange={handlePhoneChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                      />
                      {formik.errors.phone ? (
                        <p className="error-style">{formik.errors.phone}</p>
                      ) : null}
                      <textarea
                        placeholder="Message"
                        rows="6"
                        name="message"
                        className="border-1 p-2 w-100 border-golden placeholder-color bg-transparent theme-text-yellow-light my-4 d-block"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                      ></textarea>
                      {formik.errors.message ? (
                        <p className="error-style">{formik.errors.message}</p>
                      ) : null}
                      <input
                        type="submit"
                        className="font-bebas border-0 px-5 rounded-3 fs-2 py-0 btn-gradient"
                        value="send"
                      />
                      <h2 className="font-bebas m-0 pt-4 theme-text-color">
                        For immediate Assistance
                      </h2>

                      <Link
                        className="theme-text-yellow-light "
                        to="mailt:info@nisacosmeticslondon.com"
                      >
                        <FontAwesomeIcon icon={faEnvelope} />{" "}
                        info@nisacosmeticslondon.com
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Subscribe />
        <Footer top={5} />
      </div>
    </div>
  );
}

export default Contact;
