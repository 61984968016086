// import { faStar } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import React from "react";

// export default function GetStars({ value, color, className }) {
//   let list = [];

//   for (let i = 1; i <= value; i++) {
//     list.push(
//       <FontAwesomeIcon
//         icon={faStar}
//         color={color === "orange" ? color : ""}
//         className={`fa-regular ${className}`}
//       />
//     );
//   }

//   return list;
// }

import React from "react";
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function GetStars({ value, color, className }) {
  let list = [];

  const fullStars = Math.floor(value);
  const hasHalfStar = value % 1 !== 0;

  for (let i = 1; i <= fullStars; i++) {
    list.push(
      <FontAwesomeIcon
        key={i}
        icon={faStar}
        color={color === "orange" ? color : ""}
        className={`fa-regular ${className}`}
      />
    );
  }

  if (hasHalfStar) {
    list.push(
      <FontAwesomeIcon
        key="half"
        icon={faStarHalfAlt}
        color={color === "orange" ? color : ""}
        className={`fa-regular ${className}`}
      />
    );
  }

  return list;
}
