import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faReply,
  faCheck,
  faListUl,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../Component/Sidebar";
import { useState } from "react";
import { useEffect } from "react";
import GetStars from "../../Components/GetStars";
import { baseUrl } from "../../assets/Utils/Ip";
import axios from "axios";

export default function EditReview() {
  const [review, setReview] = useState({
    id: "",
    name: "",
    email: "",
    review_title: "",
    review_message: "",
    review_status: "",
    product_name: "",
    review_rating: 0,
  });

  const location = useLocation();
  const navigate = useNavigate();

  const token = localStorage.getItem("root");

  const handleStatus = async (status) => {
    try {
      let review_status = "";
      if (status === 1) {
        review_status = "approved";
      } else if (status === 2) {
        review_status = "declined";
      } else {
        review_status = "pending";
      }

      const res = await axios.put(
        `${baseUrl}/update-review/${review.id}`,
        {
          review_status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      navigate("/ManageReviews");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (location.state) {
      const data = location.state;

      setReview({
        id: data.review_id,
        name: data.name,
        email: data.email,
        review_title: data.review_title,
        review_message: data.review_message,
        product_name: data.product_name,
        review_status: data.review_status,
        review_rating: parseFloat(data.review_rating),
      });
    }
  }, []);
  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Edit Review</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/ManageReviews"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
              <div className="add-categorie-list mb-3">
                <FontAwesomeIcon color="#fff" icon={faListUl} />
                <span className="mx-2">Review</span>
              </div>
            </div>

            <div className="edit-mange-cent position-relative">
              <div className="edit-mnge-heading">
                <h3>{review.product_name}</h3>
              </div>
              <div className="edit-mnge-star">
                <GetStars value={review.review_rating} />
              </div>
            </div>
            <hr className="hr-edit-cust" />
            <div className="edit-cust-grid">
              <div className="edit-cust-grid-inner text-end">
                <p>Name</p>
              </div>
              <div className="edit-cust-grid-inner">
                <input
                  type="text"
                  placeholder="Mark Allen"
                  value={review.name}
                  readOnly
                />
              </div>
            </div>
            <hr className="hr-edit-cust" />
            <div className="edit-cust-grid">
              <div className="edit-cust-grid-inner text-end">
                <p>Email</p>
              </div>
              <div className="edit-cust-grid-inner">
                <input
                  type="email"
                  placeholder="mark_allen@gmail.com"
                  value={review.email}
                  readOnly
                />
              </div>
            </div>
            <hr className="hr-edit-cust" />
            <div className="edit-cust-grid">
              <div className="edit-cust-grid-inner text-end">
                <p>Review Title</p>
              </div>
              <div className="edit-cust-grid-inner">
                <input
                  type="text"
                  placeholder="Lorem ipsum dolor sit"
                  value={review.review_title}
                  readOnly
                />
              </div>
            </div>
            <hr className="hr-edit-cust" />
            <div className="edit-cust-grid">
              <div className="edit-cust-grid-inner text-end h-100">
                <p>Review Message</p>
              </div>
              <div className="edit-cust-grid-inner">
                <textarea
                  placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  value={review.review_message}
                  readOnly
                ></textarea>
              </div>
            </div>
            {review?.review_status === "approved" ? (
              <div className="d-flex justify-content-end mt-4">
                <button
                  className="py-2 px-4 rounded  bg-warning mx-2 text-light"
                  onClick={() => handleStatus(3)}
                >
                  Pending
                </button>
                <button
                  className="py-2 px-4 rounded  bg-danger mx-2 text-light "
                  onClick={() => handleStatus(2)}
                >
                  Declined
                </button>
              </div>
            ) : review?.review_status === "pending" ? (
              <div className="d-flex justify-content-end mt-4">
                <button
                  className="py-2 px-4 rounded  bg-success mx-2 text-light"
                  onClick={() => handleStatus(1)}
                >
                  Approve
                </button>
                <button
                  className="py-2 px-4 rounded  bg-danger mx-2 text-light "
                  onClick={() => handleStatus(2)}
                >
                  Decline
                </button>
              </div>
            ) : (
              <div className="d-flex justify-content-end mt-4">
                <button
                  className="py-2 px-4 rounded  bg-success mx-2 text-light "
                  onClick={() => handleStatus(1)}
                >
                  Approve
                </button>
                <button
                  className="py-2 px-4 rounded  bg-warning mx-2 text-light"
                  onClick={() => handleStatus(3)}
                >
                  Pending
                </button>
              </div>
            )}
            {/* <div className="d-flex justify-content-end mt-4">
              <button
                className="py-2 px-4 rounded  bg-success mx-2 text-light"
                onClick={() => handleStatus(1)}
              >
                Approved
              </button>
              <button
                className="py-2 px-4 rounded  bg-danger mx-2 text-light "
                onClick={() => handleStatus(2)}
              >
                Declined
              </button>
            </div> */}
            <div className="d-flex justify-content-between">
              <div className="pagi mnge-rev-pagi">
                {/* <img src={Pagi} alt="" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100">
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <Link
                to="/ManageReviews"
                className="btn-close text-dark position-relative bg-yellow d-flex align-items-center justify-content-center rounded-pill"
                data-bs-dismiss="modal"
              >
                X
              </Link>
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="green" fontSize="80px" icon={faCheck} />
              <h1 className="text-light">Review has Been Approved</h1>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100">
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <Link
                to="/ManageReviews"
                className="btn-close text-dark position-relative bg-yellow d-flex align-items-center justify-content-center rounded-pill"
                data-bs-dismiss="modal"
              >
                X
              </Link>
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />
              <h1 className="text-light">Review has Been Declined</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
