import React, { useState, useEffect } from 'react';

export default function Rating(props) {
  const [rating, setRating] = useState(props.rating || null);
  const [tempRating, setTempRating] = useState(null);
  const [selectedStars, setSelectedStars] = useState(null);

  const rate = (newRating) => {

    setRating(newRating);
    setTempRating(newRating);
    setSelectedStars(newRating);
  };

  const starOver = (newRating) => {
    setTempRating(rating);
    setRating(newRating);
  };

  const starOut = () => {
    setRating(tempRating);
  };


  // Use useEffect to notify the parent component when selectedStars changes
  useEffect(() => {
    if (props.onStarsSelected) {
      props.onStarsSelected(selectedStars);
    }
  }, [selectedStars, props]);

  const stars = [];
  for (let i = 0; i < 5; i++) {
    let klass = 'star-rating__star';
    if (rating >= i && rating !== null) {
      klass += ' is-selected';
    }

    stars.push(
      <label
        key={i}
        className={klass}
        onClick={() => { props.setClick === "disable" ? rate(rating) : rate(i) }}
        onMouseOver={() => { props.setClick === "disable" ? starOver(rating) : starOver(i) }}
        onMouseOut={starOut}
      >
        ★
      </label>
    );
  }

  return (
    <div className="star-rating">
      {stars}
    </div>
  );
}
