import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import StripeCheckout from "react-stripe-checkout";
import { baseUrl } from "../assets/Utils/Ip";
import { useContext } from "react";
import { CartContext } from "../Components/Cart";
import { publishableKey } from "../assets/Utils/Ip";

function Stripe(props) {
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState();

  const { cartItems, emptyCart } = useContext(CartContext);

  const handleSuccess = () => {
    Swal.fire({
      icon: "success",
      title: "Payment was successful",
      text: "Your Order created succesfully",
    });
  };
  const handleFailure = () => {
    Swal.fire({
      icon: "error",
      title: "Payment was not successful",
      time: 4000,
    });
  };

  const createOrderAPI = async () => {
    const value = props.data;
    const data = JSON.stringify({
      full_name: value.fullname,
      contact: value.contact,
      user_email: value.email,
      country: value.state,
      billing_address: value.billing,
      shipping_address: value.shipping_address,
      instruction: value.instruction,
      city: value.city,
      post_code: value.post,
      promo_code: value.promo,
      total_amount: props.totalAmount,
      order_items: cartItems,
      shipping_method: value.shipping_method,
    });
    
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/create-order`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        navigate("/OrderComplete", {
          state: { data: props.data, id: response.data.orderId },
        });
      })
      .catch((error) => {
        console.log(error);
        // Handle error here, e.g., show an error message
      });
  };

  const payNow = async (token) => {
    props.setPay(false);
    const data = JSON.stringify({
      amount: parseFloat(props.totalAmount).toFixed(2),
      payment: token.id,
      email: props.data.email,
      name: props.data.fullname,
    });
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/payment`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        handleSuccess();
        emptyCart();
        await createOrderAPI();
        props.setPay(true);
      })
      .catch((error) => {
        handleFailure();
        props.setPay(true);
      });
  };

  return (
    <div className="d-flex justify-content-end mt-2">
      <StripeCheckout
        stripeKey={publishableKey}
        label="Pay Now"
        name="Pay With Credit Card"
        billingAddress
        shippingAddress
        // amount={props.totalAmount * 100}
        description={`Your total is £${parseFloat(props.totalAmount).toFixed(
          2
        )}`}
        token={payNow}
        // style={customStyles} // Apply custom styles here
        className="custom-payment-button btn-gradient stripe-cust border-0"
      />
    </div>
  );
}

export default Stripe;
