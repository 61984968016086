import React from "react";
import Sidebar from "../Component/Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faReply } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import axios from "axios";
import { baseUrl } from "../../assets/Utils/Ip";
import { useState } from "react";
import { useEffect } from "react";

function EditPromoCode() {
  const [promo, setPromo] = useState({
    id: "",
    name: "",
    charges: "",
    quantity: "",
    method: "PERCENTAGE",
  });

  const [handle, setHandle] = useState(true);

  const navigate = useNavigate("");

  let regex = "";

  const token = localStorage.getItem("root");

  const location = useLocation();

  const handleName = (e) => {
    // regex = new RegExp("^[A-Za-z0-9_]+$");

    if (e.target.value.length > 12) {
      return;
    }
    // else if (e.target.value === "") {
    //   setPromo({ ...promo, name: "" });
    // } else if (regex.test(e.target.value)) {
    // } else {
    //   return;
    // }
    setPromo({ ...promo, name: e.target.value });
  };

  const handleCharges = (e) => {
    regex = new RegExp("^[0-9]+(\\.[0-9]+)?$");

    if (e.target.value === "") {
      setPromo({ ...promo, charges: "" });
    } else if (e.target.value.length > 10) {
      return;
    } else if (regex.test(e.target.value)) {
      setPromo({ ...promo, charges: e.target.value });
    } else {
      return;
    }
  };

  const handleQty = (e) => {
    regex = new RegExp("^[0-9]+$");

    if (e.target.value === "") {
      setPromo({ ...promo, quantity: "" });
    } else if (e.target.value.length > 4) {
      return;
    } else if (regex.test(e.target.value)) {
      setPromo({ ...promo, quantity: e.target.value });
    } else {
      return;
    }
  };

  const handleCheck = (id) => {
    if (id === 1) {
      setHandle(true);
      setPromo({ ...promo, method: "PERCENTAGE" });
    } else {
      setHandle(false);
      setPromo({ ...promo, method: "PRICE" });
    }
  };

  const fetchPromo = async (id) => {
    try {
      const res = await axios.get(`${baseUrl}/get-promo-ById/${id}`);

      const data = res.data;

      if (res.status === 200) {
        setPromo({
          id: data.promo_id,
          name: data.promo_name,
          method: data.status,
          charges: data.discount_unit,
          quantity: data.quantity,
        });

        if (data.status === "percentage") {
          setHandle(true);
        } else {
          setHandle(false);
        }
      }
    } catch (err) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!promo.charges || !promo.method || !promo.name || !promo.quantity) {
      Swal.fire({
        icon: "error",
        title: "Empty Fields",
        text: "Kindly fill all the fields",
        customClass: {
          container: "my-custom-popup",
        },
      });

      return;
    } else if (
      promo.method === "PERCENTAGE" &&
      (parseFloat(promo.charges) > 100 || parseFloat(promo.charges) < 1)
    ) {
      Swal.fire({
        icon: "error",
        title: "Invalid number",
        text: "Percentage should be between 1-100",
        customClass: {
          container: "my-custom-popup",
        },
      });
      return;
    } else if (promo.quantity <= 0) {
      Swal.fire({
        icon: "error",
        title: "Invalid Quantity",
        text: "Quantity should be atleast 1",
        customClass: {
          container: "my-custom-popup",
        },
      });
      return;
    } else {
      try {
        const res = await axios.put(
          `${baseUrl}/update-promo-code/${location.state}`,
          {
            promo_name: promo.name.trim(),
            status: promo.method,
            discount_unit: promo.charges,
            quantity: promo.quantity,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Promo code updated succesfully",
            text: "Promo code is available to use",
            customClass: {
              container: "my-custom-popup",
            },
          });

          navigate("/PromoCode");

          return;
        }
      } catch (err) {
        if (err?.response?.status === 409) {
          Swal.fire({
            icon: "error",
            title: "Promo code with this name exists",
            text: "",
            customClass: {
              container: "my-custom-popup",
            },
          });
          return;
        }

        Swal.fire({
          icon: "error",
          title: "Unexpected error occured",
          text: "",
          customClass: {
            container: "my-custom-popup",
          },
        });
      }
    }
  };

  useEffect(() => {
    if (location.state) {
      fetchPromo(location.state);
    }
  }, []);
  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Promo code</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/PromoCode"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
              <div className="add-categorie-list mb-3 mb-5 pb-4 pt-4">
                <FontAwesomeIcon color="#fff" icon={faPencil} />
                <span className="mx-2">Edit Promo Code</span>
              </div>
            </div>
            <div className="edit-cust-grid">
              <div className="edit-cust-grid-inner text-end">
                <p>Promo Code Name</p>
              </div>
              <div className="edit-cust-grid-inner">
                <input
                  value={promo.name}
                  onChange={(e) => handleName(e)}
                  type="text"
                  placeholder="Promo Name"
                />
              </div>
            </div>
            <hr className="hr-edit-cust bg-white" />
            <div className="edit-cust-grid-sec">
              <div className="edit-cust-grid-inner-sec-txt">
                <p>Shipping Charges</p>
                <input
                  type="checkbox"
                  checked={handle}
                  onClick={() => {
                    handleCheck(1);
                  }}
                />
                <p className="pe-3">By Percentage</p>
                <input
                  type="checkbox"
                  checked={!handle}
                  onClick={() => {
                    handleCheck(2);
                  }}
                />
                <p>By Price</p>
              </div>
              <div className="edit-cust-grid-inner-sec">
                <input
                  type="text"
                  value={promo.charges}
                  onChange={(e) => handleCharges(e)}
                  placeholder="£10.00"
                />
              </div>
            </div>
            <hr className="hr-edit-cust bg-white" />
            <div className="edit-cust-grid">
              <div className="edit-cust-grid-inner text-end">
                <p>Quantity</p>
              </div>
              <div className="edit-cust-grid-inner">
                <input
                  type="text"
                  value={promo.quantity}
                  onChange={(e) => handleQty(e)}
                  placeholder="100"
                />
              </div>
            </div>
            <div
              className="grid-add d-grid my-4"
              // data-bs-toggle="modal"
              // data-bs-target="#exampleModal"
            >
              <label htmlFor=""></label>
              <button
                className="fw-bold add-save-btn rounded-3 border-0 ms-auto"
                onClick={handleSubmit}
              >
                Update
              </button>
              {/* <input
                className="fw-bold add-save-btn rounded-3 border-0 ms-auto"
                type="submit"
                value="Update"
              /> */}
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      {/* <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100">
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <Link
                to="/ManageReviews"
                className="btn-close text-dark position-relative bg-yellow d-flex align-items-center justify-content-center rounded-pill"
                data-bs-dismiss="modal"
              >
                X
              </Link>
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="green" fontSize="80px" icon={faCheck} />
              <h1 className="text-light">Promo Code has been updated</h1>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
}

export default EditPromoCode;
