import React, { useState, useEffect, useContext } from "react";
import brandhover from "../../assets/img/brandhover.png";
import headerlogo from "../../assets/img/Logo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import smallheaderhover from "../../assets/img/smallheaderhover.png";
import Warning from "../Warning";
import axios from "axios";
import { baseUrl } from "../../assets/Utils/Ip";
import { CartContext } from "../Cart";
import Headerlogotab from "../../assets/img/Logo.png";

function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const [category, setCategory] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const getCategory = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-all-category`);
        if (response?.status === 200) {
          // Assuming response.data is an array of categories
          const firstFourCategories = response?.data.slice(0, 4);
          setCategory(firstFourCategories);
        }
      } catch (err) {}
    };

    getCategory();
  }, []);

  const handleNaivgate = (id) => {
    navigate("/shop", {
      state: id,
    });
  };

  const { cartItems } = useContext(CartContext);
  const handleClickToHome = () => {
    navigate("/");
    localStorage.removeItem("cartItems");
    window.location.reload();
  };

  return (
    <div className="main-header font-bebas ">
      <Warning />

      <header className="header">
        <div className="container-1640">
          <div className="flhed tablet-hidden">
            <div className="logo">
              <Link to="/">
                <img src={headerlogo} alt="" />
              </Link>
            </div>
            <div className="menusheader">
              <ul className="d-flex">
                <li className="home-active">
                  <Link to="/" className="theme-text-yellow-light">
                    Home <img src={smallheaderhover} alt="" />
                  </Link>
                </li>
                <li className="about-active">
                  <Link className="theme-text-yellow-light" to="/About">
                    About Us <img src={smallheaderhover} alt="" />
                  </Link>
                </li>
                <li className="brandhover shop-active">
                  <Link className="theme-text-yellow-light" to="/shop">
                    Shop <i class="fa-solid fa-chevron-down"></i>
                    <img src={smallheaderhover} alt="" />
                  </Link>
                  <div className="hoverul">
                    <span>
                      <Link to="/shop" className="theme-text-yellow-light">
                        <img src={brandhover} alt="" />
                        <h1 className="theme-text-yellow-light">Shop All</h1>
                      </Link>
                    </span>
                    {category?.map((category) => (
                      <div key={category.category_id}>
                        <span>
                          <button
                            onClick={() => handleNaivgate(category.category_id)}
                            className="theme-text-yellow-light"
                          >
                            <img src={brandhover} alt="" />
                            <h1 className="theme-text-yellow-light">
                              {" "}
                              {category?.category_name}
                            </h1>
                          </button>
                        </span>
                      </div>
                    ))}
                  </div>
                </li>

                <li className="gift-active">
                  <Link to="/GiftSets" className="theme-text-yellow-light">
                    Gift Sets <img src={smallheaderhover} alt="" />
                  </Link>
                </li>

                <li className="sales-active">
                  <Link to="/SalePage" className="theme-text-yellow-light">
                    Sale Page
                    <img src={smallheaderhover} alt="" />
                  </Link>
                </li>

                {/* <li className="blog-active">
                  <Link to="/Blog" className="theme-text-yellow-light">
                    Blogs <img src={smallheaderhover} alt="" />
                  </Link>
                </li> */}

                <li className="Contact-active">
                  <Link className="theme-text-yellow-light" to="/Contact">
                    Contact Us <img src={smallheaderhover} alt="" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="iconsheader">
              {/* <div className="Account-my">
                <ul>
                  <li>
                    <Link to="">
                      <i class="fa-solid fa-user theme-text-yellow-light"></i>
                    </Link>
                  </li>
                </ul>
              </div> */}
              {/* <i className="fa-solid theme-text-yellow-light fa-heart"></i> */}
              <Link to="/cart" className="cart-count">
                {" "}
                <i className="fa-solid theme-text-yellow-light fa-cart-shopping"></i>
                {cartItems.length > 0 && (
                  <span className="badge">{cartItems.length}</span>
                )}
              </Link>
              {/* <i className="fa-solid theme-text-yellow-light fa-magnifying-glass"></i> */}
            </div>
          </div>
          {/* <div className="tab-grid-header-parent d-lg-none d-grid">
            <div className="hamburger">
              <div className="header-left d-lg-none d-block">
                <label className="hamburger-icon position-relative">
                  <input type="checkbox" className="hamburger-checkbox" />
                  <span />
                  <span />
                  <span />
                  <div className="drop-down-menu-parent">
                    <div className="links-drop-down-menu">
                      <ul className="d-flex flex-column gap-4">
                        <li className="home-active">
                          <Link
                            to="/"
                            className="theme-text-yellow-light header-menu-resp-font"
                          >
                            Home
                          </Link>
                        </li>
                        <li className="about-active">
                          <Link
                            className="theme-text-yellow-light header-menu-resp-font"
                            to="/About"
                          >
                            About Us
                          </Link>
                        </li>
                        <li className="brandhover shop-active">
                          <Link
                            className="theme-text-yellow-light header-menu-resp-font shop-drp"
                            to=""
                          >
                            Shop <i class="fa-solid fa-chevron-down"></i>
                          </Link>
                          <div className="hoverul">
                            <span>
                              <Link
                                to="/shop"
                                className="theme-text-yellow-light"
                              >
                                <h1 className="theme-text-yellow-light">
                                  Shop All
                                </h1>
                              </Link>
                            </span>
                            {category?.map((category) => (
                              <div key={category.category_id}>
                                <span>
                                  <button
                                    onClick={() =>
                                      handleNaivgate(category.category_id)
                                    }
                                    className="theme-text-yellow-light"
                                  >
                                    <img src={brandhover} alt="" />
                                    <h1 className="theme-text-yellow-light">
                                      {" "}
                                      {category?.category_name}
                                    </h1>
                                  </button>
                                </span>
                              </div>
                            ))}
                          </div>
                        </li>
                        <li className="gift-active">
                          <Link
                            to="/GiftSets"
                            className="theme-text-yellow-light header-menu-resp-font"
                          >
                            Gift Sets
                          </Link>
                        </li>
                        <li className="sales-active">
                          <Link
                            to="/SalePage"
                            className="theme-text-yellow-light header-menu-resp-font"
                          >
                            Sale Page
                          </Link>
                        </li>
                        <li className="Contact-active">
                          <Link
                            className="theme-text-yellow-light header-menu-resp-font"
                            to="/Contact"
                          >
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className="tab-logo text-center">
              <Link to="/">
                <img src={Headerlogotab} alt="" />
              </Link>
            </div>
            <div className="iconsheader">
              <Link to="/cart" className="cart-count">
                <i className="fa-solid theme-text-yellow-light fa-cart-shopping"></i>
                {cartItems.length > 0 && (
                  <span className="badge">{cartItems.length}</span>
                )}
              </Link>
            </div>
          </div> */}
        </div>
      </header>
    </div>
  );
}

export default Header;
