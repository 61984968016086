import React from "react";
import footerlogo from "../../assets/img/AboutLogo.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Copyright from "./Copyright";

function Footer({ top = 0 }) {
  return (
    <footer className={`mt-${top} footer-mean font-bebas`}>
      <div className="container-1640">
        <div className="row align-items-center">
          <div className="col-lg-2 m-auto d-lg-block d-flex justify-content-lg-start justify-content-center">
            <div className="footer-contact footer-log px-4">
              <Link to="/">
                <img src={footerlogo} alt="" />
              </Link>

              {/* <span className="foot-bg"></span>
                <h4>Get in touch</h4>
                <div class="ul-fo">
                  <ul>
                    <li class="footer-flex">
                      <span>
                        <i class="fa-solid fa-envelope fa-shake"></i>
                      </span>

                      <a href="mailto:info@loremipsum.com.au">
                        info@loremipsum.com.au
                      </a>
                      <a></a>
                    </li>

                    <li>
                      <span>
                        <i class="fa-solid fa-phone fa-bounce"></i>
                      </span>
                      <a href="tel:1234 567 890" target="_blank">
                        1234 567 890
                      </a>
                    </li>
                    <li>
                      <span>
                        <i class="fa-solid fa-location-dot fa-bounce"></i>
                      </span>

                      <a
                        href="https://goo.gl/maps/dLubk24PmPaWmQjU9"
                        target="_blank" rel="noreferrer"
                      >
                        Lorem Ipsum dolor sit
                      </a>
                    </li>
                  </ul>
                </div> */}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="footer-menu d-flex justify-content-lg-evenly justify-content-between px-lg-0 px-5">
              <ul>
                <li>
                  <h1 className="theme-text-color">Products</h1>
                </li>
                <li className="res-line-height-adjus">
                  <Link
                    to="/shop"
                    className="link-hover overflow-hidden  theme-text-yellow-light font-poppins link-hover"
                    data-replace="Best Sales"
                  >
                    <span>Best Sales</span>
                  </Link>
                </li>
                <li className="res-line-height-adjus">
                  <Link
                    to="/shop"
                    className="link-hover overflow-hidden  theme-text-yellow-light font-poppins"
                    data-replace="New Product "
                  >
                    <span>New Products</span>
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <h1 className="theme-text-color">Our Company</h1>
                </li>
                <li className="res-line-height-adjus">
                  <Link
                    to="/about"
                    className="link-hover overflow-hidden  theme-text-yellow-light font-poppins"
                    data-replace="About Us"
                  >
                    <span>About us</span>
                  </Link>
                </li>
                <li className="res-line-height-adjus">
                  <Link
                    to="/Refund-Policy"
                    className="link-hover overflow-hidden  theme-text-yellow-light font-poppins"
                    data-replace="Refund Policy"
                  >
                    <span>Refund Policy</span>
                  </Link>
                </li>
                <li className="res-line-height-adjus">
                  <Link
                    to=""
                    className="link-hover overflow-hidden  theme-text-yellow-light font-poppins"
                    data-replace="Secure Payment"
                  >
                    <span>Secure Payment</span>
                  </Link>
                </li>
                <li className="res-line-height-adjus">
                  <Link
                    to="/Privacy-Policy"
                    className="link-hover overflow-hidden  theme-text-yellow-light font-poppins"
                    data-replace="Privacy Policy"
                  >
                    <span>Privacy Policy</span>
                  </Link>
                </li>
                <li className="res-line-height-adjus">
                  <Link
                    to="/Contact"
                    className="link-hover overflow-hidden  theme-text-yellow-light font-poppins"
                    data-replace="Contact Us"
                  >
                    <span>Contact Us</span>
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="footer-contact">
              <span className="foot-bg"></span>
              <h4>Get in touch</h4>
              <div class="ul-fo">
                <ul>
                  <li class="footer-flex">
                    <span>
                      <i class="fa-solid fa-envelope fa-shake"></i>
                    </span>

                    <a href="mailto:info@loremipsum.com.au">
                      info@loremipsum.com.au
                    </a>
                    <a></a>
                  </li>

                  <li>
                    <span>
                      <i class="fa-solid fa-phone fa-bounce"></i>
                    </span>
                    <a href="tel:1234 567 890" target="_blank">
                      1234 567 890
                    </a>
                  </li>
                  <li>
                    <span>
                      <i class="fa-solid fa-location-dot fa-bounce"></i>
                    </span>

                    <a
                      href="https://goo.gl/maps/dLubk24PmPaWmQjU9"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Lorem Ipsum dolor sit
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
          <div className="col-lg-3 m-auto d-lg-block d-flex justify-content-center mt-lg-0 mt-4">
            {/* <div className="footer-social">
              <span className="foot-bg"></span>
              <h4 className="mb-4">Quick Links</h4>
              <ul>
                <li class="">
                  <Link to="/shop"> Shop</Link>
                </li>
                <li class="">
                  <Link to="/sales">Sale</Link>
                </li>
                <li class="">
                  <Link to="/Promotions">This Week's Promotion</Link>
                </li>
                <li class="">
                  <Link to="">Order Status</Link>
                </li>
                <li class="">
                  <Link to="/about">About Us</Link>
                </li>
                <li class="">
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
              <h4 className="mb-4 mt-4">Follow us</h4>
              <ul className="socialicons">
                <li>
                  <Link>
                    {" "}
                    <img src={facebook} alt="" />
                  </Link>
                </li>
                <li>
                  <Link>
                    {" "}
                    <img src={insta} alt="" />
                  </Link>
                </li>
                <li>
                  <Link>
                    {" "}
                    <img src={tiktok} alt="" />
                  </Link>
                </li>
              </ul>
            </div> */}
            <div className="footer-contact">
              <div className="social text-center">
                <Link to="https://www.facebook.com/people/Nisa-London/100094721649348/" target="_blank">
                  <FontAwesomeIcon
                    className="fs-2 mx-3 theme-text-yellow-light "
                    icon={faFacebook}
                  />
                </Link>
                <Link to="https://www.youtube.com/@NisaCosmaticsLondon" target="_blank">
                  <FontAwesomeIcon
                    className="fs-2 mx-3 theme-text-yellow-light "
                    icon={faYoutube}
                  />
                </Link>
                <Link to="https://www.instagram.com/nisalondonltd/" target="_blank">
                  <FontAwesomeIcon
                    className="fs-2 mx-3 theme-text-yellow-light "
                    icon={faInstagram}
                  />
                </Link>
                <Link to="">
                  <FontAwesomeIcon
                    className="fs-2 mx-3 theme-text-yellow-light "
                    icon={faLinkedin}
                  />
                </Link>
              </div>
              <div className="text-center font-poppins my-4 email-social">
                <Link
                  to="mailto:info@nisacosmeticslondon.com" target="_blank"
                  className="fs-5 theme-text-yellow-light"
                >
                  <FontAwesomeIcon className="me-2" icon={faEnvelope} />
                  info@nisacosmeticslondon.com
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Copyright />
    </footer>
  );
}

export default Footer;
