import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListUl,
  faTrash,
  faReply,
  faEye,
  faDotCircle,
  faCheck,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../Component/Sidebar";
import axios from "axios";
import { baseUrl } from "../../assets/Utils/Ip";
import { useEffect } from "react";
import { useState } from "react";

export default function Sale() {
  const token = localStorage.getItem("root");

  const [orders, setOrders] = useState([]);

  const fetchOrders = async () => {
    try {
      const res = await axios.get(`${baseUrl}/get-all-orders`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (res.status === 200) {
        setOrders(res.data.orders);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function formatDate(timestamp) {
    const dateObj = new Date(timestamp);

    const day = dateObj.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      dateObj
    );
    const year = dateObj.getFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    fetchOrders();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const orderExist = orders && orders.length > 0;

  const ordersArray = orderExist ? orders : [];

  const indexOfLastorder = currentPage * itemsPerPage;
  const indexOfFirstorder = indexOfLastorder - itemsPerPage;
  const currentorders = ordersArray.slice(indexOfFirstorder, indexOfLastorder);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSelectAll = () => {
    setSelectedProducts(orders?.map((products) => products?.order_id));
  };

  const handleDeselectAll = () => {
    setSelectedProducts([]); // Step 3
  };
  const handleBrandSelect = (productID) => {
    // Step 4
    if (selectedProducts.includes(productID)) {
      setSelectedProducts(selectedProducts.filter((id) => id !== productID));
    } else {
      setSelectedProducts([...selectedProducts, productID]);
    }
  };

  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Sales</h1>
                <div className="boxs-flex boxs-flex-sale-resp">
                  <Link
                    className="p-3 cate-icon-back justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2 boxs-flex-sale-resp"
                    to="/AdminProduct"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>

            <div className="d-flex justify-content-between align-items-center mt-2 mb-3">
              <div className="add-categorie-list">
                <FontAwesomeIcon color="#fff" icon={faListUl} />
                <span className="mx-2">Order List</span>
              </div>
              <div className="pagin">
                <select
                  aria-label="Default select example"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(e.target.value)}
                >
                  <option value={5} selected>
                    5
                  </option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
              </div>
            </div>
            <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                <div className="salesform ">
                  <div className="grid-sales-parent sales-grid-parent ">
                    <div className="inner-sale-grid  px-2">
                      <div className="inner-grid-sales">
                        <input
                          type="checkbox"
                          onChange={() =>
                            selectedProducts?.length < orders?.length
                              ? handleSelectAll()
                              : handleDeselectAll()
                          }
                          checked={
                            selectedProducts.length === orders?.length
                          }
                        />
                      </div>
                      <div className="inner-grid-sales">
                        <p>Order ID</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>Product</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>Category</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>Customer</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>Status</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>Total</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>Date Added</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>Action</p>
                      </div>
                    </div>

                    {currentorders &&
                      currentorders.map((item) => (
                        <div className="inner-sale-grid grid-sales-two border border-2 text-light px-2">
                          <div className="inner-grid-sales">
                            <input
                              type="checkbox"
                              onChange={() => handleBrandSelect(item.order_id)}
                              checked={selectedProducts.includes(item.order_id)}
                            />
                          </div>
                          <div className="inner-grid-sales">
                            <p>#{item.order_id}</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>{item.product_count}</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>{item.category_count}</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>{item.users}</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>
                              <FontAwesomeIcon
                                style={{ color: "#206c42", marginRight: "5px" }}
                                icon={faDotCircle}
                              />{" "}
                              {item.order_status}
                            </p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>${parseFloat(item.total_amount).toFixed(2)}</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>{formatDate(item.created_at)}</p>
                          </div>
                          <div className="inner-grid-sales">
                            <p>
                              <Link to={`/Saleview/${item.order_id}`}>
                                <FontAwesomeIcon
                                  icon={faEye}
                                  style={{ color: "white" }}
                                />
                              </Link>
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            {/* Pagination start */}
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex align-items-end">
                <div className="Pagination mt-4 ">
                  <div className="nmbering d-flex">
                    {Array.from({
                      length: Math.ceil(orders.length / itemsPerPage),
                    }).map((_, index) => (
                      <span
                        key={index}
                        className={
                          currentPage === index + 1 ? "active" : "pginte-curser"
                        }
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </span>
                    ))}
                  </div>
                  <div className="linepag">
                    <span></span>
                  </div>
                </div>
                <div className="left-right-arrow-pag">
                  <span
                    onClick={() => currentPage > 1 && paginate(currentPage - 1)}
                  >
                    <i
                      className="fa-solid fa-angle-left"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                  <span
                    onClick={() =>
                      currentPage < Math.ceil(orders.length / itemsPerPage) &&
                      paginate(currentPage + 1)
                    }
                  >
                    <i
                      className="fa-solid fa-angle-right"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
            {/* Pagination end */}
            {/* Pagination end */}
            {/* <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex align-items-end">
                <div className="Pagination mt-4 ">
                  <div className="nmbering d-flex">
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                    <span>4</span>
                    <span>5</span>
                  </div>
                  <div className="linepag">
                    <span></span>
                  </div>
                </div>
                <div className="left-right-arrow-pag">
                  <span>
                    <i className="fa-solid fa-angle-left"></i>
                  </span>
                  <span>
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </div>
              </div>
              <div>
                <p style={{ color: "white" }}>
                  Showing 1 to 10 of 90 (9 Pages)
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100">
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light position-relative"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light">
                You are about to delete this product
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-danger"
              >
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Modal title
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">..awdawdawdawd.</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade sales-view-invoice-modal"
        id="exampleModal3"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog delet-popup  d-flex align-items-center justify-content-center h-100">
          <div class="modal-content p-3">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light position-relative"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon
                color="#20A700"
                fontSize="100px"
                icon={faCheck}
              />{" "}
              <h1 className="text-light">You are about to Ship this order</h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-success"
              >
                Complete
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
