import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";

import { Link } from "react-router-dom";
import upcoming1 from "../assets/img/ProductPageImg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "../Components/Cart";
import { useContext } from "react";

function PaymentMethod() {
  const { cartItems, getCartTotal } = useContext(CartContext);
  return (
    <div>
      <Header />
      <div className="bef-aft">
        <div className="container-1640">
          <div className="toplinecon shiphum"></div>

          <section className="shippingsection pay">
            <div className="row">
              <div className="col-lg-5">
                <h1 class="carttitle">
                  <FontAwesomeIcon icon={faCreditCard} /> Payment
                </h1>
                <div className="font-poppins position-relative z-10 d-flex flex-column justify-content-between">
                  <div>
                    <div className="my-3">
                      <label
                        className="theme-text-yellow-light my-1"
                        htmlFor=""
                      >
                        Name On Card*
                      </label>
                      <input
                        type="text"
                        className="border-1 p-2 w-100 mx-auto border-golden placeholder-color bg-transparent theme-text-yellow-light d-block"
                      />
                    </div>
                    <div className="my-3">
                      <label
                        className="theme-text-yellow-light my-1"
                        htmlFor=""
                      >
                        Card Number*
                      </label>
                      <input
                        type="text"
                        className="border-1 p-2 w-100 mx-auto border-golden placeholder-color bg-transparent theme-text-yellow-light d-block"
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-6 my-3">
                        <label
                          className="theme-text-yellow-light my-1"
                          htmlFor=""
                        >
                          Expiry*
                        </label>
                        <input
                          type="text"
                          className="border-1 p-2 w-100 h-auto border-golden placeholder-color bg-transparent theme-text-yellow-light"
                        />
                      </div>
                      <div className="col-lg-6 my-3">
                        <label
                          className="theme-text-yellow-light my-1"
                          htmlFor=""
                        >
                          CVV*
                        </label>
                        <input
                          type="text"
                          className="border-1 p-2 w-100 h-auto border-golden placeholder-color bg-transparent theme-text-yellow-light"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="subtotalbtn pymnt-ordr-btn font-bebas">
                    <Link to="/OrderComplete"> Complete Order</Link>
                    <Link to="/Checkout">
                      <FontAwesomeIcon icon={faChevronLeft} /> back to shipping
                      details
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3  "></div>
              <div className="col-lg-4">
                <h1 className="text-center font-bebas theme-text-color mb-4">
                  Order Summary
                </h1>
                <div className="font-poppins parent-grids-modal-1 pt-4">
                  <div className="product-detail-grid-web font-bebas">
                    <div className="prdct-detail-txt">
                      <h2 className="theme-text-color">PRODUCT</h2>
                    </div>
                    <div className="prdct-detail-txt">
                      <h2 className="theme-text-color">QTY</h2>
                    </div>
                    <div className="prdct-detail-txt text-end">
                      <h2 className="theme-text-color">TOTAL PRICE</h2>
                    </div>
                  </div>
                  <div className="prdct-dtl-line">
                    <hr />
                  </div>
                  <div className="product-detail-grid-web set-grid">
                    <div className="prdct-detail-img">
                      <div className="prdct-txt-nxt-grid ">
                        <img
                          width="20%"
                          className="me-3"
                          src={upcoming1}
                          alt=""
                        />
                        <div>
                          <h2 className="theme-text-color">BB CREAM</h2>
                          <p className="fs-7 theme-text-yellow-light">
                            Lorem Ipsum dolor sit
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="theme-text-yellow-light prdct-detail-txt set-detail-txt font-bebas">
                      <h2>01</h2>
                    </div>
                    <div className="theme-text-yellow-light prdct-detail-txt set-detail-price font-bebas">
                      <h2>£34.20</h2>
                    </div>
                  </div>
                  <div className="prdct-dtl-line font-bebas">
                    <hr />
                  </div>
                  <div className="theme-text-yellow-light prdct-sub-total-flex font-bebas">
                    <h2>Subtotal :</h2>
                    <h3>£34.20</h3>
                  </div>
                  <div className="theme-text-yellow-light prdct-sub-total-flex set-sec-flex font-bebas">
                    <h2>SHIPPING :</h2>
                    <h3>£10.00</h3>
                  </div>
                  <div className="theme-text-yellow-light prdct-sub-total-line font-bebas">
                    <hr />
                  </div>
                  <div className="theme-text-yellow-light prdct-sub-total-flex set-th-flex font-bebas">
                    <h2>total :</h2>
                    <h3>£44.20</h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />{" "}
      </div>
    </div>
  );
}

export default PaymentMethod;
