import "./App.css";
import "../src/assets/css/style.css";
import "../src/assets/css/responsive.css";
import "../src/assets/DashboardCss/style.css";
import "../src/assets/DashboardCss/responsive.css";
import HomePage from "./Pages/HomePage";
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Shop from "./Pages/Shop";
import Buynow from "./Pages/Buynow";
import Cart from "./Pages/Cart";
import Checkout from "./Pages/Checkout";
// import Signup from "./Pages/Signup";
import ShippingCart from "./Pages/ShippingCart";
import PaymentMethod from "./Pages/PaymentMethod";
import OrderComplete from "./Pages/OrderComplete";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Login from "./Pages/Login";
import SearchGiftSets from "./Pages/SearchGiftset";
import GiftSets from "./Pages/GiftSets";
import TryOnPage from "./Pages/TryOnPage";
import SalePage from "./Pages/SalePage";
import Blogs from "./Pages/Blogs";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import RefundPolicy from "./Pages/RefundPolicy";
import DashboardAdmin from "./AdminDashboard/DashboardAdmin/DashboardAdmin";
import Categories from "./AdminDashboard/Categories/Categories";
import CategoriesForm from "./AdminDashboard/Categories/CategoriesForm";
import AddCategories from "./AdminDashboard/Categories/AddCategories";
import Variation from "./AdminDashboard/Variation/Variation";
import Addshade from "./AdminDashboard/Variation/AddShade";
import AdminProduct from "./AdminDashboard/Products/AdminProduct";
import Addproduct from "./AdminDashboard/Products/Addproduct";
import Saleview from "./AdminDashboard/Sales/Saleview";
import EditProduct from "./AdminDashboard/Products/EditProduct";
import Sale from "./AdminDashboard/Sales/Sale";
import Report from "./AdminDashboard/Report/Report";
import ManageReviews from "./AdminDashboard/Review/ManageReviews";
import EditReview from "./AdminDashboard/Review/EditReview";
import Editshade from "../src/AdminDashboard/Variation/EditShade";
import ManageShipping from "./AdminDashboard/Shipping/ManageShipping";
import AddShipping from "./AdminDashboard/Shipping/AddShipping";
import EditShipping from "./AdminDashboard/Shipping/EditShipping";
import PromoCode from "./AdminDashboard/Promo/PromoCode";
import AddPromoCode from "./AdminDashboard/Promo/AddPromoCode";
import EditPromoCode from "./AdminDashboard/Promo/EditPromoCode";
import NewsLetter from "./AdminDashboard/News/NewsLetter";
import DeletedProducts from "./AdminDashboard/Products/DeletedProducts";
import DeletedCategories from "./AdminDashboard/Categories/DeletedCategories";
import DeletedVariation from "./AdminDashboard/Variation/DeletedVariation";
import { useEffect } from "react";
import AdminProtectedRoutes from "./assets/AdminProtectedRoutes/AdminProtectedRoutes";
function App() {
  const [token, setToken] = useState("");
  const [isAdmin, setAdmin] = useState("");

  const handleToken = () => {
    const value = localStorage.getItem("root");
    if (value) {
      const payload = value.split(".");
      const payloadValue = JSON.parse(atob(payload[1]));

      let status = false;
      if (payloadValue.userId === "admin") {
        status = true;
      }
      setAdmin(status);
      setToken(value); // Set the token after setting isAdmin
    }
  };

  useEffect(() => {
    handleToken();
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/Buynow/:id" element={<Buynow />} />
        <Route path="/Cart" element={<Cart />} />

        <Route path="/checkout" element={<Checkout />} />
        <Route path="/shipping-cart" element={<ShippingCart />} />
        <Route path="/PaymentMethod" element={<PaymentMethod />} />
        <Route path="/OrderComplete" element={<OrderComplete />} />
        <Route path="/about" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/GiftSets" element={<SearchGiftSets />} />
      
        <Route path="/TryOnPage" element={<TryOnPage />} />
        <Route path="/SalePage" element={<SalePage />} />
        <Route path="/Blog" element={<Blogs />} />
        <Route path="/Login" element={<Login />} />

        {/* <Route path="/refund" element={<Refund />} /> */}
        {/* <Route path="/Privacy" element={<PrivacyCookies />} /> */}
        <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
        <Route path="/Refund-Policy" element={<RefundPolicy />} />
        {/* <Route path="/Delivery" element={<Delivery />} /> */}
        {/* <Route path="/How-Order" element={<HowOrder />} /> */}
        {/* <Route path="/Delivery-Time" element={<DeliveryTime />} /> */}
        {/* <Route path="/Track-Order" element={<TrackOrder />} /> */}
        {/* <Route path="/Product-Availability" element={<ProductAvailability />} /> */}
        {/* <Route path="/FAQ" element={<FAQ />} /> */}
        {/* <Route path="/Brand" element={<Brand />} /> */}
        {/* <Route path="/sales" element={<Sales />} /> */}
        {/* <Route path="/Promotions" element={<Promotionspage />} /> */}
        {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
        {/* <Route path="/enter-otp" element={<OTP />} /> */}
        {/* <Route path="/NewPassword" element={<NewPassword />} /> */}
        {/* Add more routes here */}

        {/* admin dashbaord */}
        <Route
          element={
            <AdminProtectedRoutes
              setToken={setToken}
              setAdmin={setAdmin}
              token={token}
              isAdmin={isAdmin}
            />
          }
        >
          <Route path="/DashboardAdmin" element={<DashboardAdmin />} />
          <Route path="/Categories" element={<Categories />} />
          <Route path="/CategoriesForm/:id" element={<CategoriesForm />} />
          <Route path="/AddCategories" element={<AddCategories />} />
          <Route path="/Variation" element={<Variation />} />
          <Route path="/Addshade" element={<Addshade />} />
          <Route path="/Editshade/:id" element={<Editshade />} />
          <Route path="/AdminProduct" element={<AdminProduct />} />
          <Route path="/Addproduct" element={<Addproduct />} />
          <Route path="/EditProduct/:id" element={<EditProduct />} />
          <Route path="/Saleview/:id" element={<Saleview />} />
          <Route path="/Sale" element={<Sale />} />
          <Route path="/Report" element={<Report />} />
          <Route path="/ManageReviews" element={<ManageReviews />} />
          <Route path="/EditReview" element={<EditReview />} />
          <Route path="/ManageShipping" element={<ManageShipping />} />
          <Route path="/AddShipping" element={<AddShipping />} />
          <Route path="/EditShipping" element={<EditShipping />} />
          <Route path="/PromoCode" element={<PromoCode />} />
          <Route path="/AddPromoCode" element={<AddPromoCode />} />
          <Route path="/EditPromoCode" element={<EditPromoCode />} />
          <Route path="/NewsLetter" element={<NewsLetter />} />
          <Route path="/DeletedProducts" element={<DeletedProducts />} />
          <Route path="/DeletedCategories" element={<DeletedCategories />} />
          <Route path="/DeletedVariation" element={<DeletedVariation />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
