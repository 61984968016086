import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faGear,
  faInfoCircle,
  faTruck,
  faCheck,
  faReply,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../Component/Sidebar";
import Swal from "sweetalert2";
import axios from "axios";
import { baseUrl } from "../../assets/Utils/Ip";
import { useState, useEffect } from "react";

const Saleview = () => {
  const token = localStorage.getItem("root");
  const [showStatus, setShowStatus] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");
  const [isChecked, setisChecked] = useState(false);
  const [allHistoryData, setAllHistoryData] = useState([]);
  const [orderFromParam, setOrderFromParam] = useState("");

  const [allShadeData, setAllShadeData] = useState([]);

  const toggleStatus = () => {
    setShowStatus(!showStatus);
  };

  const getAllHistoryByOrder = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/get-history-by-order/${orderId.id}`,
        {
          data: { order_id: orderId.id },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllHistoryData(res.data.history);
    } catch (error) { }
  };

  const navigate = useNavigate();

  const [history, setHistory] = useState({
    user_id: "",
    order_id: "",
    comment: "",
    status: "",
    notify: false,
  });

  // validation
  let regex;

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    // Assuming you want to update the 'status' property based on the selected option
    let newStatus = "";

    switch (selectedValue) {
      case "complete":
        newStatus = "complete";
        break;
      case "cancelled":
        newStatus = "cancelled";
        break;
      default:
        newStatus = "pending";
        break;
    }

    setHistory((prevHistory) => ({
      ...prevHistory,
      status: newStatus,
    }));

    // Add any other logic you want to perform when the option changes
  };
  // switch  Notify true or not
  const handleCheckSwitch = (e) => {
    const isChecked = e.target.checked;
    setisChecked(isChecked);
    setHistory((prevHistory) => ({
      ...prevHistory,
      notify: isChecked,
    }));
  };

  const comment = (value) => {
    // regex = new RegExp("^[A-Za-z0-9\\s]+$");
    if (value.length > 200) {
      return;
    }

    setHistory((prevHistory) => ({
      ...prevHistory,
      comment: value,
    }));
  };

  const orderId = useParams();

  // const getOrderById = async () => {
  //   try {
  //     const res = await axios.get(`${baseUrl}/get-order-by-id/${orderId.id}`, {
  //       data: { getOrderById: orderId.id },
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     // Update each item in the orderItems array
  //     const updatedOrderItems = res.data.orderItems.map((orderItem) => {
  //       const colorArray = JSON.parse(orderItem.selectedColor);

  //       // Create an object with updated data for each order item
  //       return {
  //         ...orderItem,
  //         selectedColor: colorArray,
  //       };
  //     });

  //     // Create an object with updated data including the updated orderItems array
  //     const updatedData = {
  //       ...res.data,
  //       orderItems: updatedOrderItems,
  //     };

  //     console.log(updatedData);

  //     // Store the updated data using setData
  //     setData(updatedData);
  //   } catch (error) {
  //     // Handle error
  //     console.log(error);
  //   }
  // };

  const getOrderById = async () => {
    if (allShadeData.length < 1) {
      return;
    }
    try {
      const res = await axios.get(`${baseUrl}/get-order-by-id/${orderId.id}`, {
        data: { getOrderById: orderId.id },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log('updatedOrderItems???', res.data)
      const updatedOrderItems = res.data.orderItems.map((orderItem) => {
        const parsedColor =
          orderItem?.selectedColor && isValidJson(orderItem.selectedColor)
            ? JSON.parse(orderItem?.selectedColor)
            : orderItem?.selectedColor;

        const lowerParsedColor = Array.isArray(parsedColor)
          ? String(parsedColor[0]).toLowerCase()
          : String(parsedColor).toLowerCase();
        // console.log('lowerParsedColor>>>', lowerParsedColor)

        const matchedShade = allShadeData.find(
          (shade) => shade.shade.toLowerCase() === lowerParsedColor
        );

        const updatedOrderItem = {
          ...orderItem,
          selectedColor: matchedShade ? matchedShade.shade_name : parsedColor,
        };

        return updatedOrderItem;
      });

      const updatedData = {
        ...res.data,
        orderItems: updatedOrderItems,
      };

      // Assuming setData is a function to update state
      setData(updatedData);
    } catch (error) {
      // Handle error
      console.log(error);
    }
  };

  const getAllShade = async () => {
    try {
      const res = await axios.get(`${baseUrl}/get-all-shade`);
      // console.log(res.data);
      setAllShadeData(res.data);
    } catch (error) {
      console.log(error);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const requestData = {
        user_id: history.user_id,
        order_id: history.order_id,
        comment: history.comment,
        status: history.status,
        notify: history.notify,
      };

      if (history?.status === "") {
        Swal.fire({
          icon: "error",
          title: "Please select status",
          text: "",
        });
        return;
      }
      const res = await axios.post(`${baseUrl}/create-history`, requestData);
      if (
        res.data?.message ==
        "History entry created and order status updated successfully"
      ) {
        Swal.fire({
          icon: "success",
          title: "Add History",
          text: "Your History Added successfully",
        });
        setisChecked(false);

        navigate(1);
        getAllHistoryByOrder();
      }
    } catch (error) {
      if (error?.response?.data.message) {
        Swal.fire({
          icon: "error",
          title: "Add History added not successfully",
          text: "Please recheck and fill all field",
        });
      }
    }
  };

  function formatDate(timestamp) {
    const dateObj = new Date(timestamp);

    const day = dateObj.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      dateObj
    );
    const year = dateObj.getFullYear();

    return `${day}/${month}/${year}`;
  }


  function isValidJson(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }


  useEffect(() => {
    setEditorLoaded(true);
    getAllShade();
  }, []);

  useEffect(() => {
    setEditorLoaded(true);
    getOrderById();
  }, [allShadeData]);



  useEffect(() => {
    setEditorLoaded(true);
    getAllHistoryByOrder();
  }, []);



  useEffect(() => {
    setEditorLoaded(true);
    getOrderById();
  }, [orderId.id, token]);

  useEffect(() => {
    // Update history state based on the retrieved data
    if (data.orderItems && data.orderItems.length > 0) {
      setOrderFromParam(data.orderItems[0].order_id);
      setHistory({
        user_id: JSON.stringify(data.orderItems[0].user_id),
        order_id: JSON.stringify(data.orderItems[0].order_id),
      });
    }
  }, [data]); // Include data in the dependency array


  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Order</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/Sale"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <div className="pd-customer-row py-2">
              <div className="pd-customer-row ">
                <div className="add-categorie-list py-3">
                  <FontAwesomeIcon color="#fff" icon={faInfoCircle} />
                  <span className="mx-2">
                    Order (#{data?.orderItems?.[0]?.order_id})
                  </span>
                </div>
                <div className="inv-row-parent">
                  <div className="row py-4">
                    <div className="col-lg-4">
                      <div className="env-box position-relative text-dark bg-light">
                        <h5>Invoice</h5>
                        <p>
                          INV-
                          {data?.orderItems?.[0]?.order_id +
                            data?.orderItems?.[0]?.created_at
                              ?.replace(/[-T:]/g, "")
                              .slice(0, -1)}
                        </p>
                        <div className="setting-icn-box">
                          <button
                            className="h-100 w-100 px-1 bg-transparent border-0"
                          // data-bs-toggle="modal"
                          // data-bs-target="#exampleModal"
                          >
                            <FontAwesomeIcon icon={faGear} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="env-box bg-light text-dark">
                        <h5>Customer</h5>
                        <p>{data?.orderItems?.[0]?.full_name}</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="env-box bg-light text-dark">
                        <h5>Date Added</h5>
                        <p>
                          {data?.orderItems?.[0]?.created_at
                            ? formatDate(data?.orderItems?.[0]?.created_at)
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-fi-order-box-master">
                  <div className="main-fi-order-box">
                    <div className="sales-view-tble-parent">
                      <div className="sales-view-table">
                        <div className="sales-view-table-inner sale-indent">
                          <p>Product</p>
                        </div>
                        <div className="sales-view-table-inner text-center">
                          <p>Category</p>
                        </div>
                        <div className="sales-view-table-inner text-center">
                          <p> Shade</p>
                        </div>
                        <div className="sales-view-table-inner text-center">
                          <p> Quantity </p>
                        </div>
                        <div className="sales-view-table-inner text-center">
                          <p>Unit Price</p>
                        </div>
                        <div className="sales-view-table-inner text-center">
                          <p>Total</p>
                        </div>
                        {/* <div className="sales-view-table-inner text-center">
                      <p>Action</p>
                    </div> */}
                      </div>

                      {data?.orderItems &&
                        data?.orderItems?.map((orderItem) => (
                          <div
                            key={orderItem.variation_name}
                            className="sales-view-table sales-view-table-semi text-light"
                          >
                            <div className="sales-view-table-inner sale-indent brdr-right-sl-view ">
                              <p>{orderItem?.product_name}</p>
                            </div>
                            <div className="sales-view-table-inner text-center brdr-right-sl-view">
                              <p>{orderItem?.category_name}</p>
                            </div>
                            <div className="sales-view-table-inner sale-indent brdr-right-sl-view text-center justify-content-center">
                              {/* {orderItem?.selectedColor?.map((item, index) => ( */}
                              {/* <p className="sale-shade-color" >{orderItem?.selectedColor}</p> */}
                              {/* ))} */}
                              <p>{orderItem?.selectedColor}</p>
                            </div>
                            <div className="sales-view-table-inner sale-indent brdr-right-sl-view ">
                              <p>{orderItem?.quantity}</p>
                            </div>
                            <div className="sales-view-table-inner sale-indent brdr-right-sl-view ">
                              <p>{orderItem?.unit_price}</p>
                            </div>
                            <div className="sales-view-table-inner sale-indent brdr-right-sl-view justify-content-end">
                              <p>{orderItem?.sub_total}</p>
                            </div>
                            {/* <div className="sales-view-table-inner text-center">
                      <p>
                        <button>
                          <FontAwesomeIcon icon={faMinus} />
                        </button>
                      </p>
                    </div> */}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="billing-row-parent my-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="billing-box rounded-3 position-relative">
                        <div>
                          <h5>Payment Address</h5>
                          <p>{data?.orderItems?.[0]?.full_name}</p>
                          <p>{data?.orderItems?.[0]?.contact}</p>
                          <p>{data?.orderItems?.[0]?.billing_address}</p>
                          <p>{data?.orderItems?.[0]?.city}</p>
                          <p>{data?.orderItems?.[0]?.post_code}</p>
                          <p>{data?.orderItems?.[0]?.country}</p>
                        </div>
                        <div>
                          <button
                            className="setting-icn-box btn"
                          // data-bs-toggle="modal"
                          // data-bs-target="#exampleModal1"
                          >
                            <FontAwesomeIcon icon={faGear} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="billing-box rounded-3 position-relative">
                        <h5>Shipping Address</h5>
                        <p>{data?.orderItems?.[0]?.full_name}</p>
                        <p>{data?.orderItems?.[0]?.contact}</p>
                        <p>{data?.orderItems?.[0]?.shipping_address}</p>
                        <p>{data?.orderItems?.[0]?.city}</p>
                        <p>{data?.orderItems?.[0]?.post_code}</p>
                        <p>{data?.orderItems?.[0]?.country}</p>
                        <button
                          className="setting-icn-box btn"
                        // data-bs-toggle="modal"
                        // data-bs-target="#exampleModal2"
                        >
                          <FontAwesomeIcon icon={faGear} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="method-payment-row-prnt">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="py-mthd-box">
                        <h5>Shipping Method</h5>
                        <p>
                          {data?.orderItems?.[0]?.shipping_name
                            ? data?.orderItems?.[0]?.shipping_name
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="py-mthd-box">
                        <h5>Payment Method</h5>
                        <p>Credit Card</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comment-row my-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="env-box position-relative text-dark bg-light">
                        <p>Comment</p>
                        <div className="setting-icn-box">
                          <button
                            className="h-100 w-100 px-1 bg-transparent border-0"
                          // data-bs-toggle="modal"
                          // data-bs-target="#instructionModal"
                          >
                            <FontAwesomeIcon icon={faGear} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="total-price-row ">
                  <div className="row">
                    <div className="col-md-10 col-9 pe-0">
                      <div className="border  border-1 text-end bg-light">
                        <p className="border p-1 fw-bold">Sub Total</p>
                        <p className="border p-1 fw-bold">Promo Code</p>
                        {/* <p className="border p-1 fw-bold">Promo Discount</p> */}
                        <p className="border p-1 fw-bold">Flat Shipping Rate</p>
                        <p className="border p-1 fw-bold">Total</p>
                      </div>
                    </div>
                    <div className="col-md-2 col-3 ps-0">
                      <div className=" border border-1 text-center bg-light">
                        <p className="border p-1">
                          £
                          {data?.orderItems?.[0]?.total_order_subtotal
                            ? parseFloat(
                              data?.orderItems?.[0]?.total_order_subtotal
                            ).toFixed(2)
                            : "0.00"}
                        </p>
                        <p className="border p-1">
                          {data?.orderItems?.[0]?.promo_code
                            ? data?.orderItems?.[0]?.promo_code
                            : "0.00"}
                        </p>
                        {/* <p className="border p-1">
                          {data?.orderItems?.[0]?.promo_charges
                            ? (data?.orderItems?.[0]?.promo_status === "price"
                                ? "£"
                                : "") +
                              data?.orderItems?.[0]?.promo_charges +
                              (data?.orderItems?.[0]?.promo_status === "price"
                                ? ""
                                : "%")
                            : "0.00"}
                        </p> */}

                        <p className="border p-1">
                          {data?.orderItems?.[0]?.shiping_charges
                              ? "£" +
                              parseFloat(
                                data?.orderItems?.[0]?.shiping_charges
                              ).toFixed(2)
                              : "£" + "0.00"}
                        </p>
                        <p className="border p-1">
                          £
                          {data?.orderItems?.[0]?.total_amount
                            ? parseFloat(
                              data?.orderItems?.[0]?.total_amount
                            ).toFixed(2)
                            : "0.00"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sales-history-tble-parent my-4">
                  <div className="add-categorie-list py-3">
                    <FontAwesomeIcon color="white" icon={faMessage} />
                    <span className="mx-2">History</span>
                  </div>
                  <div className="main-fi-order-box-master">
                    <div className="main-fi-order-box">
                      <div className="sales-history-table bg-golden-gradeint d-grid p-3">
                        <div className="sales-view-table-inner sale-indent">
                          <p>Date Added</p>
                        </div>
                        <div className="sales-view-table-inner text-center">
                          <p>Comments</p>
                        </div>
                        <div className="sales-view-table-inner text-center">
                          <p>Status</p>
                        </div>
                        <div className="sales-view-table-inner text-center">
                          <p>Customer Notified</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {allHistoryData &&
                    allHistoryData?.map((history) => (
                      <div
                        key={history.history_id}
                        className="sales-history-table d-grid text-light border"
                      >
                        <div className="sales-view-table-inner sale-indent brdr-right-sl-view p-2">
                          <p>{formatDate(history.created_at)}</p>
                        </div>
                        <div className="sales-view-table-inner text-center brdr-right-sl-view p-2">
                          <p>{history.comment}</p>
                        </div>
                        <div className="sales-view-table-inner sale-indent brdr-right-sl-view p-2 text-center">
                          <p>{history.status}</p>
                        </div>
                        <div className="sales-view-table-inner sale-indent brdr-right-sl-view p-2 text-center">
                          <p>{history.notify === 1 ? "Yes" : "No"}</p>
                        </div>
                      </div>
                    ))}
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="add-categorie-list py-3">
                    <span className="mx-2">Add History</span>
                  </div>
                  <div className="grid-add d-grid align-items-center my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Order Status
                    </label>
                    <select
                      className="py-1 px-2"
                      value={history.status}
                      onChange={handleOptionChange}
                      required
                    >
                      <option value="" disabled selected>
                        Select Status
                      </option>
                      <option value="complete">Complete</option>
                      <option value="pending">Is Pending</option>
                      <option value="cancelled">Cancelled</option>
                    </select>
                  </div>
                  <hr className="text-light my-4" />

                  <div className="grid-add d-grid my-4 form-check form-switch p-0 Notify-Customer-btn">
                    <label className="text-light d-block text-end" htmlFor="">
                      Notify Customer
                    </label>
                    <input
                      class="form-check-input m-0"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      checked={isChecked}
                      onChange={handleCheckSwitch}
                    />
                  </div>
                  <hr className="text-light my-4" />
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Comment
                    </label>
                    <textarea
                      rows="5"
                      className="py-1 px-2"
                      type="text"
                      value={history.comment}
                      onChange={(e) => {
                        comment(e.target.value);
                      }}
                      required
                    ></textarea>
                  </div>
                  <div className="grid-add d-grid my-4 History-btn">
                    <label htmlFor=""></label>
                    <input
                      className="fw-bold add-save-btn w-25 rounded-3 border-0 ms-auto"
                      type="submit"
                      value="+ Add History "
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* MODAL  1*/}
      <div
        className="modal fade sales-view-invoice-modal"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h1
                className="modal-title fs-5 text-light text-center"
                id="exampleModalLabel"
              >
                INV-2022-000
              </h1>
            </div>
            <div className="modal-body p-0">
              <div className="inv-modal-input-parent">
                <div className="grid-inpt-modal-parent">
                  <div className="grid-inpt-modal">
                    <label htmlFor="inv-mdl-one text-light">Order #</label>
                    <input type="number" placeholder={1446} id="inv-mdl-one" />
                  </div>
                  <div className="grid-inpt-modal">
                    <label htmlFor="inv-mdl-two">Date Added</label>
                    <input
                      type="text"
                      placeholder="25/07/2023"
                      id="inv-mdl-two"
                    />
                  </div>
                </div>
                <div className="grid-inpt-modal-parent">
                  <div className="grid-inpt-modal">
                    <label htmlFor="inv-mdl-three">Name</label>
                    <input
                      type="text"
                      placeholder="Mark Allen"
                      id="inv-mdl-three"
                    />
                  </div>
                  <div className="grid-inpt-modal">
                    <label htmlFor="inv-mdl-four">Email</label>
                    <input
                      type="email"
                      placeholder="mark_allen@gmail.com"
                      id="inv-mdl-four"
                    />
                  </div>
                  <div className="grid-inpt-modal">
                    <label htmlFor="inv-mdl-five">Contact #</label>
                    <input
                      type="number"
                      placeholder="1234 567 890"
                      id="inv-mdl-five"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="grid-inpt-modal">
                      <label htmlFor="inv-mdl-six">Shipping Address</label>
                      <textarea
                        id="inv-mdl-six"
                        className="fs-7 text-dark"
                        placeholder="Lorem ipsum dolor sit amet, consect etur adipiscing elit, sed do eiusmod 
tempor incididunt magna aliqua."
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="grid-inpt-modal">
                      <label htmlFor="inv-mdl-six">Billing Address</label>
                      <textarea
                        id="inv-mdl-six"
                        className="fs-7 text-dark"
                        placeholder="Lorem ipsum dolor sit amet, consect etur adipiscing elit, sed do eiusmod 
tempor incididunt magna aliqua."
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="grid-inpt-modal">
                      <label htmlFor="inv-mdl-seven">Payment Method</label>
                      <input
                        type="text"
                        id="inv-mdl-six"
                        placeholder="Credit Card"
                      />
                    </div>
                  </div>
                </div>
                <div className="table-modal-parent">
                  <div className="table-modal-inner-parent">
                    <div className="table-modal-inner text-center mdl-right-border">
                      <p>Product</p>
                    </div>
                    <div className="table-modal-inner text-center mdl-right-border">
                      <p>Brand</p>
                    </div>
                    <div className="table-modal-inner text-center mdl-right-border">
                      <p>Quantity</p>
                    </div>
                    <div className="table-modal-inner text-center mdl-right-border">
                      <p>Unit Price</p>
                    </div>
                    <div className="table-modal-inner text-start pd-left-mdl-ttl">
                      <p>Total</p>
                    </div>
                  </div>
                  <div className="table-modal-inner-parent mdl-top-border">
                    <div className="table-modal-inner text-center mdl-right-border">
                      <p>Product Name</p>
                    </div>
                    <div className="table-modal-inner text-center mdl-right-border">
                      <p>Dunhill</p>
                    </div>
                    <div className="table-modal-inner text-center mdl-right-border">
                      <p>2</p>
                    </div>
                    <div className="table-modal-inner text-center mdl-right-border">
                      <p>$122.00</p>
                    </div>
                    <div className="table-modal-inner text-start pd-left-mdl-ttl">
                      <p>$244.00</p>
                    </div>
                  </div>
                  <div className="total-price-grid ">
                    <div>
                      <div className="text-end text-light">
                        <p className="border p-1">Sub Total</p>
                        <p className="border p-1">Flat Shipping Rate</p>
                        <p className="border p-1">Total</p>
                      </div>
                    </div>
                    <div>
                      <div className="text-light text-center">
                        <p className="border p-1">$200.00</p>
                        <p className="border p-1">$50.00</p>
                        <p className="border  p-1">$250.00</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* MODAL  1*/}
      {/* MODAL  2*/}
      <div
        class="modal fade sales-view-invoice-modal"
        id="exampleModal1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content p-3">
            <div class="modal-header  border-0">
              <h5 class="modal-title text-light" id="exampleModalLabel">
                Payment Address
              </h5>
            </div>
            <div class="modal-body">
              <form action="">
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Full Name
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Contact Number
                  </label>
                  <input type="number" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Email Address
                  </label>
                  <input type="email" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Billing Address
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Shipping Address
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Country
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    City
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Post Code
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Special Instruction
                  </label>
                  <textarea type="text" className="w-100 my-2 fs-7"></textarea>
                </div>
                <div className="my-2 text-end">
                  <button
                    type="button"
                    className="btn-secondary border-0 rounded p-1 px-3 me-4"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <input
                    type="submit"
                    value="Save"
                    className="btn-gradient border-0 rounded p-1 px-3"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* MODAL  2*/}
      {/* MODAL  3*/}
      <div
        class="modal fade sales-view-invoice-modal"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content p-3">
            <div class="modal-header  border-0">
              <h5 class="modal-title text-light" id="exampleModalLabel">
                Shipping Address
              </h5>
            </div>
            <div class="modal-body">
              <form action="">
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Full Name
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Contact Number
                  </label>
                  <input type="number" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Email Address
                  </label>
                  <input type="email" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Billing Address
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Shipping Address
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Country
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    City
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Post Code
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Special Instruction
                  </label>
                  <textarea type="text" className="w-100 my-2 fs-7"></textarea>
                </div>
                <div className="my-2 text-end">
                  <button
                    type="button"
                    className="btn-secondary border-0 rounded p-1 px-3 me-4"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <input
                    type="submit"
                    value="Save"
                    className="btn-gradient border-0 rounded p-1 px-3"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* MODAL  3*/}
      {/* MODAL  3*/}
      <div
        class="modal fade sales-view-invoice-modal"
        id="instructionModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog d-flex align-items-center justify-content-center h-75">
          <div class="modal-content p-3">
            <div class="modal-header  border-0">
              <h5 class="modal-title text-light" id="exampleModalLabel">
                Instruction
              </h5>
            </div>
            <div class="modal-body">
              <form action="">
                <div>
                  <label className="d-block text-light" htmlFor=""></label>
                  <textarea
                    type="text"
                    rows="7"
                    className="w-100 my-2 fs-7"
                    value={
                      data?.orderItems?.[0]?.instructions
                        ? data?.orderItems?.[0]?.instructions
                        : ""
                    }
                  ></textarea>
                </div>

                <div className="my-2 text-end">
                  {/* <button
                    type="button"
                    className="btn-secondary border-0 rounded p-1 px-3 me-4"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <input
                    type="submit"
                    value="Save"
                    className="btn-gradient border-0 rounded p-1 px-3"
                  /> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* MODAL  3*/}

      {/* MODAL  4*/}
      <div
        class="modal fade sales-view-invoice-modal"
        id="exampleModal15"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog d-flex align-items-center justify-content-center h-75">
          <div class="modal-content p-3">
            <div class="modal-header  border-0">
              <h5 class="modal-title text-light" id="exampleModalLabel">
                Add Product
              </h5>
            </div>
            <div class="modal-body">
              <form action="">
                <div class="grid-inpt-modal">
                  <label for="inv-mdl-seven">Choose Product</label>
                  <select className="select-com">
                    <option value="1">Pure CSS Select</option>
                    <option value="2">With wrapper</option>
                    <option value="3">No JS!</option>
                  </select>
                </div>

                <div class="grid-inpt-modal">
                  <label for="inv-mdl-seven">Quantity</label>
                  <input type="number" />
                </div>

                <div className="my-2 text-end">
                  <input
                    type="submit"
                    value="Save"
                    className="btn-gradient border-0 rounded p-1 px-3"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* MODAL  3*/}

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal20"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100">
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light position-relative"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light">You are about to cancel this order</h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-danger"
              >
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        class="modal fade sales-view-invoice-modal"
        id="exampleModal23"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog delet-popup  d-flex align-items-center justify-content-center h-100">
          <div class="modal-content p-3">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light position-relative"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon
                color="#20A700"
                fontSize="100px"
                icon={faCheck}
              />{" "}
              <h1 className="text-light">You are about to Ship this order</h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-success"
              >
                Complete
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Saleview;
