import React from "react";
import Header from "../Components/Header/Header";
import { Link } from "react-router-dom";
// import buyleftspan from "../assets/images/buyleftspan.png";
// import buyrightspan from "../assets/images/buyrightspan.png";
// import headerlogo from "../assets/images/headerlogo.png";
import Footer from "../Components/Footer/Footer";
// import arrowul from "../assets/images/arrowul.png";

function PrivacyPolicy() {
  return (
    <div>
      <Header />
      <div className="bef-aft">
        <section className="policiessection">
          <div className="container-1640 pt-3">
            <div className="refundcontent">
              <h1 class="carttitle privacyPolicy">Privacy Policy</h1>
              <div>
                <h6 className="theme-text-yellow-light font-poppins">
                  Last updated on:<span className="fw-regular">10/5/2022</span>
                </h6>
                <p className="font-poppins text-white">
                  Thank you for choosing to be a part of the Nisa London
                  community in the United Kingdom. We are committed to
                  safeguarding your personal information and respecting your
                  right to privacy. Please contact us at
                  info@nisacosmeticslondon.com if you have any questions or
                  concerns about our Privacy Policy or our practices regarding
                  your personal information.
                </p>
                <p className="font-poppins text-white">
                  You entrust us with your personal information when you visit
                  our Site and use our Services. We value your privacy very
                  much. We hope that this Privacy Policy explains what
                  information we collect, how we use it, and what rights you
                  have in relation to it as clearly as possible. We hope you
                  will take the time to read it thoroughly because it is
                  critical. If you do not agree with any of the terms in this
                  Privacy Policy, please stop using our Site and Services.
                </p>
                <p className="font-poppins text-white">
                  The Site provides consumers with an easy way to find, learn
                  about, and buy Products from us (the “Products”). This Privacy
                  Policy and our Cookie Policy are incorporated into our Terms
                  of Service (collectively, the “Terms”), and the Terms apply to
                  your use of the Site, whether as a guest or registered user,
                  as well as any content, functionality, and services offered on
                  or through the Site (collectively, the “Services”).
                </p>
                <p className="font-poppins text-white">
                  Please carefully read this Privacy Policy as it will assist
                  you in making informed decisions about sharing your personal
                  information with us.
                </p>
              </div>
              <p className="paratitle bold" style={{ marginBottom: "20px" }}>
                HOW DO WE COLLECT INFORMATION? <br /> The personal information
                you disclose to us
              </p>
              <p className="paratitle">
                The Fundamentals: We collect personal information that you
                provide to us.
              </p>
              <p className="paratitle">
                We collect personal information that you voluntarily provide to
                us when you register for the Services, express an interest in
                learning more about us or the Products and Services, or
                participate in activities on the Services (such as posting
                reviews or entering competitions, contests, or giveaways), or
                otherwise contact us.
              </p>
              <p className="paratitle">
                We collect personal information based on the context of your
                interactions with us and the Services, your choices, and the
                Products and features you use. We may collect the following
                types of personal information:
              </p>

              <p className="paratitle bold" style={{ marginBottom: "20px" }}>
                You Provide Personal Information and Publicly Available Personal
                Information
              </p>
              <p className="paratitle">
                We collect first and last names, usernames, and nicknames, as
                well as IDs, current and former addresses, phone numbers, email
                addresses, credit card numbers, and other similar information.
              </p>

              <p className="paratitle bold" style={{ marginBottom: "30px" }}>
                Payment Information.
              </p>

              <p className="paratitle">
                If you make purchases, we collect data such as your payment
                instrument number (such as a credit card number) and the
                security code associated with your payment instrument. Shop Pay,
                Apple Pay, Google Pay, and PayPal Express all store all payment
                information.
              </p>
              <p className="paratitle">
                All personal information you provide to us must be true,
                complete, and accurate, and you must notify us if your personal
                information changes.
              </p>

              <p className="paratitle bold" style={{ marginBottom: "30px" }}>
                Data that is automatically collected
              </p>

              <p className="paratitle">
                The Fundamentals: When you visit our Services, some information
                is automatically collected, such as your IP address and/or
                browser and device characteristics.
              </p>
              <p className="paratitle">
                When you visit, use, or navigate the Services, we automatically
                collect certain information. This information does not reveal
                your specific identity (such as your name or contact
                information), but it may include device and usage data such as
                your IP address, browser and device characteristics, operating
                system, language preferences, referring URLs, device name,
                country, location, information about how and when you use our
                Services, and other technical information. This information is
                primarily required to ensure the security and operation of our
                Services, as well as for internal analytics and reporting.
              </p>
              <p className="paratitle">
                We, like many other businesses, collect information through
                cookies and other similar technologies.
              </p>

              <p className="paratitle bold" style={{ marginBottom: "30px" }}>
                Identifiers are used online.
              </p>

              <p className="paratitle">
                We collect tools and protocols such as IP (Internet Protocol)
                addresses, cookie identifiers or other similar identifiers such
                as those used for analytics and marketing, device geolocation,
                devices, applications, and other similar data.
              </p>

              <p className="paratitle bold" style={{ marginBottom: "30px" }}>
                HOW WILL WE APPLY YOUR INFORMATION?
              </p>

              <p className="paratitle">
                The Fundamentals: We process your information for legitimate
                business interests, contract fulfilment, compliance with our
                legal obligations, and/or your consent.
              </p>
              <p className="paratitle">
                We use personal information collected through our Services for
                the following business purposes: We process your personal
                information for these purposes in order to enter into or perform
                a contract with you, with your consent, and/or to comply with
                our legal obligations. Next to each purpose listed below, we
                indicate the specific processing grounds on which we rely.
              </p>
              <p className="paratitle">
                We use the information we collect or receive in the following
                ways:
              </p>

              <ul className="policy-ul font-poppins text-white">
                <li>
                  To make the account creation and login process easier. If you
                  choose to link your account with us to a third-party account
                  (such as your Google or Facebook account), we will use the
                  information you permitted us to collect from those third
                  parties to facilitate account creation and login process for
                  contract performance.
                </li>
                <li>
                  To send you marketing and promotional materials. If your
                  marketing preferences allow it, we and/or our third-party
                  marketing partners may use the personal information you send
                  us for marketing purposes. You can opt out of our marketing
                  emails at any time.
                </li>
                <li>
                  To send you administrative information. We may use your
                  personal information to send you information about new
                  products, services, and features, as well as changes to our
                  terms, conditions, and policies.
                </li>
                <li>
                  Order fulfilment and management.We may use your information to
                  fulfill and manage orders, payments, returns, and exchanges
                  placed via the Services.
                </li>
                <li>
                  To publish reviews. We publish product reviews that may
                  contain personally identifiable information. Please contact us
                  at info@nisacosmeticslondon.com with your name, review
                  location, and contact information if you want to update or
                  delete your review.
                </li>
                <li>
                  Provide you with targeted advertising. We may use your
                  information to create and display content and advertising
                  (along with third-party partners) tailored to your interests
                  and/or location, as well as to measure their effectiveness.
                </li>
                <li>
                  Solicit feedback. We may use your information to contact you
                  about your use of our Services and to solicit feedback.
                </li>
                <li>
                  To safeguard our services. We may use your information to help
                  us keep our Services safe and secure (for example, for fraud
                  monitoring and prevention).
                </li>
                <li>
                  To enforce our terms, conditions, and policies for commercial,
                  legal, and/or contractual purposes.
                </li>
                <li>
                  Responding to legal requests and preventing harm. We may need
                  to inspect the data we hold to determine how to respond if we
                  receive a subpoena or other legal request.
                </li>
                <li>
                  To administer user accounts. We may use your information to
                  manage our account and keep it in good working order.
                </li>
                <li>
                  To provide services to the user. We may use your information
                  to provide you with the service you have requested.
                </li>
                <li>
                  Respond to user inquiries and provide user support. We may use
                  your information to respond to your inquiries and resolve any
                  issues you may have with our Services.
                </li>
                <li>
                  For other commercial purposes. We may also use your
                  information for Business Purposes such as data analysis,
                  identifying usage trends, determining the effectiveness of our
                  promotional campaigns, and evaluating and improving our
                  Services, products, marketing, and your experience. We may use
                  and store this information in aggregated and anonymized form
                  so that it cannot be associated with individual end-users and
                  does not contain personal information. Without your
                  permission, we will not use identifiable personal information.
                </li>
              </ul>

              <p className="paratitle bold" style={{ marginBottom: "30px" }}>
                Cookie Policy
              </p>
              <ul className="policy-ul font-poppins text-white">
                <ol>
                  Certain Cookies may be placed and accessed by this Site on
                  your computer. Nisa London employs cookies to enhance your
                  browsing experience and to expand our product offerings. Nisa
                  London carefully selected these Cookies and has taken steps to
                  ensure that your privacy is always protected and respected.
                </ol>
                <ol>
                  This Site’s Cookies are used in accordance with current Cookie
                  Law in the United States, the United Kingdom, and the European
                  Union.
                </ol>
                <ol>
                  Before the Site places Cookies on your computer, you will see
                  a message bar requesting your permission to do so. By
                  consenting to the placement of Cookies, you enable Nisa London
                  to provide you with a better experience and service. You may
                  refuse consent to the placement of Cookies if you so desire;
                  however, certain features of the Site may not function fully
                  or as intended.
                </ol>
              </ul>
              <p className="paratitle">
                Cookies can be enabled or disabled in your internet browser.
                Most internet browsers accept Cookies by default, but this can
                be changed. Please see your internet browser’s help menu for
                more information.
              </p>
              <p className="paratitle">
                You can delete Cookies at any time; however, you will lose any
                information that allows you to access the Site more quickly and
                efficiently, including but not limited to personalization
                settings.
              </p>
              <p className="paratitle">
                If you are unsure about adjusting your privacy settings, it is
                recommended that you update your internet browser and consult
                the help and guidance provided by the developer of your internet
                browser.
              </p>
              <p className="paratitle">
                Please visit
                <Link className="mx-2" style={{ color: "#ffe48e" }}>
                  https://www.aboutcookies.org
                </Link>
                for more information on cookies in general, including how to
                disable them. You’ll also learn how to delete cookies from your
                computer.
              </p>
              <p className="paratitle bold" style={{ marginBottom: "20px" }}>
                Contact Us
              </p>
              <p className="paratitle">
                If these Terms do not address your questions, you can contact us
                via email at info@nisacosmeticslondon.com or inquiry form.
              </p>
              {/* <p className="paratitle">
                please visit :
                <Link className="mx-2" style={{ color: "#ffe48e" }}>
                  http://www.aboutcookies.org
                </Link>
              </p> */}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default PrivacyPolicy;
