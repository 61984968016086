import React, { useEffect, useState } from "react";
import Sidebar from "../Component/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { faPencil, faReply, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { baseUrl } from "../../assets/Utils/Ip";
import Swal from "sweetalert2";

function ManageShipping() {
  const [shippingMethods, setShippingMethods] = useState([]);
  const [deletedId, setDeletedID] = useState(0);

  const token = localStorage.getItem("root");

  const navigate = useNavigate();

  const getShipping = async () => {
    try {
      const res = await axios.get(`${baseUrl}/get-all-shipping`);

      if (res.status === 200) {
        setShippingMethods(res.data);
      }
    } catch (err) {}
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(`${baseUrl}/delete-shipping/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        Swal.fire({
          icon: "success", // Use "success" for the icon
          title: "Shipping method deleted successfully",
          text: "",
          customClass: {
            // Assign your custom class to the popup container
            container: "my-custom-popup",
          },
        });

        getShipping();
      }
    } catch (err) {
      Swal.fire({
        icon: "error", // Use "success" for the icon
        title: "Error deleting shipping method",
        text: "",
        customClass: {
          // Assign your custom class to the popup container
          container: "my-custom-popup",
        },
      });
    }
  };

  const handleEdit = (id) => {
    navigate("/EditShipping", {
      state: id,
    });
  };

  useEffect(() => {
    getShipping();
  }, []);

  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Manage Shipping</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/ManageReviews"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
              <div className="d-flex justify-content-end mt-5">
                <Link
                  className="add-categorie-btn text-dark rounded-2 px-5 py-2 font-bebas"
                  to="/AddShipping"
                >
                  Add SHIPPING METHOD
                </Link>
              </div>
            </div>
            <div className="main-fi-order-box-master">
            <table className="main-fi-order-box">
              <thead>
                <tr className="grid-manage-parent-shipping">
                  <th className="grid-manage-inner border-custom justify-content-start pd-left-mdl-ttl">
                    Shipping Method Name
                  </th>
                  <th className="grid-manage-inner border-custom">
                    Shipping Charges
                  </th>
                  <th className="grid-manage-inner border-custom">Action</th>
                </tr>
              </thead>
              <tbody>
                {shippingMethods &&
                  shippingMethods.map((item, index) => (
                    <tr
                      key={index}
                      className="grid-manage-parent-shipping grid-manage-parent-rev"
                    >
                      <td className="grid-manage-inner border-custom justify-content-start pd-left-mdl-ttl">
                        {item.shipping_name}
                      </td>
                      <td className="grid-manage-inner border-custom">
                        £{item.shipping_charges}
                      </td>
                      <td className="grid-manage-inner border-custom">
                        {/* Button trigger modal */}
                        <button
                          type="button"
                          onClick={() => setDeletedID(item.shipping_id)}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          className="btn bg-yellow me-2"
                        >
                          <FontAwesomeIcon color="red" icon={faTrash} />
                        </button>
                        <button
                          type="button"
                          onClick={() => handleEdit(item.shipping_id)}
                          className="btn bg-yellow me-2"
                        >
                          <FontAwesomeIcon color="black" icon={faPencil} />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            </div>
            {/* <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex align-items-end">
                <div className="Pagination mt-4 ">
                  <div className="nmbering d-flex">
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                    <span>4</span>
                    <span>5</span>
                  </div>
                  <div className="linepag">
                    <span></span>
                  </div>
                </div>
                <div className="left-right-arrow-pag">
                  <span>
                    <i className="fa-solid fa-angle-left"></i>
                  </span>
                  <span>
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </div>
              </div>
              <div>
                <p style={{ color: "white" }}>
                  Showing 1 to 10 of 90 (9 Pages)
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        size="lg"
      >
        <div
          className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100 w-100"
          size="lg"
        >
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light my-3">
                You are about to delete this Shipping Method
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => handleDelete(deletedId)}
              >
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ManageShipping;
