import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import PlusCirc from "../../assets/img/admin/plusicon.png";
import "./imageUploader.css";
import { baseUrl } from "../../assets/Utils/Ip";
import Swal from "sweetalert2";

export default function ImageUploader({
  title,
  onImagesChange,
  getGalleryImages,
  onRemoveImage,
}) {
  const location = useLocation();
  const path = location.pathname;
  // const { id } = useParams();
  const fileInputRef = useRef();

  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    if (getGalleryImages?.length && typeof getGalleryImages === "string") {
      const imgs = getGalleryImages
        .split(",")
        .filter((elem) => elem.length > 4);
      setSelectedImages(imgs);
    }
  }, [getGalleryImages]);

  // const handleImageChange = (e) => {
  //   const files = Array.from(e.target.files);

  //   for (const file of files) {
  //     if (file.name.includes(",")) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Invalid Filename",
  //         text: "Image filenames cannot contain commas (,). Please rename the image file and try again.",
  //       });
  //       return;
  //     }
  //   }

  //   const updatedImages = [...selectedImages, ...files];
  //   setSelectedImages(updatedImages);
  //   onImagesChange(updatedImages);

  //   // Reset the file input value
  //   e.target.value = "";
  // };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
  
    for (const file of files) {
      // Check for commas in the filename
      if (file.name.includes(",")) {
        Swal.fire({
          icon: "error",
          title: "Invalid Filename",
          text: "Image filenames cannot contain commas (,). Please rename the image file and try again.",
        });
        return;
      }
  
      // Check for file size greater than 5 MB
      if (file.size > 5 * 1024 * 1024) { // 5 MB in bytes
        Swal.fire({
          icon: "error",
          title: "File Too Large",
          text: "Each image file must be less than 5 MB. Please choose a smaller file.",
        });
        return;
      }
  
      // Check for file type other than PNG
      if (file.type !== "image/png") {
        Swal.fire({
          icon: "error",
          title: "Invalid File Type",
          text: "Only PNG images are allowed. Please choose a PNG file.",
        });
        return;
      }
    }
  
    const updatedImages = [...selectedImages, ...files];
    setSelectedImages(updatedImages);
    onImagesChange(updatedImages);
  
    // Reset the file input value
    e.target.value = "";
  };
  
  const handleRemoveImage = (index) => {
    const imgs = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(imgs);

    if (onRemoveImage) {
      onRemoveImage(imgs);
    }
  };

  return (
    <div className="right-parent">
      <div className="image-box-parent">
        <div className="image-box-heading"></div>
        <div
          className="image-box"
          onClick={() => fileInputRef.current.click()}
        >
          <input
            ref={fileInputRef}
            type="file"
            className="image-input"
            id="fileInput"
            accept="image/*"
            multiple
            onChange={handleImageChange}
          />
          <p className="text-center">
            <span>
              <img className="img-plus" src={PlusCirc} alt="" />
            </span>
            {title}
          </p>
        </div>
        {selectedImages.length > 0 ? (
          <div className="uploaded-images-new-class-add-main">
            <div className="justify-content-around mt-3 uploaded-images uploaded-images-new-class-add">
              {selectedImages.map((image, index) => (
                <div
                  key={index}
                  className="position-relative product-upload-data align-items-center bg-white border d-flex mx-2 my-3 px-2 rounded-3 image-uploader-width"
                >
                  <img
                    src={
                      typeof image === "string"
                        ? `${baseUrl}/${image}`
                        : URL.createObjectURL(image)
                    }
                    alt={`Selected ${index}`}
                    className="w-100"
                  />
                  <span
                    onClick={() => handleRemoveImage(index)}
                    className="bg-white border position-absolute rounded-5 shadow text-danger cross-btn"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="alert alert-warning mt-2">
            Currently No images uploaded
          </div>
        )}
      </div>
    </div>
  );
}
