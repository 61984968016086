import React from "react";
import ComingSoon from "../assets/img/comingSoonBanner.png";

const ComingSoonBanner = () => {
  return (
    <div>
      <section>
        <img width="100%" src={ComingSoon} alt="" />
      </section>
    </div>
  );
};

export default ComingSoonBanner;
