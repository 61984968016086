import React, { useEffect, useState } from "react";
import Sidebar from "../Component/Sidebar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListUl,
  faPencil,
  faPlus,
  faTrash,
  faReply,
} from "@fortawesome/free-solid-svg-icons";
import { baseUrl } from "../../assets/Utils/Ip";
import axios from "axios";
import Swal from "sweetalert2";

export default function Categories() {
  const [category, setCategory] = useState([]);
  const [deletedId, setDeletedId] = useState();
  const [selectedCatagory, setSelectedCatagory] = useState([]); // Step 1
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const token = localStorage.getItem("root");

  const getAllCategory = async () => {
    try {
      const res = await axios.get(`${baseUrl}/get-all-category`);
      setCategory(res.data);
    } catch (error) {}
  };

  function handleDeleteCategory(categoryId) {
    // Delete button click event handler
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${baseUrl}/category-delete/${categoryId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCategory((prevProd) =>
          prevProd.filter((category) => category.category_id !== categoryId)
        );
        Swal.fire({
          icon: "success", // Use "success" for the icon
          title: "Selected Category Deleted",
          text: "Deleted Successfully",
          customClass: {
            // Assign your custom class to the popup container
            container: "my-custom-popup",
          },
        });
        // Handle success, for example, update the UI or show a success message
      })
      .catch((error) => {
        // Handle error, show an error message or take appropriate action
      });
  }

  const handleSelectAll = () => {
    setSelectedCatagory(
      category?.map((category) => category?.category_id)
    ); // Step 2
  };

  const handleDeselectAll = () => {
    setSelectedCatagory([]); // Step 3
  };

  const handleBrandSelect = (catagoryId) => {
    // Step 4
    if (selectedCatagory.includes(catagoryId)) {
      setSelectedCatagory(selectedCatagory.filter((id) => id !== catagoryId));
    } else {
      setSelectedCatagory([...selectedCatagory, catagoryId]);
    }
  };

  const deleteAll = () => {
    const categoryIdsToDelete = selectedCatagory; // Replace with the category IDs you want to delete

    if (categoryIdsToDelete.length === 0) {
      Swal.fire({
        icon: "error", // Use "success" for the icon
        title: "Kindly select an item before deletion",
        text: "",
        customClass: {
          // Assign your custom class to the popup container
          container: "my-custom-popup",
        },
      });

      return;
    }

    axios
      .delete(`${baseUrl}/delete-all-category`, {
        data: { categoryIds: categoryIdsToDelete },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Swal.fire({
          icon: "success", // Use "success" for the icon
          title: "All Categories",
          text: "Deleted Successfully",
          customClass: {
            // Assign your custom class to the popup container
            container: "my-custom-popup",
          },
        });

        setCategory((prevProd) =>
          prevProd.filter(
            (category) => !categoryIdsToDelete.includes(category.category_id)
          )
        );
      })
      .catch((error) => {
        // Handle errors or display an error message.
      });
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  // pagination start

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastCategory = currentPage * itemsPerPage;
  const indexOfFirstCategory = indexOfLastCategory - itemsPerPage;
  const currentCategories = category.slice(
    indexOfFirstCategory,
    indexOfLastCategory
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // pagination end

  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex align-items-center justify-content-between">
                <h1>CATEGORIES</h1>
                <div className="boxs-flex boxs-flex-categories-resp">
                  <Link
                    className="p-3 justify-content-center bg-yellow w-25 h-25 d-flex align-items-center text-dark rounded-2 boxs-flex-categories-resp-big"
                    to="/AddCategories"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Link>
                  <span className="p-3 justify-content-center bg-yellow w-25 h-25 d-flex align-items-center text-dark rounded-2 boxs-flex-categories-resp-big">
                    <FontAwesomeIcon
                      color="red"
                      icon={faTrash}
                      className="cursor-pointer"
                      onClick={deleteAll}
                    />
                  </span>
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2 boxs-flex-categories-resp-big"
                    to="/DashboardAdmin"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>

            <div className="d-flex justify-content-end mt-4">
              <Link
                className="add-categorie-btn text-dark rounded-2 px-5 py-2 font-bebas"
                to="/AddCategories"
              >
                Add CATEGORY
              </Link>
              <Link
                className="add-categorie-btn text-dark rounded-2  px-5 py-2 font-bebas"
                to="/DeletedCategories"
                style={{ marginLeft: "10px" }}
              >
                Deleted CATEGORY
              </Link>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <div className="add-categorie-list">
                <FontAwesomeIcon color="#fff" icon={faListUl} />
                <span className="mx-2">Category List</span>
              </div>
              <div className="pagin">
                <select
                  aria-label="Default select example"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(e.target.value)}
                >
                  <option value={5} selected>
                    5
                  </option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
              </div>
            </div>
            <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                <div className="categories-parent-table-box">
                  <div className="brand-categorie-table-box w-100 align-items-center rounded-3 my-3">
                    <div className="categori-name d-flex align-items-center p-3">
                      <input
                        type="checkbox"
                        onChange={() =>
                          selectedCatagory?.length < category?.length
                            ? handleSelectAll()
                            : handleDeselectAll()
                        }
                        checked={selectedCatagory.length === category?.length}
                      />
                      <p className="mx-3">Category</p>
                    </div>

                    <div className="categori-name border-end p-3 text-end">
                      <p>Number of Products</p>
                    </div>
                    <div className="categori-name border-end-0 p-3 text-end">
                      <p>Action</p>
                    </div>
                  </div>

                  {currentCategories.map((category) => (
                    <div
                      key={category.category_id}
                      className="brand-categorie-table-box-sec text-light w-100 align-items-center rounded-3 my-2"
                    >
                      <div className="categori-name d-flex align-items-center p-3">
                        <input
                          type="checkbox"
                          onChange={() =>
                            handleBrandSelect(category.category_id)
                          }
                          checked={selectedCatagory.includes(
                            category.category_id
                          )}
                        />
                        <p className="mx-3">{category.category_name}</p>
                      </div>

                      <div className="border-end text-end p-3">
                        <p> {category.product_count} </p>
                      </div>
                      <div className="p-2 px-3 text-end">
                        {/* Button trigger modal */}
                        <button
                          type="button"
                          className="btn bg-yellow me-2"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setDeletedId(category.category_id)}
                        >
                          <FontAwesomeIcon color="red" icon={faTrash} />
                        </button>
                        <Link
                          to={`/CategoriesForm/${category.category_id}`}
                          className="btn bg-yellow"
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Pagination start */}
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex align-items-end">
                <div className="Pagination mt-4 ">
                  <div className="nmbering d-flex">
                    {Array.from({
                      length: Math.ceil(category.length / itemsPerPage),
                    }).map((_, index) => (
                      <span
                        key={index}
                        className={
                          currentPage === index + 1 ? "active" : "pginte-curser"
                        }
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </span>
                    ))}
                  </div>
                  <div className="linepag">
                    <span></span>
                  </div>
                </div>
                <div className="left-right-arrow-pag">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => currentPage > 1 && paginate(currentPage - 1)}
                  >
                    <i
                      className="fa-solid fa-angle-left"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                  <span
                    onClick={() =>
                      currentPage < Math.ceil(category.length / itemsPerPage) &&
                      paginate(currentPage + 1)
                    }
                  >
                    <i
                      className="fa-solid fa-angle-right"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
            {/* Pagination end */}
            {/* pagi */}
            {/* Pagination end */}
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        size="lg"
      >
        <div
          className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100 w-100"
          size="lg"
        >
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light my-3">
                You are about to delete this Category
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => handleDeleteCategory(deletedId)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
