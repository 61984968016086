import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { baseUrl } from "../assets/Utils/Ip";
import axios from "axios";
import GetStars from "./GetStars";

function AboutPageTestimonial() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    slidesToScroll: 1,
    arrows: true,
    autoplay: false, // Enable auto sliding
    autoplaySpeed: 2000, // Set the time interval in milliseconds (e.g., 2000ms = 2 seconds)
  };

  const { id } = useParams();
  const [reviewGet, setBestReviewGet] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-all-front-review`);

        console.log(response.data);
        setBestReviewGet(response?.data);
      } catch (error) {}
    };

    fetchData();
  }, [id]);

  const [bestproducts, setBestproducts] = useState();

  return (
    <div>
      <section className="about-testimonial font-bebas">
        <div className="heading-bg text-center py-md-5 py-3 mb-md-5 mb-4">
          <h1 className="fs-70 theme-text-color m-0 line-height">
            HEAR FROM OUR
          </h1>
          <h2 className="m-0 theme-text-yellow-light fs-50">
            GORGEOUS CLIENTS
          </h2>
        </div>
        <div className="container-1640 z-10">
          <div className="row">
            <div className="col-lg-12">
              {reviewGet?.length > 2 ? (
                <Slider {...settings} className="set-slide">
                  {reviewGet?.map((reviewGet) => (
                    <div
                      key={reviewGet.product_id}
                      className="bg-theme-box p-lg-3 p-1 w-85 mx-auto d-block h-475 h-475-resp"
                    >
                      <div className="border-golden d-flex justify-content-between align-items-center p-lg-4 p-1">
                        <div className="offer-content-on text-start w-100">
                          <div className="d-flex justify-content-center my-4">
                            <GetStars
                              color={"orange"}
                              value={reviewGet.review_rating}
                              className="fs-4 mx-2"
                            />
                          </div>
                          <p className="font-poppins theme-text-yellow-light para-resp-about">
                            {reviewGet.review_message}
                          </p>
                          <h1 className="m-0">Customer</h1>
                          <h2>{reviewGet.name}</h2>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="d-flex justify-content-center">
                  {reviewGet?.map((reviewGet) => (
                    <div
                      key={reviewGet.product_id}
                      className="bg-theme-box p-3 w-85 mx-auto d-block h-475 h-475-resp"
                    >
                      <div className="border-golden d-flex justify-content-between align-items-center p-4">
                        <div className="offer-content-on text-start w-100">
                          <div className="d-flex justify-content-center my-4">
                            <GetStars
                              color={"orange"}
                              value={reviewGet.review_rating}
                              className="fs-4 mx-2"
                            />
                          </div>
                          <p className="font-poppins theme-text-yellow-light para-resp-about">
                            {reviewGet.review_message}
                          </p>
                          <h1 className="m-0">Customer</h1>
                          <h2>{reviewGet.name}</h2>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* {bestproducts?.length > 4 ? (
              <Slider {...settings}>
                {bestproducts?.map((product) => (
                  <div key={product.id} className="prnt-prdt-box">
                    <div className="prnt-prdct-txt pt-5">
                      <h1 className="font-bebas">{product.product_name}</h1>
                      <div className="star-prnt">
                        <GetStars value={product.avg_rating} />
                      </div>
                    </div>
                    <div className="prdct-price">
                      <h3 className="font-bebas">£{product.sale_price}</h3>
                      <Link to={`/Buynow/${product.product_id}`}>
                        <FontAwesomeIcon icon={faCartShopping} />
                      </Link>
                    </div>
                    <div className="prdct-img">
                      <img src={`${baseUrl}/${product.main_image}`} alt="" />
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="d-flex justify-content-evenly">
                {bestproducts?.map((product) => (
                  <div key={product.id} className="prnt-prdt-box">
                    <div className="prnt-prdct-txt pt-5">
                      <h1 className="font-bebas">{product.product_name}</h1>
                      <div className="star-prnt">
                        <GetStars value={product.avg_rating} />
                      </div>
                    </div>
                    <div className="prdct-price">
                      <h3 className="font-bebas">
                        £
                        {product.sale_price !== "" && product.sale_price !== "0"
                          ? product.sale_price
                          : product.regular_price}
                      </h3>
                      <Link to={`/Buynow/${product.product_id}`}>
                        <FontAwesomeIcon icon={faCartShopping} />
                      </Link>
                    </div>
                    <div className="prdct-img">
                      <img src={`${baseUrl}/${product.main_image}`} alt="" />
                    </div>
                  </div>
                ))}
              </div>
            )} */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutPageTestimonial;
