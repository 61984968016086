import React, { useEffect } from "react";
import Sidebar from "../Component/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faReply, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../assets/Utils/Ip";
import Swal from "sweetalert2";

function PromoCode() {
  const [promos, setPromos] = useState([]);
  const [selectedPromo, setSelectedPromo] = useState([]);
  const [id, setID] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const token = localStorage.getItem("root");

  const navigate = useNavigate();

  const fetchPromos = async () => {
    try {
      const res = await axios.get(`${baseUrl}/get-all-promo`);

      if (res.status === 200) {
        setPromos(res.data);
      }
    } catch (err) {}
  };

  const handleNavigate = async (id) => {
    navigate("/EditPromoCode", {
      state: id,
    });
  };

  const handleSelectAll = () => {
    setSelectedPromo(currentpromos?.map((promo) => promo?.promo_id)); // Step 2
  };

  const handleDeselectAll = () => {
    setSelectedPromo([]);
  };

  const handleBrandSelect = (promo_id) => {
    if (selectedPromo.includes(promo_id)) {
      setSelectedPromo(selectedPromo.filter((id) => id !== promo_id));
    } else {
      setSelectedPromo([...selectedPromo, promo_id]);
    }
  };

  const deleteAll = () => {
    if (selectedPromo.length === 0) {
      Swal.fire({
        icon: "error", // Use "success" for the icon
        title: "Kindly select an item before deletion",
        text: "",
        customClass: {
          // Assign your custom class to the popup container
          container: "my-custom-popup",
        },
      });

      return;
    }

    axios
      .delete(`${baseUrl}/delete-all-promo`, {
        data: { promoIds: selectedPromo },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Promo Code Successfully Deleted",
          text: "",
        });
        fetchPromos();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error deleting products",
          text: "",
        });
      });
  };

  const deleteSingle = async () => {
    try {
      const res = await axios.delete(`${baseUrl}/delete-promo/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Promo Code Successfully Deleted",
          text: "",
        });

        fetchPromos();
        return;
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error deleting this promo code",
        text: "",
      });
    }
  };

  useEffect(() => {
    fetchPromos();
  }, []);

  // Pagination start
  const [currentPage, setCurrentPage] = useState(1);

  // Check if promos is defined
  const promoExist = promos && promos.length > 0;

  // If promo is not defined, initialize it as an empty array
  const promosArray = promoExist ? promos : [];

  const indexOfLastpromo = currentPage * itemsPerPage;
  const indexOfFirstpromo = indexOfLastpromo - itemsPerPage;
  const currentpromos = promosArray.slice(indexOfFirstpromo, indexOfLastpromo);

  const paginate = (pageNumber) => {
    // console.log('Setting current page to:', pageNumber);
    setCurrentPage(pageNumber);
  };
  // Pagination end

  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Promo code</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/ManageShipping"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                  <button
                    type="button"
                    className="btn bg-yellow me-2"
                    onClick={() => deleteAll()}
                  >
                    <FontAwesomeIcon color="red" icon={faTrash} />
                  </button>
                </div>
              </div>
              <hr className="text-light opacity-100" />
              <div className="d-flex justify-content-end mt-5">
                <Link
                  className="add-categorie-btn text-dark rounded-2 px-5 py-2 font-bebas"
                  to="/AddPromoCode"
                >
                  Add Promo code
                </Link>
              </div>
              <div className="pagin d-flex justify-content-end mt-3">
                <select
                  aria-label="Default select example"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(e.target.value)}
                >
                  <option value={5} selected>
                    5
                  </option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
              </div>
            </div>
            <div className="main-fi-order-box-master">
            <table className="main-fi-order-box">
              <thead>
                <tr className="grid-manage-parent-Promo">
                  <th className="categori-name py-3 text-center">
                    <input
                      type="checkbox"
                      onChange={() =>
                        selectedPromo?.length < promos?.length
                          ? handleSelectAll()
                          : handleDeselectAll()
                      }
                      checked={selectedPromo.length === promos?.length}
                    />
                  </th>
                  <th className="grid-manage-inner border-custom justify-content-start pd-left-mdl-ttl">
                    Promo Code Name
                  </th>
                  <th className="grid-manage-inner border-custom justify-content-center px-1">
                    Discount Percentage
                  </th>
                  <th className="grid-manage-inner border-custom justify-content-start ps-4">
                    Discount Amount
                  </th>
                  <th className="grid-manage-inner border-custom justify-content-start ps-3">
                    No. of times used
                  </th>
                  <th className="grid-manage-inner border-custom">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentpromos &&
                  currentpromos.map((item) => (
                    <tr
                      key={item.promo_id}
                      className="grid-manage-parent-Promo grid-manage-parent-rev"
                    >
                      <td className="categori-name py-3 text-center">
                        <input
                          type="checkbox"
                          onChange={() => handleBrandSelect(item.promo_id)}
                          checked={selectedPromo.includes(item.promo_id)}
                        />
                      </td>
                      <td className="grid-manage-inner border-custom justify-content-start pd-left-mdl-ttl">
                        {item.promo_name}
                      </td>
                      <td className="grid-manage-inner border-custom">
                        {item.status === "percentage"
                          ? item.discount_unit + "%"
                          : "-"}
                      </td>
                      <td className="grid-manage-inner border-custom">
                        {item.status === "price"
                          ? "£" + item.discount_unit
                          : "-"}
                      </td>
                      <td className="grid-manage-inner border-custom">
                        {item.quantity}
                      </td>
                      <td className="grid-manage-inner border-custom">
                        {/* Button trigger modal */}
                        <button
                          type="button"
                          className="btn bg-yellow me-2"
                          onClick={() => setID(item.promo_id)}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <FontAwesomeIcon color="red" icon={faTrash} />
                        </button>
                        <span
                          onClick={() => handleNavigate(item.promo_id)}
                          className="btn bg-yellow"
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            </div>

            {/* Pagination start */}
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex align-items-end">
                <div className="Pagination mt-4 ">
                  <div className="nmbering d-flex">
                    {Array.from({
                      length: Math.ceil(promos.length / itemsPerPage),
                    }).map((_, index) => (
                      <span
                        key={index}
                        className={
                          currentPage === index + 1 ? "active" : "pginte-curser"
                        }
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </span>
                    ))}
                  </div>
                  <div className="linepag">
                    <span></span>
                  </div>
                </div>
                <div className="left-right-arrow-pag">
                  <span
                    onClick={() => currentPage > 1 && paginate(currentPage - 1)}
                  >
                    <i
                      className="fa-solid fa-angle-left"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                  <span
                    onClick={() =>
                      currentPage < Math.ceil(promos.length / itemsPerPage) &&
                      paginate(currentPage + 1)
                    }
                  >
                    <i
                      className="fa-solid fa-angle-right"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
            {/* Pagination end */}
            {/* Pagination end */}
            {/* <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex align-items-end">
                <div className="Pagination mt-4 ">
                  <div className="nmbering d-flex">
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                    <span>4</span>
                    <span>5</span>
                  </div>
                  <div className="linepag">
                    <span></span>
                  </div>
                </div>
                <div className="left-right-arrow-pag">
                  <span>
                    <i className="fa-solid fa-angle-left"></i>
                  </span>
                  <span>
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </div>
              </div>
              <div>
                <p style={{ color: "white" }}>
                  Showing 1 to 10 of 90 (9 Pages)
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        size="lg"
      >
        <div
          className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100 w-100"
          size="lg"
        >
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light my-3">
                You are about to delete this Promo Code
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={deleteSingle}
              >
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PromoCode;
