import React from "react";
import { InstagramEmbed } from "react-social-media-embed";
const InstagramFeeds = () => {
  <script src="https://snapwidget.com/js/snapwidget.js"></script>;

  return (
    <section
      className="position-relative instafeed-res bg-black"
      style={{ padding: "50px 0 100px" }}
    >
      <div className="container-1640 z-10">
        <div className="prnt-txt-insta text-center">
          <h1 className="font-bebas">Instagram Highlights</h1>
        </div>

        {/* USING SNAPWIDGET */}

        {/* <iframe
          title="insta-feeds"
          src="https://snapwidget.com/embed/1052236"
          className="snapwidget-widget insta-feeds"
          allowTransparency="true"
          // frameBorder="0"
          // scrolling="no"

          style={{
            border: "none",
            overflow: "hidden",
            width: "100%",
            height: "1200px",
          }}
        ></iframe> */}
        {/* <iframe
          title="insta-feeds"
          src="https://snapwidget.com/embed/1052236"
          className="snapwidget-widget"
          allowTransparency="true"
          // frameBorder="0"
          // scrolling="no"
          style={{
            border: "none",
            overflow: "hidden",
            width: "100%",
            height: "100vh",
          }}
        ></iframe> */}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "12px",
            padding: "0 0 1rem",
          }}
          className="insta-feeds"
        >
          <InstagramEmbed
            url="https://www.instagram.com/p/C0JkrY0If9b/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA"
            width={500}
            height={734}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/C0Ffl2BhJJx/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            width={500}
            height={734}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/C0B0-9HIX4j/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            width={500}
            height={734}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center", gap: "12px" }} className="insta-feeds">
          <InstagramEmbed
            url="https://www.instagram.com/p/Cz3oWupPnW9/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            width={500}
            height={734}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/CzvowNiufD-/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            width={500}
            height={734}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/CzqpTCTNRBS/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            width={500}
            height={734}
          />
        </div>
      </div>
    </section>
  );
};

export default InstagramFeeds;
