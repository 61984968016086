import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../assets/img/admin/AboutLogo.png";
import ProfilePic from "../../assets/img/admin/ProfilePic.png";
import dashboardIcon from "../../assets/img/admin/dashboardIcon.png";
import CategoriesIcon from "../../assets/img/admin/CategoriesIcon.png";
import BoxIcon from "../../assets/img/admin/BoxIcon.png";
import ReportsIcon from "../../assets/img/admin/ReportsIcon.png";
import SaleIcon from "../../assets/img/admin/SaleIcon.png";
import CustomerIcon from "../../assets/img/admin/CustomerIcon.png";
import ShutdownIcon from "../../assets/img/admin/ShutdownIcon.png";
import { useLocation } from "react-router-dom";
import { handleLogout } from "../../Components/handleLogout";
import ManageIcon from "../../assets/img/admin/ManageIcon.png";
import PromoIcon from "../../assets/img/admin/PromoIcon.png";
import NewsletterIcon from "../../assets/img/admin/NewsletterIcon.png";

export default function Sidebar() {
  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  const logout = () => {
    handleLogout();
    navigate("/login");
  };

  const [isActive, setIsActive] = useState(false);

  const handleHamburgerClick = () => {
    // Toggle the 'active' state
    setIsActive(!isActive);
  };

  return (
    <div className="first-side-bar font-poppins">
      <div className={`side-bar-main ${isActive ? "active" : ""}`}>
        <div className="hamburger-admin" onClick={handleHamburgerClick}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="side-bar-parent">
          <div className="d-flex justify-content-center mt-2">
            <Link to="/" className="text-center">
              <img
                className="w-50 custom-resp-logo-sidebar"
                src={logo}
                alt=""
              />
            </Link>
          </div>
          <div className="user-img">
            <img src={ProfilePic} alt="" />
            <h5 className="text-theme m-0 mt-2">Admin</h5>
          </div>
          <div className="main-sidebar-list">
            <ul className="side-list-flex m-0 ms-lg-5 ms-3 p-0 w-100">
              <li className="flex-list-li mt-1" aria-current="page">
                <Link
                  className={
                    "" + (url === "/DashboardAdmin" ? "sidebar-active" : "")
                  }
                  to="/DashboardAdmin"
                >
                  <img className="px-2" src={dashboardIcon} alt="" />
                  Dashboard
                </Link>
              </li>
              <li className="flex-list-li  my-3" aria-current="page">
                <Link
                  className={
                    "" + (url === "/Categories" ? "sidebar-active" : "")
                  }
                  to="/Categories"
                >
                  <img className="px-2" src={CategoriesIcon} alt="" />
                  Categories
                </Link>
              </li>
              <li className="flex-list-li  my-3" aria-current="page">
                <Link
                  className={
                    "" + (url === "/Variation" ? "sidebar-active" : "")
                  }
                  to="/Variation"
                >
                  <img className="px-2" src={BoxIcon} alt="" />
                  Variation
                </Link>
              </li>

              <li className="flex-list-li  my-3" aria-current="page">
                <Link
                  className={
                    "" + (url === "/AdminProduct" ? "sidebar-active" : "")
                  }
                  to="/AdminProduct"
                >
                  <img className="px-2" src={ReportsIcon} alt="" />
                  Product
                </Link>
              </li>
              <li className="flex-list-li  my-3" aria-current="page">
                <Link
                  className={"" + (url === "/Sale" ? "sidebar-active" : "")}
                  to="/Sale"
                >
                  <img className="px-2" src={SaleIcon} alt="" />
                  Sales
                </Link>
              </li>
              <li className="flex-list-li  my-3" aria-current="page">
                <Link
                  className={"" + (url === "/Report" ? "sidebar-active" : "")}
                  to="/Report"
                >
                  <img className="px-2" src={CustomerIcon} alt="" />
                  Report
                </Link>
              </li>
              <li className="flex-list-li  my-3" aria-current="page">
                <Link
                  className={
                    "" + (url === "/ManageReviews" ? "sidebar-active" : "")
                  }
                  to="/ManageReviews"
                >
                  <img className="px-2" src={ReportsIcon} alt="" />
                  Manage Reviews
                </Link>
              </li>
              <li className="flex-list-li  my-3" aria-current="page">
                <Link
                  className={
                    "" + (url === "/ManageShipping" ? "sidebar-active" : "")
                  }
                  to="/ManageShipping"
                >
                  <img className="px-2" src={ManageIcon} alt="" />
                  Manage Shipping
                </Link>
              </li>
              <li className="flex-list-li  my-3" aria-current="page">
                <Link
                  className={
                    "" + (url === "/PromoCode" ? "sidebar-active" : "")
                  }
                  to="/PromoCode"
                >
                  <img className="px-2" src={PromoIcon} alt="" />
                  Promo Code
                </Link>
              </li>
              <li className="flex-list-li  my-3" aria-current="page">
                <Link
                  className={
                    "" + (url === "/NewsLetter" ? "sidebar-active" : "")
                  }
                  to="/NewsLetter"
                >
                  <img className="px-2" src={NewsletterIcon} alt="" />
                  Newsletter
                </Link>
              </li>
            </ul>
          </div>
          <button
            className="logout-btn start-50 position-absolute"
            onClick={logout}
          >
            <img src={ShutdownIcon} alt="" />
            <span className="mx-1">Logout</span>
          </button>
        </div>
      </div>
    </div>
  );
}
