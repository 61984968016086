import React, { useEffect, useState } from "react";
import Sidebar from "../Component/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListUl,
  faPlus,
  faTrash,
  faReply,
} from "@fortawesome/free-solid-svg-icons";
import { baseUrl } from "../../assets/Utils/Ip";
import axios from "axios";
import Swal from "sweetalert2";

export default function DeletedCategories() {
  const navigate = useNavigate();
  const [visibleProducts, setVisibleProducts] = useState(10);
  const [selectedCatagory, setSelectedCatagory] = useState([]);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${baseUrl}/get-all-deleted-category`,
        };

        const response = await axios.request(config);

        setCategory(response?.data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const handleRestore = (categoryId) => {
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${baseUrl}/restore-category/${categoryId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Category Restored",
          text: "Your Category Restored successfully",
        });
        navigate("/Categories");
        setCategory((prevProd) =>
          prevProd.filter((category) => category.category_id !== categoryId)
        );
      })
      .catch((error) => {});
  };

  const displayedcategory = category?.slice(0, visibleProducts);
  const handleSelectAll = () => {
    setSelectedCatagory(category?.map((category) => category?.category_id)); // Step 2
  };

  const handleDeselectAll = () => {
    setSelectedCatagory([]); // Step 3
  };

  const handleBrandSelect = (categoryID) => {
    // Step 4
    if (selectedCatagory.includes(categoryID)) {
      setSelectedCatagory(selectedCatagory.filter((id) => id !== categoryID));
    } else {
      setSelectedCatagory([...selectedCatagory, categoryID]);
    }
  };

  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Deleted CATEGORIES</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 cate-icon-back justify-content-center bg-yellow w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/AddCategories"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Link>
                  <Link
                    className="p-3 cate-icon-back justify-content-center bg-yellow w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to=""
                  >
                    <FontAwesomeIcon color="red" icon={faTrash} />
                  </Link>
                  <Link
                    className="p-3 cate-icon-back justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/Categories"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>

            <div className="add-categorie-list">
              <FontAwesomeIcon color="#fff" icon={faListUl} />
              <span className="mx-2">Category List</span>
            </div>
            <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                <div className="categories-parent-table-box">
                  <div className="brand-categorie-table-box w-100 align-items-center rounded-3 my-3">
                    <div className="categori-name d-flex align-items-center p-3">
                      <input
                        type="checkbox"
                        onChange={() =>
                          selectedCatagory?.length < category?.length
                            ? handleSelectAll()
                            : handleDeselectAll()
                        }
                        checked={selectedCatagory.length === category?.length}
                      />
                      <p className="mx-3">Category</p>
                    </div>

                    <div className="categori-name border-end p-3 text-end">
                      <p>Number of Products</p>
                    </div>
                    <div className="categori-name border-end-0 p-3 text-end">
                      <p>Action</p>
                    </div>
                  </div>

                  {displayedcategory?.map((category) => (
                    <div
                      key={category.category_id}
                      className="brand-categorie-table-box-sec text-light w-100 align-items-center rounded-3 my-2"
                    >
                      <div className="categori-name d-flex align-items-center p-3">
                        <input
                          type="checkbox"
                          onChange={() =>
                            handleBrandSelect(category.category_id)
                          }
                          checked={
                            selectedCatagory.includes(category.category_id)
                              ? true
                              : false
                          }
                        />
                        <p className="mx-3">{category.category_name}</p>
                      </div>

                      <div className="border-end text-end p-3">
                        <p> {category.product_count} </p>
                      </div>
                      <div className="p-2 px-3 text-end">
                        <Link
                          className="  add-categorie-btn text-dark rounded-2 px-5 py-2 font-bebas"
                          onClick={() => handleRestore(category.category_id)}
                        >
                          Restore
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        size="lg"
      >
        <div
          className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100 w-100"
          size="lg"
        >
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light my-3">
                You are about to delete this Category
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
