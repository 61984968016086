import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListUl,
  faReply,
  faEye,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";
import Sidebar from "../Component/Sidebar";
import { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../assets/Utils/Ip";
import { useEffect } from "react";

// assign page set
export default function ManageReviews() {
  const [reviews, setReviews] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const navigate = useNavigate();

  const fetchReviews = async () => {
    try {
      const res = await axios.get(`${baseUrl}/get-all-review`);

      if (res.status === 200) {
        setReviews(res.data);
      }
    } catch (err) {}
  };

  const handleNavigate = (item) => {
    navigate("/EditReview", {
      state: item,
    });
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  // Pagination start
  const [currentPage, setCurrentPage] = useState(1);

  // Check if variation is defined
  const reviewExist = reviews && reviews.length > 0;

  // If review is not defined, initialize it as an empty array
  const reviewsArray = reviewExist ? reviews : [];

  const indexOfLastreview = currentPage * itemsPerPage;
  const indexOfFirstreview = indexOfLastreview - itemsPerPage;
  const currentreviews = reviewsArray.slice(
    indexOfFirstreview,
    indexOfLastreview
  );

  const paginate = (pageNumber) => {
    // console.log('Setting current page to:', pageNumber);
    setCurrentPage(pageNumber);
  };
  // Pagination end
  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>MANAGE REVIEWS</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/Report"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
              <div className="d-flex justify-content-between align-items-center mt-2">
                <div className="add-categorie-list">
                  <FontAwesomeIcon color="#fff" icon={faListUl} />
                  <span className="mx-2">Reviews</span>
                </div>
                <div className="pagin">
                  <select
                    aria-label="Default select example"
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(e.target.value)}
                  >
                    <option value={5} selected>
                      5
                    </option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="main-fi-order-box-master">
            <table className="main-fi-order-box">
              <thead>
                <tr className="grid-manage-parent">
                  <th className="grid-manage-inner border-custom">
                    Customer Name
                  </th>
                  <th className="grid-manage-inner border-custom">
                    Product Name
                  </th>
                  <th className="grid-manage-inner border-custom">
                    Review Title
                  </th>
                  <th className="grid-manage-inner border-custom">Rating</th>
                  <th className="grid-manage-inner border-custom">Status</th>
                  <th className="grid-manage-inner border-custom">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentreviews &&
                  currentreviews.map((review, index) => (
                    <tr
                      key={index}
                      className="grid-manage-parent grid-manage-parent-rev"
                    >
                      <td className="grid-manage-inner border-custom">
                        {review.name}
                      </td>
                      <td className="grid-manage-inner border-custom">
                        {review.product_name}
                      </td>
                      <td className="grid-manage-inner border-custom">
                        {review.review_title}
                      </td>
                      <td className="grid-manage-inner border-custom">
                        <div className="mange-star-parent">
                          {[...Array(Math.min(review.review_rating, 5))].map(
                            (_, starIndex) => (
                              <FontAwesomeIcon key={starIndex} icon={faStar} />
                            )
                          )}
                          {[
                            ...Array(Math.max(5 - review.review_rating, 0)),
                          ].map((_, starIndex) => (
                            <i
                              key={starIndex}
                              className="fa-regular fa-star mange-star-parent"
                            ></i>
                          ))}
                        </div>
                      </td>
                      <td className="grid-manage-inner border-custom">
                        {review.review_status === "pending" ? (
                          <p>
                            <FontAwesomeIcon
                              color="yellow"
                              icon={faDotCircle}
                            />{" "}
                            Pending
                          </p>
                        ) : review.review_status === "approved" ? (
                          <p>
                            <FontAwesomeIcon color="green" icon={faDotCircle} />{" "}
                            Approved
                          </p>
                        ) : (
                          <p>
                            <FontAwesomeIcon color="red" icon={faDotCircle} />{" "}
                            Declined
                          </p>
                        )}
                      </td>
                      <td className="grid-manage-inner border-custom">
                        <span
                          className="cursor-pointer"
                          onClick={() => handleNavigate(review)}
                        >
                          <FontAwesomeIcon color="white" icon={faEye} />
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            </div>

            {/* Pagination start */}
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex align-items-end">
                <div className="Pagination mt-4 ">
                  <div className="nmbering d-flex">
                    {Array.from({
                      length: Math.ceil(reviews.length / itemsPerPage),
                    }).map((_, index) => (
                      <span
                        key={index}
                        className={
                          currentPage === index + 1 ? "active" : "pginte-curser"
                        }
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </span>
                    ))}
                  </div>
                  <div className="linepag">
                    <span></span>
                  </div>
                </div>
                <div className="left-right-arrow-pag">
                  <span
                    onClick={() => currentPage > 1 && paginate(currentPage - 1)}
                  >
                    <i
                      className="fa-solid fa-angle-left"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                  <span
                    onClick={() =>
                      currentPage < Math.ceil(reviews.length / itemsPerPage) &&
                      paginate(currentPage + 1)
                    }
                  >
                    <i
                      className="fa-solid fa-angle-right"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
            {/* Pagination end */}
            {/* Pagination end */}
            {/* <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex align-items-end">
                <div className="Pagination mt-4 ">
                  <div className="nmbering d-flex">
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                    <span>4</span>
                    <span>5</span>
                  </div>
                  <div className="linepag">
                    <span></span>
                  </div>
                </div>
                <div className="left-right-arrow-pag">
                  <span>
                    <i className="fa-solid fa-angle-left"></i>
                  </span>
                  <span>
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </div>
              </div>
              <div>
                <p style={{ color: "white" }}>
                  Showing 1 to 10 of 90 (9 Pages)
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
