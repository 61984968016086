import React, { useState } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import boxInnerParticle from "../assets/img/box-inner-particles.png";
import boxInnerParticle1 from "../assets/img/box-inner-particles1.png";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from ".././assets/Utils/Ip";
import axios from "axios";
import Swal from "sweetalert2";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Login() {
  const [show, setShow] = useState("");
  const [loginCredentials, SetLoginCredentials] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState(""); // State to manage error message
  const clearError = () => {
    setError(""); // Clear the error message
  };

  const navigate = useNavigate();
  // This function will handle the form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    let data = JSON.stringify({
      email: loginCredentials.email,
      password: loginCredentials.password,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response && response.status === 200) {
          localStorage.setItem("root", response.data.token);
        }
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          customClass: {
            // Assign your custom class to the popup container
            container: "my-custom-popup",
          },
        });

        navigate("/DashboardAdmin");

        // You can add more code to handle the response as needed
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Invalid credentials",
            customClass: {
              // Assign your custom class to the popup container
              container: "my-custom-popup",
            },
          });
        } else {
          setTimeout(() => {
            //setsinginloader(false)
            Swal.fire({
              icon: "warning",
              title: "Server Error!",
              text: "We are facing some issue connecting to server. Please try again",
              customClass: {
                // Assign your custom class to the popup container
                container: "my-custom-popup",
              },
            });
          }, 3000);
        }
        // You can add more code to handle the error as needed
      });
  };

  return (
    <div>
      {/* <Warning /> */}

      <Header />
      <section className="EXQUISITE font-bebas py-5">
        <div className="position-relative">
          <span className="bg-particle"></span>
          <div className="container-1640 position-relative z-2">
            <div className="w-75 p-4 bg-theme-box mx-auto my-5 position-relative">
              <img
                className="position-absolute start-0 top-0 w-75"
                src={boxInnerParticle}
                alt=""
              />
              <img
                className="position-absolute end-0 bottom-0 w-75"
                src={boxInnerParticle1}
                alt=""
              />
              <div className="border-golden text-center p-1">
                <h1 className="theme-text-color m-0 line-height fs-70 pt-5">
                  Welcome
                </h1>
                <h2 className="theme-text-yellow-light fs-50">
                  Login To Continue
                </h2>
                <form action="">
                  <div className=" font-poppins position-relative z-10">
                    <input
                      type="email"
                      placeholder="Email"
                      className="border-1 p-2 w-50 mx-auto border-golden placeholder-color bg-transparent theme-text-yellow-light my-md-5 my-3 d-block resp-input-login-page"
                      value={loginCredentials.email}
                      onChange={(e) => {
                        clearError(); // Clear the error message when user types
                        SetLoginCredentials({
                          ...loginCredentials,
                          email: e.target.value,
                        });
                      }}
                      onBlur={(e) => {
                        const emailValue = e.target.value;
                        const errorSpan = document.getElementById("errorSpan");

                        if (!emailValue) {
                          errorSpan.innerText = "Email is required";
                        } else {
                          // Email format ki validation
                          const emailFormat =
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                          if (!emailFormat.test(emailValue)) {
                            errorSpan.innerText = "Invalid email format";
                          } else {
                            errorSpan.innerText = ""; // Clear the error message if email is valid
                          }
                        }
                      }}
                    />

                    <span id="errorSpan" style={{ color: "red" }}></span>

                    <div className="position-relative login-pass-input-parent">
                      <input
                        type={show ? "text" : "password"}
                        placeholder="Password"
                        className="border-1 arrow-input p-2 w-50 mx-auto border-golden placeholder-color bg-transparent theme-text-yellow-light mb-4 d-block resp-input-login-page-email"
                        value={loginCredentials.password}
                        onChange={(e) => {
                          clearError(); // Clear the error message when user types
                          SetLoginCredentials({
                            ...loginCredentials,
                            password: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          const passwordValue = e.target.value;
                          const errorSpan =
                            document.getElementById("passwordErrorSpan");

                          if (!passwordValue) {
                            errorSpan.innerText = "Password is required";
                          } else if (passwordValue.length < 8) {
                            errorSpan.innerText =
                              "Password must be at least 8 characters long";
                          } else {
                            errorSpan.innerText = ""; // Clear the error message if password is valid
                          }
                        }}
                      />
                      <span
                        id="passwordErrorSpan"
                        style={{ color: "red" }}
                      ></span>
                      <FontAwesomeIcon
                        className="position-absolute text-white prnt-show-icn"
                        onClick={() => {
                          show ? setShow(false) : setShow(true);
                        }}
                        icon={show ? faEyeSlash : faEye}
                      />
                      {error && (
                        <p style={{ color: "red", textAlign: "center" }}>
                          {error}
                        </p>
                      )}
                    </div>
                    <button
                      to="/DashboardAdmin"
                      className="font-bebas border-0 px-5 d-table mb-5 mx-auto text-dark rounded-3 fs-2 py-0 btn-gradient"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Login
                    </button>
                    {/* <h2 className="font-bebas m-0 pt-4 theme-text-color">
                      For immediate Assistance
                    </h2>

                    <Link
                      className="theme-text-yellow-light "
                      to="mailt:info@nisacosmeticslondon.com"
                    >
                      <FontAwesomeIcon icon={faEnvelope} />{" "}
                      info@nisacosmeticslondon.com
                    </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Login;
