import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import goldenCircle from "../assets/img/goldenCircle.svg";
import Face from "../assets/img/face.png";
import Eyes from "../assets/img/eyes.png";
import Cheeks from "../assets/img/cheeks.png";
import Lips from "../assets/img/lips.png";
import featureproduct1 from "../assets/img/featureproduct1.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faStar } from "@fortawesome/free-solid-svg-icons";
import { baseUrl } from "../assets/Utils/Ip";
import axios from "axios";
import GetStars from "./GetStars";
import { Link, useNavigate } from "react-router-dom";

function FeatureProduct() {
  let mystyle = {
    opacity: 1,
  };

  const [product1, setProduct1] = useState([]);
  const [product2, setProduct2] = useState([]);
  const [product3, setProduct3] = useState([]);
  const [product4, setProduct4] = useState([]);
  const [product5, setProduct5] = useState([]);
  const [product6, setProduct6] = useState([]);
  const [product7, setProduct7] = useState([]);
  const [product8, setProduct8] = useState([]);
  const [product9, setProduct9] = useState([]);
  const [product10, setProduct10] = useState([]);
  const [product11, setProduct11] = useState([]);
  const [product12, setProduct12] = useState([]);

  const [chosen, setChosen] = useState(100)

  const navigate = useNavigate()

  const getFeatured = async () => {
    try {
      const res = await axios.get(`${baseUrl}/featured-products`);
      console.log('====================================');
      console.log(res.data);
      console.log('====================================');
      if (res.status === 200) {
        const products = res.data.categoryProducts;

        setProduct1(products[0].products[0]);
        setProduct2(products[0].products[1]);
        setProduct3(products[0].products[2]);

        setProduct4(products[1].products[0]);
        setProduct5(products[1].products[1]);
        setProduct6(products[1].products[2]);

        setProduct7(products[2].products[0]);
        setProduct8(products[2].products[1]);
        setProduct9(products[2].products[2]);

        setProduct10(products[3].products[0]);
        setProduct11(products[3].products[1]);
        setProduct12(products[3].products[2]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNavigate = (id) => {
    console.log(id);
    navigate("/shop", {
      state: id,
    });
  }

  useEffect(() => {
    getFeatured();
  }, []);

  // this code is needed to refresh scroll trigger when coming from another page
  useEffect(() => {
    const proUp = $(".home_p").offset().top;
    const winH = $(window).height();
    const pJsB = $(".p_js_b .img-inner img");
    const homePJs1 = $(".home_p .home_p_js_1");
    const homePJs2 = $(".home_p .home_p_js_2");
    const homePJs3 = $(".home_p .home_p_js_3");
    const homePJs4 = $(".home_p .home_p_js_4");

    const homePChange = (index) => {
      switch (index) {
        case 0:
          pJsB.css({
            transform: "rotate(0deg)",
          });
          homePJs1.css({
            transform: "rotate(0deg)",
            opacity: 1,
          });
          homePJs2.css({
            transform: "rotate(180deg)",
            opacity: 0,
          });
          homePJs3.css({
            transform: "rotate(180deg)",
            opacity: 0,
          });
          homePJs4.css({
            transform: "rotate(180deg)",
            opacity: 0,
          });
          $(".home_p_tit_1 h2").css("margin-left", "0");
          $(".home_p_tit_2 h2").css("margin-left", "100%");
          $(".home_p_tit_3 h2").css("margin-left", "100%");
          $(".home_p_tit_4 h2").css("margin-left", "100%");
          break;
        case 1:
          pJsB.css({
            transform: "rotate(90deg)",
          });
          homePJs1.css({
            transform: "rotate(180deg)",
            opacity: 0,
          });
          homePJs2.css({
            transform: "rotate(360deg)",
            opacity: 1,
          });
          homePJs3.css({
            transform: "rotate(180deg)",
            opacity: 0,
          });
          homePJs4.css({
            transform: "rotate(180deg)",
            opacity: 0,
          });
          $(".home_p_tit_1 h2").css("margin-left", "-100%");
          $(".home_p_tit_2 h2").css("margin-left", "0");
          $(".home_p_tit_3 h2").css("margin-left", "100%");
          $(".home_p_tit_4 h2").css("margin-left", "100%");
          break;
        case 2:
          pJsB.css({
            transform: "rotate(180deg)",
          });
          homePJs1.css({
            transform: "rotate(180deg)",
            opacity: 0,
          });
          homePJs2.css({
            transform: "rotate(540deg)",
            opacity: 0,
          });
          homePJs3.css({
            transform: "rotate(360deg)",
            opacity: 1,
          });
          homePJs4.css({
            transform: "rotate(180deg)",
            opacity: 0,
          });
          $(".home_p_tit_1 h2").css("margin-left", "-100%");
          $(".home_p_tit_2 h2").css("margin-left", "-100%");
          $(".home_p_tit_3 h2").css("margin-left", "0");
          $(".home_p_tit_4 h2").css("margin-left", "100%");
          break;
        case 3:
          pJsB.css({
            transform: "rotate(270deg)",
          });
          homePJs1.css({
            transform: "rotate(180deg)",
            opacity: 0,
          });
          homePJs2.css({
            transform: "rotate(540deg)",
            opacity: 0,
          });
          homePJs3.css({
            transform: "rotate(540deg)",
            opacity: 0,
          });
          homePJs4.css({
            transform: "rotate(360deg)",
            opacity: 1,
          });
          $(".home_p_tit_1 h2").css("margin-left", "-100%");
          $(".home_p_tit_2 h2").css("margin-left", "-100%");
          $(".home_p_tit_3 h2").css("margin-left", "-100%");
          $(".home_p_tit_4 h2").css("margin-left", "0");
          break;
        default:
          break;
      }
    };

    const handleScroll = () => {
      const top = $(document).scrollTop();

      if (top - proUp < winH * 1) {
        homePChange(0);
      } else if (top - proUp > winH * 1 && top - proUp < winH * 2) {
        homePChange(1);
      } else if (top - proUp > winH * 2 && top - proUp < winH * 3) {
        homePChange(2);
      } else if (top - proUp > winH * 3) {
        homePChange(3);
      }
    };

    const handleClickChange = (index) => {
      homePChange(index);
    };

    $(".circle-1").on("click", () => handleClickChange(0));
    $(".circle-2").on("click", () => handleClickChange(1));
    $(".circle-3").on("click", () => handleClickChange(2));
    $(".circle-4").on("click", () => handleClickChange(3));

    if (window.innerWidth > 991) {
      $(window).on("scroll", handleScroll);

      return () => {
        $(window).off("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <>
      {/* <div className="feature-product-tablet-tab-parent d-lg-none position-relative">
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item">
            <div className="flex">
              <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  Accordion Item #1
                </button>
              </h2>
              <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Accordion Item #2
                </button>
              </h2>
              <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Accordion Item #3
                </button>
              </h2>
            </div>
            <div
              id="panelsStayOpen-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne"
            >
              <div class="accordion-body">
                <strong>This is the first item's accordion body.</strong> It is
                shown by default, until the collapse plugin adds the appropriate
                classes that we use to style each element. These classes control
                the overall appearance, as well as the showing and hiding via
                CSS transitions. You can modify any of this with custom CSS or
                overriding our default variables. It's also worth noting that
                just about any HTML can go within the{" "}
                <code>.accordion-body</code>, though the transition does limit
                overflow.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div
              id="panelsStayOpen-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingTwo"
            >
              <div class="accordion-body">
                <strong>This is the second item's accordion body.</strong> It is
                hidden by default, until the collapse plugin adds the
                appropriate classes that we use to style each element. These
                classes control the overall appearance, as well as the showing
                and hiding via CSS transitions. You can modify any of this with
                custom CSS or overriding our default variables. It's also worth
                noting that just about any HTML can go within the{" "}
                <code>.accordion-body</code>, though the transition does limit
                overflow.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div
              id="panelsStayOpen-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree"
            >
              <div class="accordion-body">
                <strong>This is the third item's accordion body.</strong> It is
                hidden by default, until the collapse plugin adds the
                appropriate classes that we use to style each element. These
                classes control the overall appearance, as well as the showing
                and hiding via CSS transitions. You can modify any of this with
                custom CSS or overriding our default variables. It's also worth
                noting that just about any HTML can go within the{" "}
                <code>.accordion-body</code>, though the transition does limit
                overflow.
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="sec-featured">
        <div className="tab-four-circle-parent d-lg-none d-flex align-items-center justify-content-between">
          <div className="tab-four-circle-inner tab-circle-img-main-width position-relative circle-1">
            <img className="blend-screen-tab w-100" src={goldenCircle} alt="" />
            <h2 className="theme-text-color font-bebas absolutely-center-text-tab" onClick={() => setChosen(100)}>
              Face
            </h2>
          </div>
          <div className="tab-four-circle-inner tab-circle-img-main-width position-relative circle-2">
            <img className="blend-screen-tab w-100" src={goldenCircle} alt="" />
            <h2 className="theme-text-color font-bebas absolutely-center-text-tab" onClick={() => setChosen(101)}>
              Eyes
            </h2>
          </div>
          <div className="tab-four-circle-inner tab-circle-img-main-width position-relative circle-3">
            <img className="blend-screen-tab w-100" src={goldenCircle} alt="" />
            <h2 className="theme-text-color font-bebas absolutely-center-text-tab" onClick={() => setChosen(102)}>
              Cheeks
            </h2>
          </div>
          <div className="tab-four-circle-inner tab-circle-img-main-width position-relative circle-4">
            <img className="blend-screen-tab w-100" src={goldenCircle} alt="" />
            <h2 className="theme-text-color font-bebas absolutely-center-text-tab" onClick={() => setChosen(103)}>
              Lips
            </h2>
          </div>
        </div>
        <div className="content-area">
          <div className="row row-collapse row-full-width align-center home_p">
            <div className="col">
              <div className="col-inner">
                <div
                  className="row row-collapse home_p_b"
                  style={{ maxWidth: 2500 }}
                >
                  <div className="col home_p_star basis-1/2">
                    <div className="col-inner">
                      <div className="img bg-dark-gray has-hover p_js_b">
                        <div className="img-inner dark">
                          <img
                            src={goldenCircle}
                            alt=""
                            style={{ mixBlendMode: "screen" }}
                          />
                        </div>
                      </div>
                      <div className="text home_p_tit_1">
                        <h2 className="slide-left-text theme-text-color font-bebas">
                          Face
                        </h2>
                      </div>
                      <div className="text home_p_tit_2">
                        <h2 className="slide-left-text theme-text-color font-bebas">
                          Eyes
                        </h2>
                      </div>
                      <div className="text home_p_tit_3">
                        <h2 className="slide-left-text theme-text-color font-bebas">
                          Cheeks
                        </h2>
                      </div>
                      <div className="text home_p_tit_4">
                        <h2 className="slide-left-text theme-text-color font-bebas">
                          Lips
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="col home_p_js medium-7 small-12 large-7">
                    <div className="col-inner">
                      <section className="section home_p_js_1">

                        <div className="section-content relative">

                          <div className="img has-hover face-img-parent">
                            <div className="img-inner dark">
                              <img
                                className="vapes custom-scroll-img-class"
                                src={Face}
                                alt=""
                              />
                            </div>

                          </div>
                          <div className="img has-hover"></div>
                        </div>
                      </section>
                      <section className="section home_p_js_2">
                        <div className="section-content relative">
                          <div className="img has-hover">
                            <div className="img-inner dark">
                              <img
                                src={Eyes}
                                className="slide-right-img custom-scroll-img-class"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="section home_p_js_3">
                        <div className="section-content relative">
                          <div className="img has-hover cheeks-img-parent">
                            <div className="img-inner dark">
                              <img
                                src={Cheeks}
                                className="slide-right-img custom-scroll-img-class"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="section home_p_js_4">
                        <div className="section-content relative">
                          <div className="img has-hover lips-img-parent">
                            <div className="img-inner dark">
                              <img
                                src={Lips}
                                className="slide-right-img custom-scroll-img-class"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                  <div className="allproducts home_p_star w-50 font-bebas d-lg-block d-none">
                    <div className="col-inner">
                      <div className="text home_p_tit_1">
                        <h2>
                          <Link
                            className="d-flex justify-content-end"
                            to={`/buynow/${product1?.product_id}`}
                          >


                            <div className="align-items-center d-flex divh2 font-size-responsive justify-content-end right-product-bg-gradient text-end w-100 gapping-scroll-right">
                              <div className="d-flex flex-column me-4">
                                <h1 className="fs-50 btnname theme-text-color m-0">
                                  {product1?.product_name}
                                </h1>
                                <div className="stars">
                                  <GetStars
                                    value={product1?.avg_rating}
                                    color={"orange"}
                                  />
                                </div>
                                <h3 className="theme-text-yellow-light fs-2 mb-0">
                                  £
                                  {product1?.sale_price === "" ||
                                    product1?.sale_price === "0"
                                    ? product1?.regular_price
                                    : product1?.sale_price}
                                </h3>
                                <Link
                                  className="d-flex justify-content-end"
                                  to={`/buynow/${product1?.product_id}`}
                                >
                                  {" "}
                                  <FontAwesomeIcon
                                    fontSize="20px"
                                    className="theme-text-color"
                                    icon={faCartShopping}
                                  />
                                </Link>
                              </div>
                              <img
                                className="slide-right-img-cust"
                                src={
                                  product1 && `${baseUrl}/${product1?.main_image}`
                                }
                                alt=""
                              />
                            </div>
                          </Link>
                          <Link
                            className="d-flex justify-content-end"
                            to={`/buynow/${product2?.product_id}`}
                          >
                            <div className="align-items-center d-flex divh2 font-size-responsive justify-content-end right-product-bg-gradient text-end w-100 gapping-scroll-right">
                              <div className="d-flex flex-column me-4">
                                <h1 className="fs-50 btnname theme-text-color m-0">
                                  {product2?.product_name}
                                </h1>
                                <div className="stars">
                                  <GetStars
                                    value={product2?.avg_rating}
                                    color={"orange"}
                                  />
                                </div>
                                <h3 className="theme-text-yellow-light fs-2 mb-0">
                                  £
                                  {product2?.sale_price === "" ||
                                    product2?.sale_price === "0"
                                    ? product2?.regular_price
                                    : product2?.sale_price}
                                </h3>
                                <Link
                                  className="d-flex justify-content-end"
                                  to={`/buynow/${product2?.product_id}`}
                                >
                                  {" "}
                                  <FontAwesomeIcon
                                    fontSize="20px"
                                    className="theme-text-color"
                                    icon={faCartShopping}
                                  />
                                </Link>
                              </div>
                              <img
                                className="slide-right-img-cust"
                                src={
                                  product2 && `${baseUrl}/${product2?.main_image}`
                                }
                                alt=""
                              />
                            </div>
                          </Link>
                          <Link
                            className="d-flex justify-content-end"
                            to={`/buynow/${product3?.product_id}`}
                          >
                            <div className="align-items-center d-flex divh2 font-size-responsive justify-content-end right-product-bg-gradient text-end w-100 gapping-scroll-right">
                              <div className="d-flex flex-column me-4">
                                <h1 className="fs-50 btnname theme-text-color m-0">
                                  {product3?.product_name}
                                </h1>
                                <div className="stars">
                                  <GetStars
                                    value={product3?.avg_rating}
                                    color={"orange"}
                                  />
                                </div>
                                <h3 className="theme-text-yellow-light fs-2 mb-0">
                                  £
                                  {product3?.sale_price === "" ||
                                    product3?.sale_price === "0"
                                    ? product3?.regular_price
                                    : product3?.sale_price}
                                </h3>
                                <Link
                                  className="d-flex justify-content-end"
                                  to={`/buynow/${product3?.product_id}`}
                                >
                                  {" "}
                                  <FontAwesomeIcon
                                    fontSize="20px"
                                    className="theme-text-color"
                                    icon={faCartShopping}
                                  />
                                </Link>
                              </div>
                              <img
                                className="slide-right-img-cust"
                                src={
                                  product3 && `${baseUrl}/${product3?.main_image}`
                                }
                                alt=""
                              />
                            </div>
                          </Link>
                        </h2>
                      </div>
                      <div className="text home_p_tit_2">
                        <h2>
                          <Link
                            className="d-flex justify-content-end"
                            to={`/buynow/${product4?.product_id}`}
                          >
                            <div className="align-items-center d-flex divh2 font-size-responsive justify-content-end right-product-bg-gradient text-end w-100 gapping-scroll-right">
                              <div className="d-flex flex-column me-4">
                                <h1 className="fs-50 btnname theme-text-color m-0">
                                  {product4?.product_name}
                                </h1>
                                <div className="stars">
                                  <GetStars
                                    value={product4?.avg_rating}
                                    color={"orange"}
                                  />
                                </div>
                                <h3 className="theme-text-yellow-light fs-2 mb-0">
                                  £
                                  {product4?.sale_price === "" ||
                                    product4?.sale_price === "0"
                                    ? product4?.regular_price
                                    : product4?.sale_price}
                                </h3>
                                <Link
                                  className="d-flex justify-content-end"
                                  to={`/buynow/${product4?.product_id}`}
                                >
                                  {" "}
                                  <FontAwesomeIcon
                                    fontSize="20px"
                                    className="theme-text-color"
                                    icon={faCartShopping}
                                  />
                                </Link>
                              </div>
                              <img
                                className="slide-right-img-cust"
                                src={
                                  product4 && `${baseUrl}/${product4?.main_image}`
                                }
                                alt=""
                              />
                            </div>
                          </Link>
                          <Link
                            className="d-flex justify-content-end"
                            to={`/buynow/${product5?.product_id}`}
                          >
                            <div className="align-items-center d-flex divh2 font-size-responsive justify-content-end right-product-bg-gradient text-end w-100 gapping-scroll-right">
                              <div className="d-flex flex-column me-4">
                                <h1 className="fs-50 btnname theme-text-color m-0">
                                  {product5?.product_name}
                                </h1>
                                <div className="stars">
                                  <GetStars
                                    value={product5?.avg_rating}
                                    color={"orange"}
                                  />
                                </div>
                                <h3 className="theme-text-yellow-light fs-2 mb-0">
                                  £
                                  {product5?.sale_price === "" ||
                                    product5?.sale_price === "0"
                                    ? product5?.regular_price
                                    : product5?.sale_price}
                                </h3>
                                <Link
                                  className="d-flex justify-content-end"
                                  to={`/buynow/${product5?.product_id}`}
                                >
                                  {" "}
                                  <FontAwesomeIcon
                                    fontSize="20px"
                                    className="theme-text-color"
                                    icon={faCartShopping}
                                  />
                                </Link>
                              </div>
                              <img
                                className="slide-right-img-cust"
                                src={
                                  product5 && `${baseUrl}/${product5?.main_image}`
                                }
                                alt=""
                              />
                            </div>
                          </Link>
                          <Link
                            className="d-flex justify-content-end"
                            to={`/buynow/${product6?.product_id}`}
                          >
                            <div className="align-items-center d-flex divh2 font-size-responsive justify-content-end right-product-bg-gradient text-end w-100 gapping-scroll-right">
                              <div className="d-flex flex-column me-4">
                                <h1 className="fs-50 btnname theme-text-color m-0">
                                  {product6?.product_name}
                                </h1>
                                <div className="stars">
                                  <GetStars
                                    value={product6?.avg_rating}
                                    color={"orange"}
                                  />
                                </div>
                                <h3 className="theme-text-yellow-light fs-2 mb-0">
                                  £
                                  {product6?.sale_price === "" ||
                                    product6?.sale_price === "0"
                                    ? product6?.regular_price
                                    : product6?.sale_price}
                                </h3>
                                <Link
                                  className="d-flex justify-content-end"
                                  to={`/buynow/${product6?.product_id}`}
                                >
                                  {" "}
                                  <FontAwesomeIcon
                                    fontSize="20px"
                                    className="theme-text-color"
                                    icon={faCartShopping}
                                  />
                                </Link>
                              </div>
                              <img
                                className="slide-right-img-cust"
                                src={
                                  product6 && `${baseUrl}/${product6?.main_image}`
                                }
                                alt=""
                              />
                            </div>
                          </Link>
                        </h2>
                      </div>
                      <div className="text home_p_tit_3">
                        <h2>
                          <Link
                            className="d-flex justify-content-end"
                            to={`/buynow/${product7?.product_id}`}
                          >
                            <div className="align-items-center d-flex divh2 font-size-responsive justify-content-end right-product-bg-gradient text-end w-100 gapping-scroll-right">
                              <div className="d-flex flex-column me-4">
                                <h1 className="fs-50 btnname theme-text-color m-0">
                                  {product7?.product_name}
                                </h1>
                                <div className="stars">
                                  <GetStars
                                    value={product7?.avg_rating}
                                    color={"orange"}
                                  />
                                </div>
                                <h3 className="theme-text-yellow-light fs-2 mb-0">
                                  £
                                  {product7?.sale_price === "" ||
                                    product7?.sale_price === "0"
                                    ? product7?.regular_price
                                    : product7?.sale_price}
                                </h3>
                                <Link
                                  className="d-flex justify-content-end"
                                  to={`/buynow/${product7?.product_id}`}
                                >
                                  {" "}
                                  <FontAwesomeIcon
                                    fontSize="20px"
                                    className="theme-text-color"
                                    icon={faCartShopping}
                                  />
                                </Link>
                              </div>
                              <img
                                className="slide-right-img-cust"
                                src={
                                  product7 && `${baseUrl}/${product7?.main_image}`
                                }
                                alt=""
                              />
                            </div>
                          </Link>
                          <Link
                            className="d-flex justify-content-end"
                            to={`/buynow/${product8?.product_id}`}
                          >
                            <div className="align-items-center d-flex divh2 font-size-responsive justify-content-end right-product-bg-gradient text-end w-100 gapping-scroll-right">
                              <div className="d-flex flex-column me-4">
                                <h1 className="fs-50 btnname theme-text-color m-0">
                                  {product8?.product_name}
                                </h1>
                                <div className="stars">
                                  <GetStars
                                    value={product8?.avg_rating}
                                    color={"orange"}
                                  />
                                </div>
                                <h3 className="theme-text-yellow-light fs-2 mb-0">
                                  £
                                  {product8?.sale_price === "" ||
                                    product8?.sale_price === "0"
                                    ? product8?.regular_price
                                    : product8?.sale_price}
                                </h3>
                                <Link
                                  className="d-flex justify-content-end"
                                  to={`/buynow/${product8?.product_id}`}
                                >
                                  {" "}
                                  <FontAwesomeIcon
                                    fontSize="20px"
                                    className="theme-text-color"
                                    icon={faCartShopping}
                                  />
                                </Link>
                              </div>
                              <img
                                className="slide-right-img-cust"
                                src={
                                  product8 && `${baseUrl}/${product8?.main_image}`
                                }
                                alt=""
                              />
                            </div>
                          </Link>
                        </h2>
                      </div>
                      <div className="text home_p_tit_4">
                        <h2>
                          {/* Coming Soon */}
                          <Link
                            className="d-flex justify-content-end"
                            to={`/buynow/${product10?.product_id}`}
                          >


                            <div className="align-items-center d-flex divh2 font-size-responsive justify-content-end right-product-bg-gradient text-end w-100 gapping-scroll-right">
                              <div className="d-flex flex-column me-4">
                                <h1 className="fs-50 btnname theme-text-color m-0">
                                  {product10?.product_name}
                                </h1>
                                <div className="stars">
                                  <GetStars
                                    value={product10?.avg_rating}
                                    color={"orange"}
                                  />
                                </div>
                                <h3 className="theme-text-yellow-light fs-2 mb-0">
                                  £
                                  {product10?.sale_price === "" ||
                                    product10?.sale_price === "0"
                                    ? product10?.regular_price
                                    : product10?.sale_price}
                                </h3>
                                <Link
                                  className="d-flex justify-content-end"
                                  to={`/buynow/${product10?.product_id}`}
                                >
                                  {" "}
                                  <FontAwesomeIcon
                                    fontSize="20px"
                                    className="theme-text-color"
                                    icon={faCartShopping}
                                  />
                                </Link>
                              </div>
                              <img
                                className="slide-right-img-cust"
                                src={
                                  product10 && `${baseUrl}/${product10?.main_image}`
                                }
                                alt=""
                              />
                            </div>
                          </Link>
                          <Link
                            className="d-flex justify-content-end"
                            to={`/buynow/${product11?.product_id}`}
                          >
                            <div className="align-items-center d-flex divh2 font-size-responsive justify-content-end right-product-bg-gradient text-end w-100 gapping-scroll-right">
                              <div className="d-flex flex-column me-4">
                                <h1 className="fs-50 btnname theme-text-color m-0">
                                  {product11?.product_name}
                                </h1>
                                <div className="stars">
                                  <GetStars
                                    value={product11?.avg_rating}
                                    color={"orange"}
                                  />
                                </div>
                                <h3 className="theme-text-yellow-light fs-2 mb-0">
                                  £
                                  {product11?.sale_price === "" ||
                                    product11?.sale_price === "0"
                                    ? product11?.regular_price
                                    : product11?.sale_price}
                                </h3>
                                <Link
                                  className="d-flex justify-content-end"
                                  to={`/buynow/${product11?.product_id}`}
                                >
                                  {" "}
                                  <FontAwesomeIcon
                                    fontSize="20px"
                                    className="theme-text-color"
                                    icon={faCartShopping}
                                  />
                                </Link>
                              </div>
                              <img
                                className="slide-right-img-cust"
                                src={
                                  product11 && `${baseUrl}/${product11?.main_image}`
                                }
                                alt=""
                              />
                            </div>
                          </Link>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="btn-master-home-page featurebtn" onClick={() => handleNavigate(chosen)}>Shop Now</button>
          </div>
        </div>
      </div>
      {/* <div className="parent-tab-featured-product position-relative d-lg-none d-block">
        <div className="container-1640">
          
          <div className="tab-main-circle-parent">
            <div className="col-inner">
              <div className="img bg-dark-gray has-hover p_js_b">
                <div className="img-inner dark">
                  <img
                    src={goldenCircle}
                    alt=""
                    style={{ mixBlendMode: "screen" }}
                  />
                </div>
              </div>
              <div className="text home_p_tit_1">
                <h2 className="slide-left-text theme-text-color font-bebas">
                  Face
                </h2>
              </div>
              <div className="text home_p_tit_2">
                <h2 className="slide-left-text theme-text-color font-bebas">
                  Eyes
                </h2>
              </div>
              <div className="text home_p_tit_3">
                <h2 className="slide-left-text theme-text-color font-bebas">
                  Cheeks
                </h2>
              </div>
              <div className="text home_p_tit_4">
                <h2 className="slide-left-text theme-text-color font-bebas">
                  Lips
                </h2>
              </div>
            </div>
            <section className="section home_p_js_1">
              <div className="section-content relative">
                <div className="img has-hover face-img-parent">
                  <div className="img-inner dark">
                    <img
                      className="vapes custom-scroll-img-class"
                      src={Face}
                      alt=""
                    />
                  </div>
                </div>
                <div className="img has-hover"></div>
              </div>
            </section>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default FeatureProduct;
