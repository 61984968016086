import { createContext, useState, useEffect } from "react";

export const CartContext = createContext();


export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(
    localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : []
  );

  const [totalQuantity, setTotalQuantity] = useState(0);

  const emptyCart = () => {
    localStorage.removeItem("cartItems");
    setCartItems([]);
  };

  const addToCart = (item, color) => {
    const isItemInCart = cartItems.find(
      (cartItem) => cartItem.product_id === item.product_id
    );
    console.log(isItemInCart);
    if (!isItemInCart) {
      setCartItems([
        ...cartItems,
        { ...item, p_quantity: 1, selectedColor: color },
      ]);
    } 
    setTotalQuantity(totalQuantity + 1);
  };

  const incrementQuantity = (item) => {
    setCartItems(
      cartItems.map((cartItem) =>
        cartItem.product_id === item.product_id
          ? { ...cartItem, p_quantity: cartItem.p_quantity + 1 }
          : cartItem
      )
    );
    setTotalQuantity(totalQuantity + 1);
  };

  const decrementQuantity = (item) => {
    const updatedCartItems = cartItems.map((cartItem) =>
      cartItem.product_id === item.product_id
        ? { ...cartItem, p_quantity: cartItem.p_quantity - 1 }
        : cartItem
    );

    const filteredCartItems = updatedCartItems.filter(
      (cartItem) => cartItem.p_quantity > 0
    );

    setTotalQuantity(totalQuantity - 1);
    setCartItems(filteredCartItems);
  };

  const updateQuantity = (item, addtocartqty, selectedColor) => {
    const itemInCart = cartItems.find(
      (cartItem) =>
        cartItem.product_id === item.product_id &&
        cartItem.selectedColor === selectedColor
    );

    if (itemInCart) {
      // If item is already in the cart, update its quantity
      const updatedCartItems = cartItems.map((cartItem) => {
        if (
          cartItem.product_id === item.product_id &&
          cartItem.selectedColor === selectedColor
        ) {
          return {
            ...cartItem,
            p_quantity: addtocartqty,
          };
        }
        return cartItem;
      });

      setCartItems(updatedCartItems);
    } else {
      // If item is not in the cart, add a new row
      const newItem = {
        ...item,
        p_quantity: addtocartqty,
        selectedColor: selectedColor,
      };
      setCartItems([...cartItems, newItem]);
    }

    // Update the total quantity
    setTotalQuantity(totalQuantity + addtocartqty - item.p_quantity);
  };

  const updateQuantityArr = (arr) => {
    let cartsArr = [...cartItems];

    arr.forEach((item) => {
      const itemInCart = cartsArr.find(
        (cartItem) => cartItem.product_id === item.product_id
      );

      if (itemInCart) {
        cartsArr = cartsArr.map((cartItem) => {
          if (cartItem.product_id === item.product_id) {
            return { ...cartItem, p_quantity: item.p_quantity };
          }
          return cartItem;
        });
      } else {
        cartsArr.push(item);
      }
    });

    setCartItems(cartsArr);
  };

  const CancelProduct = (item) => {
    // Filter out the item with matching product_id and selectedColor
    const updatedCartItems = cartItems.filter(
      (cartItem) =>
        !(
          cartItem.product_id === item.product_id &&
          cartItem.selectedColor === item.selectedColor
        )
    );

    // Calculate the new totalQuantity by subtracting the canceled item's quantity
    const newTotalQuantity = totalQuantity - item.p_quantity;

    // Update state with the new cart items and total quantity
    setCartItems(updatedCartItems);
    setTotalQuantity(newTotalQuantity);
  };

  const removeFromCart = (item) => {
    const isItemInCart = cartItems.find(
      (cartItem) => cartItem.product_id === item.product_id
    );

    if (isItemInCart.p_quantity !== 0) {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.product_id === item.product_id
            ? { ...cartItem, p_quantity: cartItem.p_quantity - 1 }
            : cartItem
        )
      );
      setTotalQuantity(totalQuantity - 1);

      // setCartItems(cartItems.filter((cartItem) => cartItem.product_id !== item.product_id));
    } else {
    }
  };

  const getProductById = (productId) => {
    const product = cartItems.find((item) => item.product_id === productId);

    return product || null; // Return null if the product is not found
  };

  const getCartTotal = () => {
    const value = cartItems.reduce(
      (total, item) => total + item.price * item.p_quantity,
      0
    );

    return value;
  };

  const getCartProductIds = () => {
    return cartItems?.map((item) => item.product_id);
  };

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems), totalQuantity);
  }, [cartItems]);

  useEffect(() => {
    const cartItems = localStorage.getItem("cartItems");
    if (cartItems) {
      setCartItems(JSON.parse(cartItems));
    }
  }, []);

  useEffect(() => {
    const newTotalQuantity = cartItems.reduce(
      (total, item) => total + item.p_quantity,
      0
    );
    setTotalQuantity(newTotalQuantity);
  }, [cartItems]);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        totalQuantity,
        getProductById,
        getCartTotal,
        decrementQuantity,
        updateQuantity,
        updateQuantityArr,
        CancelProduct,
        incrementQuantity,
        getCartProductIds,
        emptyCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
