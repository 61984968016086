import { faCheck, faPencil, faReply } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import Sidebar from "../Component/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { baseUrl } from "../../assets/Utils/Ip";
import Swal from "sweetalert2";

function NewsLetter() {
  const [newsletter, setNewsletter] = useState({
    title: "",
    subject: "",
    description: "",
  });

  const token = localStorage.getItem("root");
  const navigate = useNavigate();

  const handleTitle = (e) => {
    if (e.target.value === "") {
      setNewsletter({ ...newsletter, title: "" });
    } else if (e.target.value.length >= 100) {
      return;
    } else {
      setNewsletter({ ...newsletter, title: e.target.value });
    }
  };

  const handleSubject = (e) => {
    if (e.target.value === "") {
      setNewsletter({ ...newsletter, subject: "" });
    } else if (e.target.value.length >= 100) {
      return;
    } else {
      setNewsletter({ ...newsletter, subject: e.target.value });
    }
  };

  const handleDesc = (e) => {
    if (e.target.value === "") {
      setNewsletter({ ...newsletter, description: "" });
    } else if (e.target.value.length >= 225) {
      return;
    } else {
      setNewsletter({ ...newsletter, description: e.target.value });
    }
  };

  const handleSubmit = async () => {
    try {
      if (!newsletter.title || !newsletter.subject || !newsletter.description) {
        Swal.fire({
          icon: "error",
          title: "All fields are required",
          text: "",
        });
        return;
      }

      const res = await axios.post(
        `${baseUrl}/send-newsletter`,
        {
          title: newsletter.title,
          subject: newsletter.subject,
          description: newsletter.description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Newsletter sent successfully",
          text: "",
        });

        setNewsletter({
          ...newsletter,
          description: "",
          subject: "",
          title: "",
        });

        navigate("/DashboardAdmin");

        return;
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error sending newsletter",
        text: "",
      });
    }
  };

  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Newsletter</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/PromoCode"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
              <div className="add-categorie-list mb-3 mb-5 pb-4 pt-4">
                <FontAwesomeIcon color="#fff" icon={faPencil} />
                <span className="mx-2">Send Newsletter</span>
              </div>
            </div>
            <div className="edit-cust-grid">
              <div className="edit-cust-grid-inner text-end">
                <p>Title</p>
              </div>
              <div className="edit-cust-grid-inner">
                <input
                  type="text"
                  value={newsletter.title}
                  onChange={(e) => handleTitle(e)}
                  placeholder="New Discount"
                  required
                />
              </div>
            </div>
            <hr className="hr-edit-cust bg-white" />
            <div className="edit-cust-grid">
              <div className="edit-cust-grid-inner text-end">
                <p>Subject</p>
              </div>
              <div className="edit-cust-grid-inner">
                <input
                  type="text"
                  value={newsletter.subject}
                  onChange={(e) => handleSubject(e)}
                  placeholder="Get It Now"
                  required
                />
              </div>
            </div>
            <div className="grid-add-news d-grid my-4">
              <label className="text-light d-block text-end" htmlFor="">
                Description
              </label>
              <textarea
                rows="5"
                className="py-1 px-2"
                type="text"
                value={newsletter.description}
                onChange={(e) => handleDesc(e)}
                placeholder="Description"
                required
              ></textarea>
            </div>
            <div className="grid-add d-grid my-4">
              <label htmlFor=""></label>
              <button
                className="fw-bold add-save-btn rounded-3 border-0 ms-auto"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      {/* <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100">
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <Link
                to="/ManageReviews"
                className="btn-close text-dark position-relative bg-yellow d-flex align-items-center justify-content-center rounded-pill"
                data-bs-dismiss="modal"
              >
                X
              </Link>
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="green" fontSize="80px" icon={faCheck} />
              <h1 className="text-light">
                Newsletter has been sent to registered subscribers
              </h1>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
}

export default NewsLetter;
