import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ProductImg from "../../../src/assets/img/admin/product1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faListUl,
  faPlus,
  faTrash,
  faReply,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../Component/Sidebar";
import axios from "axios";
import Swal from "sweetalert2";
import { baseUrl } from "../../assets/Utils/Ip";
import Pagination from "../Component/Pagination"

export default function DeletedProducts() {
  const navigate = useNavigate();
  const [allProducts, setAllProducts] = useState();
  const [visibleProducts, setVisibleProducts] = useState(5);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

const productsExist = allProducts && allProducts.length > 0;
const allproductsArray = productsExist ? allProducts : [];
const indexOfLastProduct = currentPage * itemsPerPage;
const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
const currentproducts = allProducts?.slice(
  indexOfFirstProduct,
  indexOfLastProduct
);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${baseUrl}/get-all-deleted-product`,
        };

        const response = await axios.request(config);

        setAllProducts(response.data.results);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const handleRestore = (id) => {
    // Inside the handleDelete function, place your Axios request
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${baseUrl}/restore-product/${id}`, // Replace with the appropriate URL
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "success", // Use "success" for the icon
          title: "Restore Product ",
          text: "Your Product Restore successfully",
        });
        navigate("/AdminProduct");
        // Handle success, e.g., show a success message
        setAllProducts((prevProd) =>
          prevProd.filter((product) => product.product_id !== id)
        );
      })
      .catch((error) => {});
  };

  const displayedProducts = allProducts?.slice(0, visibleProducts);
  const handleSelectAll = () => {
    setSelectedProducts(allProducts?.map((products) => products?.product_id)); // Step 2
  };

  const handleDeselectAll = () => {
    setSelectedProducts([]); // Step 3
  };

  const handleBrandSelect = (productID) => {
    // Step 4
    if (selectedProducts.includes(productID)) {
      setSelectedProducts(selectedProducts.filter((id) => id !== productID));
    } else {
      setSelectedProducts([...selectedProducts, productID]);
    }
  };

  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Deleted PRODUCTS</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-yellow w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/AddProduct"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Link>

                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/AdminProduct"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>

            <div className="add-categorie-list">
              <FontAwesomeIcon color="#fff" icon={faListUl} />
              <span className="mx-2">Product List</span>
            </div>
            <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                <div className="product-table-parent">
                  <div className="product-grid-divide w-100 align-items-center rounded-3 my-3">
                    <div className="categori-name p-3">
                      <input
                        type="checkbox"
                        onChange={() =>
                          selectedProducts?.length < allProducts?.length
                            ? handleSelectAll()
                            : handleDeselectAll()
                        }
                        checked={
                          selectedProducts.length === allProducts?.length
                        }
                      />
                    </div>
                    <div className="categori-name p-3">
                      <p className="mx-3 mb-0 ">Images</p>
                    </div>
                    <div className="categori-name d-flex align-items-center p-3">
                      <p className="mx-3 mb-0 ">Product Name </p>
                      <FontAwesomeIcon icon={faChevronUp} />
                    </div>
                    <div className="categori-name border-end p-3 text-start">
                      <p className="mb-0">Category</p>
                    </div>
                    <div className="categori-name border-end p-3 text-center">
                      <p className="mb-0">Price</p>
                    </div>
                    <div className="categori-name border-end p-3 text-center">
                      <p className="mb-0">Quantity</p>
                    </div>
                    <div className="categori-name p-3 text-end border-end-0">
                      <p className="mb-0">Action</p>
                    </div>
                  </div>
                  {currentproducts?.map((product) => (
                    <div
                      key={product.id}
                      className="product-grid-divide-box w-100 align-items-center rounded-3 my-3 text-light"
                    >
                      <div className="h-100 d-flex align-items-center categori-name p-2">
                        <input
                          type="checkbox"
                          onChange={() => handleBrandSelect(product.product_id)}
                          checked={selectedProducts.includes(
                            product.product_id
                          )}
                        />
                      </div>
                      <div className="h-100 categori-name text-center p-2">
                        <img
                          className=" "
                          src={`${baseUrl}/${product.main_image}`}
                          alt=""
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50px",
                          }}
                        />
                      </div>
                      <div className="h-100 categori-name justify-content-between d-flex align-items-center p-2">
                        <p className="mx-3">{product.product_name}</p>
                      </div>
                      <div className="h-100 d-flex align-items-center categori-name border-end p-2 text-start ">
                        <p>{product.category_name}</p>
                      </div>
                      <div className="h-100 categori-name border-end p-2 text-end">
                        <p className="text-decoration-line-through">
                          ${product.regular_price}
                        </p>
                        <p className="text-warning">${product.sale_price}</p>
                      </div>
                      <div className="h-100 d-flex align-items-center categori-name border-end p-2 justify-content-end">
                        <p>{product.quantity}</p>
                      </div>
                      <div className="h-100 d-flex align-items-center justify-content-end categori-name p-2 px-3 text-end">
                        <Link
                          className="  add-categorie-btn text-dark rounded-2 px-5 py-2 font-bebas"
                          onClick={() => handleRestore(product.product_id)}
                        >
                          Restore
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
                <Pagination
              pageCount={Math.ceil(allProducts?.length / itemsPerPage)}
              currentPage={currentPage}
              handlePageChange={paginate}
            />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100">
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light position-relative"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light">
                You are about to delete this product
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-danger"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Modal title
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">..awdawdawdawd.</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
