import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply, faPencil } from "@fortawesome/free-solid-svg-icons";

import Sidebar from "../Component/Sidebar";
import { baseUrl } from "../../assets/Utils/Ip";
import axios from "axios";
import Swal from "sweetalert2";

export default function AddCategories() {
  const navigate = useNavigate();

  const token = localStorage.getItem("root");

  const [addCategories, setAddCategories] = useState({
    categoryName: "",
    metatag: "",
    metadescription: "",
    metakeyword: "",
  });

  const [loading, setLoading] = useState(false);

  let regex;

  const handleName = (value) => {
    if (value.length > 255) {
      return;
    }

    setAddCategories({ ...addCategories, categoryName: value });
  };

  const handlemetatag = (value) => {
    // regex = new RegExp("^[A-Za-z0-9\\s]+$");
    if (value.length > 255) {
      return;
    }

    setAddCategories({ ...addCategories, metatag: value });
  };

  const handlemetadescription = (value) => {
    if (value.length > 255) {
      return;
    }

    setAddCategories({ ...addCategories, metadescription: value });
  };

  const handlemetakeyword = (value) => {
    if (value.length > 255) {
      return;
    }

    setAddCategories({ ...addCategories, metakeyword: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    if (
      !addCategories.categoryName ||
      !addCategories.metadescription ||
      !addCategories.metakeyword ||
      !addCategories.metatag
    ) {
      Swal.fire({
        icon: "error", // Use "success" for the icon
        title: "Empty Fields",
        text: "Kindly fill all the fields",
        customClass: {
          // Assign your custom class to the popup container
          container: "my-custom-popup",
        },
      });

      setLoading(false);
      return;
    }

    let data = {
      category_name: addCategories.categoryName.trim(),
      meta_tag_title: addCategories.metatag,
      meta_tag_description: addCategories.metadescription,
      meta_tag_keywords: addCategories.metakeyword,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/create-category`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "success", // Use "success" for the icon
          title: "Added Category",
          text: "Your Category Added Successfully",
          customClass: {
            // Assign your custom class to the popup container
            container: "my-custom-popup",
          },
        });
        setLoading(false);
        navigate("/Categories");
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          Swal.fire({
            icon: "error", // Use "success" for the icon
            title: "Category with the same name exists",
            text: "",
            customClass: {
              // Assign your custom class to the popup container
              container: "my-custom-popup",
            },
          });
          setLoading(false);
          return;
        }
        Swal.fire({
          icon: "error", // Use "success" for the icon
          title: "Error Occured",
          text: "Unfamiliar Error Occured",
          customClass: {
            // Assign your custom class to the popup container
            container: "my-custom-popup",
          },
        });
      });
    setLoading(false);
  };

  return (
    <section className="bg-main font-poppins p-m-0">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>CaTEGORIES</h1>
                <div className="boxs-flex boxs-flex-addcategories-resp">
                  <Link
                    className="cate-icon-back p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2 boxs-flex-addcategories-resp-big"
                    to="/Categories"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <div className="add-categorie-list mt-5">
              <FontAwesomeIcon color="#fff" icon={faPencil} />
              <span className="mx-2">Add Category</span>
            </div>
            <form action="">
              <div className="grid-add d-grid align-items-center my-4">
                <label
                  className="text-light Title-font d-block text-end"
                  htmlFor=""
                >
                  Category Name
                </label>
                <input
                  className="py-1 px-2"
                  type="text"
                  value={addCategories.categoryName}
                  onChange={(e) => {
                    handleName(e.target.value);
                  }}
                />
              </div>
              <hr className="text-light my-4" />
              <div className="grid-add d-grid my-4">
                <label
                  className="text-light Title-font d-block text-end"
                  htmlFor=""
                >
                  Meta Tag Title
                </label>
                <input
                  className="py-1 px-2"
                  type="text"
                  value={addCategories.metatag}
                  onChange={(e) => {
                    handlemetatag(e.target.value);
                  }}
                />
              </div>
              <hr className="text-light my-4" />

              <div className="grid-add d-grid my-4">
                <label
                  className="text-light Title-font d-block text-end"
                  htmlFor=""
                >
                  Meta Tag Description
                </label>
                <textarea
                  rows="5"
                  className="py-1 px-2"
                  type="text"
                  value={addCategories.metadescription}
                  onChange={(e) => {
                    handlemetadescription(e.target.value);
                  }}
                ></textarea>
              </div>
              <hr className="text-light my-4" />

              <div className="grid-add d-grid my-4">
                <label
                  className="text-light Title-font d-block text-end"
                  htmlFor=""
                >
                  Meta Tag Keyword
                </label>
                <textarea
                  rows="5"
                  className="py-1 px-2"
                  type="text"
                  value={addCategories.metakeyword}
                  onChange={(e) => {
                    handlemetakeyword(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="grid-add d-grid my-4">
                <label htmlFor=""></label>
                <input
                  className="fw-bold add-save-btn Title-font rounded-3 border-0 ms-auto"
                  type="submit"
                  value="Save"
                  onClick={loading ? null : handleSubmit}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
