import React from "react";
import TryOnImg from "../assets/img/try-on-img.png";
import boxInnerParticle from "../assets/img/box-inner-particles.png";
import boxInnerParticle1 from "../assets/img/box-inner-particles1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Exquisite() {
  return (
    <section className="EXQUISITE bg-black font-bebas py-5">
      <div className="position-relative">
        <span className="bg-particle"></span>
        <div className="container-1640 position-relative z-2">
          <div className="w-75 p-4 bg-theme-box mx-auto my-5 position-relative boxShadow-TryBox">
            <img
              className="position-absolute start-0 top-0 w-100 boxInnerParticle"
              src={boxInnerParticle}
              alt=""
            />
            <img
              className="position-absolute  end-0 bottom-0 w-100 boxInnerParticle"
              src={boxInnerParticle1}
              alt=""
            />
            <div className="border-golden d-flex flex-md-row flex-column-reverse justify-content-between align-items-center p-4">
              <span className="position-absolute shadowBorder top-23"></span>
              <span className="position-absolute shadowBorder bottom-26 start-50"></span>
              <div className="content-try-on text-lg-center text-md-start text-center">
                <span className="text-danger font-poppins fw-bold fs-4">
                  LIVE <FontAwesomeIcon icon={faCamera} />
                </span>
                <h1 className="theme-text-color fs-70">Try On Feature</h1>
                <p className="m-0 theme-text-yellow-light font-poppins w-75 mx-auto try-on-para-resp text-md-start text-lg-center">
                  Test limitless shades with virtual try-on and efficiently
                  lag-free face tracking in real time.
                </p>
                <p className=" theme-text-yellow-light font-poppins w-75 mx-auto try-on-para-resp text-md-start text-lg-center">
                  Discover our virtual makeup application tools and find your
                  best products for free. You can channel your inner makeup
                  artist with the complete look try-on feature.
                </p>
                <div className="position-relative d-flex justify-content-lg-center justify-content-md-start justify-content-center">
                  <Link className="btn-master-home-page" to="/TryOnPage">
                    Try On
                  </Link>
                </div>
              </div>
              <div>
                <img className="try-on-img-resp" src={TryOnImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Exquisite;
