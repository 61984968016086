import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";

import { CartContext } from "../Components/Cart";
import { useContext } from "react";
import { baseUrl } from "../assets/Utils/Ip";
import axios from "axios";
import Stripe from "./Stripe";
import Swal from "sweetalert2";
function Checkout() {
  const { cartItems, getCartTotal } = useContext(CartContext);

  const [isInputVisible, setInputVisible] = useState(true);
  const [promocode, setPromocode] = useState();
  const [same, setSame] = useState(false);

  const [selectedShipping, setSelectedShipping] = useState(0);

  const [shipping, setShipping] = useState([]);

  const [amount, setAmount] = useState(getCartTotal());

  const [discount, setDiscount] = useState("£0");

  const [payment, setPayment] = useState(false);
  const [pay, setPay] = useState(true);

  const [checkout, setCheckout] = useState({
    fullname: "",
    email: "",
    billing: "",
    instruction: "",
    contact: "",
    shipping_address: "",
    state: "",
    city: "",
    post: "",
    shipping_method: 0,
    promo: "",
  });

  const totalAmount = getCartTotal();

  const productDataArray = [];

  let regex;

  const handlepromosubmit = (e) => {
    e.preventDefault();

    let data = {
      promo: promocode,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/verify-promo`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.orders;

          if (parseFloat(data.quantity) <= 0) {
            Swal.fire({
              icon: "error",
              title: "Promo code expired",
              text: "This promo code has been expired",
            });
            return;
          }

          if (response.data.orders.status === "percentage") {
            let value = parseFloat(data.discount_unit);

            value = totalAmount * (value / 100);

            value = totalAmount - value;

            setAmount(value + selectedShipping);

            setDiscount(data.discount_unit + "%");
          } else if (response.data.orders.status === "price") {
            setDiscount("£" + data.discount_unit);

            let value = parseFloat(data.discount_unit);

            value = totalAmount - value;

            if (value <= 0) {
              value = 0;
            }

            setAmount(value + selectedShipping);
          }
          setCheckout({ ...checkout, promo: promocode });
          Swal.fire({
            icon: "success",
            title: "Promo code verified",
            text: "Discount has been added",
          });
        }

        // You can perform any additional actions with the response here.
      })

      .catch((error) => {
        if (error.response.status === 404) {
          Swal.fire({
            icon: "error",
            title: "Promo code not verified",
            text: "Invalid Promo Code",
          });
        } else if (error.response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Promo code has been expired",
            text: "Invalid Promo Code",
          });
        }
      });
  };

  const handleShipping = (id) => {
    const item = shipping.find((item) => item.shipping_id == id);

    setCheckout({ ...checkout, shipping_method: item.shipping_name });

    
      const newShippingCharges = parseFloat(item.shipping_charges);

      setAmount(amount - selectedShipping + newShippingCharges);
      setSelectedShipping(newShippingCharges);
    
  };

  const handleName = (value) => {
    regex = new RegExp("^[a-zA-Z\\s]+$");

    if (value === "") {
      setCheckout({ ...checkout, fullname: "" });
    } else if (value.length > 90) {
      return;
    } else if (regex.test(value)) {
      setCheckout({ ...checkout, fullname: value });
    }
  };

  const handleEmail = (value) => {
    if (value === "") {
      setCheckout({ ...checkout, email: "" });
    } else if (value.length > 100) {
      return;
    } else {
      setCheckout({ ...checkout, email: value });
    }
  };

  const handleBilling = (value) => {
    if (value.length > 255) {
      return;
    }
    setCheckout((prevCheckout) => ({
      ...prevCheckout,
      billing: value,
      shipping_address: same ? value : prevCheckout.shipping_address,
    }));
  };

  const handleShippingAddress = (value) => {
    if (value.length > 255 || same) {
      return;
    }
    setCheckout((prevCheckout) => ({
      ...prevCheckout,
      shipping_address: value,
    }));
  };

  const handleInstructions = (value) => {
    if (value === "") {
      setCheckout({ ...checkout, instruction: "" });
    } else if (value.length > 255) {
      return;
    } else {
      setCheckout({ ...checkout, instruction: value });
    }
  };

  const handleContact = (value) => {
    // regex = new RegExp("^\\+?[0-9\\s.-]+$");
    regex = new RegExp("^\\+?\\d+(\\.\\d+)?$");

    if (value === "") {
      setCheckout({ ...checkout, contact: "" });
    } else if (value.length > 15) {
      return;
    } else if (regex.test(value)) {
      setCheckout({ ...checkout, contact: value });
    }
  };

  const handleSameBilling = (value) => {
    setSame(value);
    if (value) {
      setCheckout({ ...checkout, shipping_address: checkout.billing });
    }
  };

  const handlePost = (value) => {
    if (value.length > 25) {
      return;
    } else if (value === "") {
      setCheckout({ ...checkout, post: "" });
    } else {
      setCheckout({ ...checkout, post: value });
    }
  };

  const handleCountry = (value) => {
    if (value.length > 25) {
      return;
    } else {
      setCheckout({ ...checkout, state: value });
    }
  };

  const handleCity = (value) => {
    if (value.length > 50) {
      return;
    } else {
      setCheckout({ ...checkout, city: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all required fields are filled
    if (
      !checkout.fullname ||
      !checkout.email ||
      !checkout.billing ||
      !checkout.contact ||
      !checkout.shipping_address ||
      !checkout.state ||
      !checkout.city ||
      !checkout.post ||
      checkout.shipping_method === 0
    ) {
      // Display an error message or handle it as per your requirement
      Swal.fire({
        icon: "error", // Use "success" for the icon
        title: "All fields are required",
        text: "",
        customClass: {
          // Assign your custom class to the popup container
          container: "my-custom-popup",
        },
      });
      return;
    } else if (checkout.post.length < 6 || checkout.post.length > 8) {
      Swal.fire({
        icon: "error", // Use "success" for the icon
        title: "Postal code should be within 6-8",
        text: "",
        customClass: {
          // Assign your custom class to the popup container
          container: "my-custom-popup",
        },
      });
      return;
    }

    setPayment(true);
  };

  const toggleInputVisibility = () => {
    setInputVisible(!isInputVisible);
  };

  const fetch = async () => {
    try {
      let res = await axios.get(`${baseUrl}/get-all-shipping`);

      setShipping(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (payment) {
      setPayment(false);
    }
  }, [checkout]);

  return (
    <div>
      <Header />

      <div className="container-1640">
        <div className="toplinecon shiphum"></div>

        <section className="shippingsection">
          <form onSubmit={handleSubmit}>
            <h1 className="theme-text-color carttitle">Checkout</h1>
            <div className="row margin-0-auto-mob">
              <div className="col-lg-4">
                <div className="font-poppins position-relative z-10">
                  <div className="my-3">
                    <label className="theme-text-yellow-light my-1" htmlFor="">
                      Full Name*
                    </label>
                    <input
                      type="text"
                      className="border-1 p-2 w-100 mx-auto border-golden placeholder-color bg-transparent theme-text-yellow-light d-block"
                      value={checkout.fullname}
                      onChange={(e) => handleName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="my-3">
                    <label className="theme-text-yellow-light my-1" htmlFor="">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="border-1 p-2 w-100 mx-auto border-golden placeholder-color bg-transparent theme-text-yellow-light d-block"
                      value={checkout.email}
                      onChange={(e) => handleEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="my-3">
                    <label className="theme-text-yellow-light my-1" htmlFor="">
                      Billing Address
                    </label>
                    <input
                      type="text"
                      className="border-1 p-2 w-100 mx-auto border-golden placeholder-color bg-transparent theme-text-yellow-light d-block"
                      value={checkout.billing}
                      onChange={(e) => handleBilling(e.target.value)}
                      required
                    />
                  </div>
                  <div className="my-3">
                    <label className="theme-text-yellow-light my-1" htmlFor="">
                      Special Instruction ( Optional )
                    </label>
                    <textarea
                      rows="6"
                      className="border-1 p-2 w-100 mx-auto border-golden placeholder-color bg-transparent theme-text-yellow-light d-block"
                      value={checkout.instruction}
                      onChange={(e) => handleInstructions(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <label className="theme-text-yellow-light my-1" htmlFor="">
                      Promo Code
                    </label>
                    <div className="form-check form-switch py-2">
                      <input
                        className="form-check-input w-15 h-30px"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        onChange={toggleInputVisibility}
                      />
                    </div>
                    {!isInputVisible && (
                      <div className="py-4 text-end">
                        <input
                          className="border-1 text-start p-2 w-100 mx-auto border-golden placeholder-color bg-transparent theme-text-yellow-light d-block"
                          type="text"
                          value={promocode}
                          onChange={(e) => setPromocode(e.target.value)}
                          required
                        />
                        <button
                          className="px-5 mt-3 rounded border-0 btn-gradient font-bebas fs-4  "
                          onClick={handlepromosubmit}
                        >
                          Apply
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="font-poppins position-relative z-10 d-flex flex-column justify-content-between">
                  <div>
                    <div className="my-3">
                      <label
                        className="theme-text-yellow-light my-1"
                        htmlFor=""
                      >
                        Contact*
                      </label>
                      <input
                        type="text"
                        className="border-1 p-2 w-100 mx-auto border-golden placeholder-color bg-transparent theme-text-yellow-light d-block"
                        value={checkout.contact}
                        onChange={(e) => handleContact(e.target.value)}
                        required
                      />
                    </div>

                    <div className="my-3">
                      <label
                        className="theme-text-yellow-light my-1 d-flex align-items-center gap-3"
                        htmlFor=""
                      >
                        Shipping Address{" "}
                        <div className="d-flex align-items-center gap-1">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              handleSameBilling(e.target.checked)
                            }
                          />
                          <span className="fs-9">same as billing address</span>
                        </div>
                      </label>
                      <input
                        type="text"
                        className="border-1 p-2 w-100 mx-auto border-golden placeholder-color bg-transparent theme-text-yellow-light d-block"
                        value={checkout.shipping_address}
                        required
                        onChange={(e) => {
                          if (same) {
                            return;
                          }
                          handleShippingAddress(e.target.value);
                        }}
                      />
                    </div>
                    <div className="my-3">
                      <label
                        className="theme-text-yellow-light my-1"
                        htmlFor=""
                      >
                        Country*
                      </label>
                      <input
                        type="text"
                        className="border-1 p-2 w-100 mx-auto border-golden placeholder-color bg-transparent theme-text-yellow-light d-block"
                        value={checkout.state}
                        required
                        onChange={(e) => {
                          handleCountry(e.target.value);
                        }}
                      />
                    </div>
                    <div className="my-3">
                      <label
                        className="theme-text-yellow-light my-1"
                        htmlFor=""
                      >
                        City*
                      </label>
                      <input
                        type="text"
                        className="border-1 p-2 w-100 mx-auto border-golden placeholder-color bg-transparent theme-text-yellow-light d-block"
                        value={checkout.city}
                        required
                        onChange={(e) => {
                          handleCity(e.target.value);
                        }}
                      />
                    </div>
                    <div className="my-3">
                      <label
                        className="theme-text-yellow-light my-1"
                        htmlFor=""
                      >
                        Post Code
                      </label>
                      <input
                        type="text"
                        className="border-1 p-2 w-100 mx-auto border-golden placeholder-color bg-transparent theme-text-yellow-light d-block"
                        value={checkout.post}
                        onChange={(e) => handlePost(e.target.value)}
                        required
                      />
                    </div>

                    <div className="mt-lg-5 mt-3 mb-lg-0 mb-4">
                      <label
                        className="theme-text-yellow-light my-1"
                        htmlFor=""
                      >
                        Shipping Method
                      </label>
                      <select
                        class="option-cust border-1 p-2 w-100 mx-auto border-golden placeholder-color bg-transparent theme-text-yellow-light d-block"
                        aria-label="example"
                        value={selectedShipping?.shipping_id}
                        onChange={(e) => handleShipping(e.target.value)}
                        required
                      >
                        <option value={0} selected disabled>
                          Select shipping method
                        </option>
                        {shipping?.map((item) => (
                          <option
                            value={item.shipping_id}
                            key={item.shipping_id}
                          >
                            {item.shipping_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <h1 className="text-center font-bebas theme-text-color mb-4">
                  Order Summary
                </h1>
                <div className="font-poppins parent-grids-modal-1 pt-4">
                  <div className="product-detail-grid-web font-bebas">
                    <div className="prdct-detail-txt">
                      <h2 className="theme-text-color">PRODUCT</h2>
                    </div>
                    <div className="prdct-detail-txt">
                      <h2 className="theme-text-color text-lg-start text-center">
                        QTY
                      </h2>
                    </div>
                    <div className="prdct-detail-txt text-end">
                      <h2 className="theme-text-color">TOTAL PRICE</h2>
                    </div>
                  </div>
                  <div className="prdct-dtl-line">
                    <hr />
                  </div>
                  {cartItems?.map((item) => {
                    const productData = {
                      product_id: item.product_id,
                      quantity: item.quantity,
                      price: item.sale_price,
                    };

                    productDataArray.push(productData);

                    return (
                      <div className="product-detail-grid-web set-grid">
                        <div className="prdct-detail-img">
                          <div className="prdct-txt-nxt-grid ">
                            <img src={`${baseUrl}/${item.main_image}`} alt="" />
                            <div>
                              <h2 className="theme-text-color">
                                {" "}
                                {item.product_name}
                              </h2>
                              <p className="fs-7 theme-text-yellow-light">
                                {item.product_description}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="theme-text-yellow-light prdct-detail-txt set-detail-txt font-bebas text-lg-start text-center">
                          <h2>{item.p_quantity}</h2>
                        </div>
                        <div className="theme-text-yellow-light prdct-detail-txt set-detail-price font-bebas">
                          <h2>
                            £
                            {(parseFloat(item.price) * item.p_quantity).toFixed(
                              2
                            )}
                          </h2>
                        </div>
                      </div>
                    );
                  })}
                  <div className="prdct-dtl-line font-bebas">
                    <hr />
                  </div>
                  <div className="theme-text-yellow-light prdct-sub-total-flex font-bebas sub-total-pd">
                    <h2>Subtotal :</h2>
                    <h3>£{parseFloat(getCartTotal()).toFixed(2)}</h3>
                  </div>
                  <div className="theme-text-yellow-light prdct-sub-total-flex set-sec-flex font-bebas">
                    <h2>SHIPPING :</h2>
                    <h3>£{selectedShipping}</h3>
                  </div>
                  <div className="theme-text-yellow-light prdct-sub-total-flex font-bebas">
                    <h2>Discount :</h2>
                    <h3>{discount}</h3>
                  </div>
                  <div className="theme-text-yellow-light prdct-sub-total-line font-bebas">
                    <hr />
                  </div>
                  <div className="theme-text-yellow-light prdct-sub-total-flex set-th-flex font-bebas">
                    <h2>total :</h2>
                    <h3>£ {parseFloat(amount).toFixed(2)}</h3>
                  </div>
                </div>

                {pay ? (
                  payment ? (
                    <Stripe
                      setPay={setPay}
                      totalAmount={amount}
                      data={checkout}
                    />
                  ) : (
                    <button
                      type="submit"
                      className="font-bebas btn-gradient border-0 rounded px-lg-5 px-4 ms-auto mt-lg-5 mt-3 d-table text-black proceed-top-pay-btn-resp"
                    >
                      Proceed To Payment
                    </button>
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </form>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Checkout;
