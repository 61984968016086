import React from "react";

import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faChartColumn,
  faDownload,
  faReply,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../Component/Sidebar";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../assets/Utils/Ip";
import { useState } from "react";

export default function Report() {
  const [values, setValues] = useState([]);
  const [type, setType] = useState("1");

  const token = localStorage.getItem("root");

  const location = useLocation()

  const fetch = async () => {
    try {
      let res;
      if (type === "1") {
        res = await axios.get(`${baseUrl}/complete-order`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else if (type === "2") {
        res = await axios.get(`${baseUrl}/get-all-cancled-order`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else if (type === "3") {
        res = await axios.get(`${baseUrl}/get-all-pending-order`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      setValues(res.data);
    } catch (err) {}
  };

  useEffect(() => {


    fetch();
  }, [type]);

  useEffect(()=>{
    if(location?.state)
      {
        setType(location?.state)
      }
  }, [])

  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>REPORTS</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/Sale"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <div className="mt-5">
              <h5 className="bg-golden-gradeint p-2 m-0">
                <FontAwesomeIcon icon={faChartColumn} /> Choose the report type
              </h5>
              <div className="p-4 blur-white">
                <select
                  className="w-100 p-2"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value={1}>Total Sales</option>
                  <option value={2}>Cancelled Orders</option>
                  <option value={3}>Pending Orders</option>
                </select>
              </div>
            </div>
            <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                <div className="mt-5">
                  <h5 className="bg-golden-gradeint p-2 m-0 d-flex justify-content-between">
                    <p>
                      <FontAwesomeIcon icon={faChartColumn} />
                      {type === "1"
                        ? "Total Sales"
                        : type === "2"
                        ? "Cancelled Orders"
                        : type === "3"
                        ? "Pending Orders"
                        : ""}
                    </p>
                    {/* <div className="reports-table-reports-sec text-end d-flex">
                  <p className=" me-4">Date Range</p>
                  <span className="cursor-pointer rel-two-span position-relative ">
                    <FontAwesomeIcon
                      color="#A67D3F"
                      className="cursor-pointer position-absolute end-0 bg-light p-1 px-3 rounded "
                      icon={faCalendar}
                    />
                    <input className="date-inp" type="date" />
                  </span>
                  <span className="cursor-pointer rel-two-span position-relative ms-2">
                    <FontAwesomeIcon
                      color="#A67D3F"
                      className="cursor-pointer position-absolute end-0 bg-light p-1 px-3 rounded "
                      icon={faCalendar}
                    />
                    <input className="date-inp" type="date" />
                  </span>
                </div> */}
                  </h5>
                  <div className="blur-white border ">
                    <div className="grid-report border">
                      <div className="border p-2 text-center">
                        <p>S. No.</p>
                      </div>
                      <div className="border  p-2">
                        <p>Customer Name</p>
                      </div>
                      <div className="border  p-2">
                        <p>Product Count</p>
                      </div>
                      <div className="border  p-2">
                        <p>Total Price</p>
                      </div>
                      <div className="  p-2">
                        <p>Quantity</p>
                      </div>
                    </div>
                    {values?.map((item, index) => (
                      <div className="grid-report border">
                        <div className="border p-2 text-center">
                          <p>{index + 1}</p>
                        </div>
                        <div className="border  p-2">
                          <p>{item.full_name}</p>
                        </div>
                        <div className="border  p-2">
                          <p>{item.product_count}</p>
                        </div>
                        <div className="border  p-2">
                          <p>{item.total_amount}</p>
                        </div>
                        <div className="  p-2">
                          <p>{item.quantity}</p>
                        </div>
                      </div>
                    ))}

                    {/* <div className="d-grid grid-no-result p-2 text-center text-light">
                  <p>No Result</p>
                  <p>
                    <FontAwesomeIcon className="fs-4" icon={faDownload} />
                  </p>
                </div> */}
                  </div>
                  {/* <p style={{ color: "white", textAlign: "end" }} className="py-4">
                Showing 0 to 0 of 0 (0 Pages)
              </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
