import React from "react";
import Header from "../Components/Header/Header";
import Sponser from "../Components/Sponser";
import Partner from "../Components/Partner";
import Footer from "../Components/Footer/Footer";
import AboutTestimonial from "../Components/AboutTestimonial";
import Subscribe from "../Components/Subscribe";
import Loader from "../Components/Loader";
import AboutPackaging from "../assets/img/AboutPackaging.png";
import AboutCustomization from "../assets/img/AboutCustomization.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutPageTestimonial from "../Components/AboutUsPageTestimonial";

function About(props) {
  return (
    <div>
      <Loader />

      {/* <Warning /> */}
      <div className="overlayabout">
        <div className="about-hover">
          <Header />
        </div>
        <Partner addNone="d-none" />
        <section className="home-offer-section font-bebas">
          <div className="container-1640">
            <div className="row">
              <div className="col-lg-12 my-4 px-md-4">
                <div className="bg-theme-box p-md-3 p-2 about-page-box-resp">
                  <div className="border-golden d-flex justify-content-between align-items-center flex-lg-row flex-column-reverse p-4">
                    <div className="text-lg-start text-md-center mt-lg-0 mt-3">
                      <h1 className="theme-text-color fs-70">CUSTOMIZATION</h1>
                      <p className="m-0 font-poppins theme-text-yellow-light para-resp-about">
                        Designed for your tone, finish, coverage, and every
                        other need. The customization options are through
                        personality tests and skin analysis. We maintain
                        personalisation by building a relationship between the
                        brand and the consumer. The brand understands the
                        superior level of service for engaged beauty consumers
                        making it accessible to them. Our customised products
                        will always be responsive to consumer needs considering
                        their beauty needs.
                      </p>
                    </div>
                    <div>
                      <img
                        className="about-custom-img-resp"
                        src={AboutCustomization}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 my-4 px-md-4">
                <div className="bg-theme-box p-md-3 p-2 about-page-box-resp">
                  <div className="border-golden d-flex justify-content-between align-items-center flex-lg-row flex-column p-4">
                    <img
                      className="about-custom-img-resp"
                      src={AboutPackaging}
                      alt=""
                    />

                    <div className="text-lg-start text-md-center ms-md-3 mt-lg-0 mt-3">
                      <h1 className="theme-text-color fs-70">PACKAGING</h1>
                      <p className="m-0 font-poppins theme-text-yellow-light para-resp-about">
                        Modern, sophisticated yet minimalist packaging. We
                        believe in the safe delivery of products to the consumer
                        at the minimum overall cost. Bold colors, unique shapes,
                        and well-designed boxes will surely make our packaging
                        stand out. Extremes of cold can cause marked changes in
                        the consistency of cosmetics so we use suitable
                        materials for the packaging. It also protects from
                        discoloration and builds brand recognition in the
                        market.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <h1 className="text-center theme-body-bg m-0 py-lg-4 theme-text-color font-bebas fs-70">
          What Sets us Apart?
        </h1>
        <Sponser />
        <AboutPageTestimonial />
        <Subscribe />
        <Footer />
      </div>
    </div>
  );
}

export default About;
