import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Sponser1 from "../assets/img/sponser-one.png";
import Sponser2 from "../assets/img/sponser-two.png";
import Sponser3 from "../assets/img/sponser-three.png";
import Sponser4 from "../assets/img/sponser-four.png";
import Sponser5 from "../assets/img/sponser-five.png";
import Sponser6 from "../assets/img/sponser-six.png";
import Sponser7 from "../assets/img/sponser-seven.png";
function Sponser() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    slidesToScroll: 1,
    arrows: false,
    autoplay: true, // Enable auto sliding
    autoplaySpeed: 2000, // Set the time interval in milliseconds (e.g., 2000ms = 2 seconds)
  };
  return (
    <section className="sec-sponser popularproduct">
      <div className="container-1640 z-10">
        <div className="row prnt-prdt-card-row">
          <div className="col-lg-12">
            <Slider {...settings} className="set-slider">
              <div className="sponser-img w-75">
                <img className="w-100" src={Sponser1} alt="" />
              </div>
              <div className="sponser-img w-75">
                <img className="w-100" src={Sponser2} alt="" />
              </div>
              <div className="sponser-img w-75">
                <img className="w-100" src={Sponser3} alt="" />
              </div>
              <div className="sponser-img w-75">
                <img className="w-100" src={Sponser4} alt="" />
              </div>
              <div className="sponser-img w-75">
                <img className="w-100" src={Sponser5} alt="" />
              </div>
              <div className="sponser-img w-75">
                <img className="w-100" src={Sponser6} alt="" />
              </div>
              <div className="sponser-img w-75">
                <img className="w-100" src={Sponser7} alt="" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sponser;
