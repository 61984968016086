import React, { useEffect, useState } from "react";

import Graph from "../Component/Graph";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faChartSimple,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleDot } from "@fortawesome/free-regular-svg-icons";
import Sidebar from "../Component/Sidebar";
import axios from "axios";
import { baseUrl } from "../../assets/Utils/Ip";

export default function DashboardAdmin() {
  const token = localStorage.getItem("root");

  const [orderCount, setOrdercount] = useState(0);
  const [pendingOrder, setPending] = useState(0);
  const [cancelledOrder, setCancelledOrder] = useState(0);
  const [totalSales, setTotalSales] = useState(0);

  const [orders, setOrders] = useState([]);

  const navigate = useNavigate()

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can use "auto" for instant scrolling
    });
  };

  const extractChartData = (data, type) => {
    const chartData = {
      labels: [],
      datasets: [
        {
          label: "Order",
          data: [],
          backgroundColor: "rgb(247, 240, 159)",
        },
      ],
    };

    // Extract and set data based on type (yearly or monthly)
    data.forEach((item) => {
      const date = new Date(item.created_at);
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();

      if (type === "yearly") {
        if (!chartData.labels.includes(year)) {
          chartData.labels.push(year);
          chartData.datasets[0].data.push(1);
        } else {
          const index = chartData.labels.indexOf(year);
          chartData.datasets[0].data[index]++;
        }
      } else if (type === "monthly") {
        const label = `${month} ${year}`;

        if (!chartData.labels.includes(label)) {
          chartData.labels.push(label);
          chartData.datasets[0].data.push(1);
        } else {
          const index = chartData.labels.indexOf(label);
          chartData.datasets[0].data[index]++;
        }
      }
    });

    return chartData;
  };

  const yearlyChartData = extractChartData(orders, "yearly");
  const monthlyChartData = extractChartData(orders, "monthly");

  function formatDate(timestamp) {
    const dateObj = new Date(timestamp);

    const day = dateObj.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      dateObj
    );
    const year = dateObj.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const fetch = async () => {
    try {
      let res = await axios.get(`${baseUrl}/total-order`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        setOrdercount(res.data.total_order_count || 0);
      }

      res = await axios.get(`${baseUrl}/pending-order`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        setPending(res.data.pending_order_count || 0);
      }

      res = await axios.get(`${baseUrl}/cancelled-order`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        setCancelledOrder(res.data.cancelled_order_count || 0);
      }

      res = await axios.get(`${baseUrl}/total-sales`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        setTotalSales(res.data.total_sales || 0);
      }

      res = await axios.get(`${baseUrl}/get-all-orders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        setOrders(res.data.orders);
      }
    } catch (err) {}
  };

  const handleNavigate = (type) => {
    navigate("/report", {
      state: type
    })
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent ">
          <div className="sec-side-fi-text justify-content-center border-text">
            <h1 className="text-light text-center display-2">Dashboard</h1>
            <hr className="text-light opacity-100" />
          </div>
          <div className="main-without-heading p-5">
            <div className="parent-boxes-flex row">
              <div className="col-lg-3 p-3">
                <div className="parent-box-img">
                  <div className="text-center pt-2">
                    <FontAwesomeIcon
                      color="#E9AF40"
                      fontSize="70px"
                      icon={faChartSimple}
                    />
                  </div>
                  <p className="text-center text-light fs-4 pt-3 m-0">
                    Total Sales
                  </p>
                  <h1 className="textyellow-color  fs-70 text-center">
                    £{parseInt(totalSales)}
                  </h1>
                  <div class="text-center mt-4 ">
                    <Link
                      className="py-2 btn-gradient d-table w-75 mx-auto rounded-pill text-dark fw-bold font-bebas"
                      to="/Sale"
                    >
                      View More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 p-3">
                <div className="parent-box-img">
                  <div className="text-center pt-2">
                    <FontAwesomeIcon
                      color="#E9AF40"
                      fontSize="70px"
                      icon={faCartShopping}
                    />
                  </div>
                  <p className="text-center text-light fs-4 pt-3 m-0">
                    Cancelled Orders
                  </p>
                  <h1 className="textyellow-color fs-70 text-center">
                    {cancelledOrder}
                  </h1>
                  <div class="text-center mt-4 ">
                    <button
                      className="py-2 btn-gradient d-table w-75 mx-auto rounded-pill text-dark fw-bold font-bebas"
                      onClick={()=>handleNavigate(2)}
                    >
                      View More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 p-3">
                <div className="parent-box-img">
                  <div className="text-center pt-2">
                    <FontAwesomeIcon
                      color="#E9AF40"
                      fontSize="70px"
                      icon={faCartShopping}
                    />
                  </div>
                  <p className="text-center text-light fs-4 pt-3 m-0">
                    Pending Orders
                  </p>
                  <h1 className="textyellow-color fs-70 text-center">
                    {pendingOrder}
                  </h1>
                  <div class="text-center mt-4 ">
                    <button
                      className="py-2 btn-gradient d-table w-75 mx-auto rounded-pill text-dark fw-bold font-bebas"
                      onClick={()=>handleNavigate(3)}
                    >
                      View More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 p-3">
                <div className="parent-box-img">
                  <div className="text-center pt-2">
                    <FontAwesomeIcon
                      color="#E9AF40"
                      fontSize="70px"
                      icon={faCartShopping}
                    />
                  </div>
                  <p className="text-center text-light fs-4 pt-3 m-0">
                    Total Orders
                  </p>
                  <h1 className="textyellow-color fs-70 text-center">
                    {orderCount}
                  </h1>
                  <div class="text-center mt-4 ">
                    <button
                      className="py-2 btn-gradient d-table w-75 mx-auto rounded-pill text-dark fw-bold font-bebas"
                      onClick={()=>handleNavigate(1)}
                    >
                      View More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="graph-parent-flex row">
              <div className="col-lg-6">
                <div className="graph my-4">
                  <Graph data={yearlyChartData} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="graph my-4">
                  <Graph data={monthlyChartData} />
                </div>
              </div>
            </div>
            <div className="latest-order-box-parent">
              <div className="latest-order-flex">
                <FontAwesomeIcon
                  color="#E9AF40"
                  fontSize="20px"
                  icon={faCartShopping}
                />
                <span>Latest Orders</span>
              </div>
              <div className="main-fi-order-box-master">
                <div className="main-fi-order-box">
                  <div className="fi-order-box">
                    <ul className="order-list">
                      <li>Order ID</li>
                      <li>Customer</li>
                      <li>Status</li>
                      <li>Date Added</li>
                      <li>Total</li>
                      <li>Action</li>
                    </ul>
                  </div>
                  {orders?.slice(0, 5).map((item) => (
                    <div className="sec-order-box py-4 border my-4 rounded rounded-3">
                      <ul className="sec-order-list text-light">
                        <li>#{item.order_id}</li>
                        <li>{item.users}</li>
                        <li className="set-color-icn">
                          <FontAwesomeIcon
                            className={`me-1 text-${
                              item.order_status === "cancelled"
                                ? "danger"
                                : item.order_status === "complete" || item.order_status === "Complete"
                                ? "success"
                                : item.order_status === "pending"
                                ? "warning"
                                : ""
                            }`}
                            icon={faCircleDot}
                          />
                          {item.order_status.toUpperCase()}
                        </li>
                        <li>{formatDate(item.created_at)}</li>
                        <li>£{parseFloat(item.total_amount).toFixed(2)}</li>
                        <li>
                          <Link
                            to={`/Saleview/${item.order_id}`}
                            onClick={scrollToTop}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
