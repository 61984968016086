import React from "react";
import TryOnImg from "../assets/img/offer-img-one.png";
import TryOntwo from "../assets/img/offer-img-two.png";
import OfferThdImg from "../assets/img/offer-third-img.png";
import CartImgOne from "../assets/img/Cart-Img-One.png";
import CartImgTwo from "../assets/img/Cart-Img-two.png";
import CartImgThree from "../assets/img/Cart-Img-Three.png";
import { Link } from "react-router-dom";

function Offers() {
  return (
    <>
      <section className="home-offer-section font-bebas">
        <div className="container-1640">
          <div className="row">
            <div className="col-lg-6 px-4">
              <div
                className="bg-theme-box p-3"
                style={{ position: "relative" }}
              >
                <div className="shawdow-box-brdr-offer"></div>
                <div
                  className="border-golden d-flex justify-content-between align-items-center p-lg-4 p-md-4 p-2"
                  style={{ position: "relative" }}
                >
                  <div className="offer-content-on text-start">
                    <h1>BIG DISCOUNT</h1>
                    <h2>ON MAKEUP WITH SKIN CARE</h2>
                    <Link
                      className="btn-master-home-page btn-master-home-page-offer"
                      to="/Shop"
                    >
                      Shop Now
                    </Link>
                  </div>
                  <div className="w-40">
                    <img className="w-100" src={TryOnImg} alt="" />
                  </div>
                </div>
                <div className="shawdow-box-brdr-offer-bottom"></div>
              </div>
            </div>
            <div className="col-lg-6 px-4">
              <div
                className="bg-theme-box p-3"
                style={{ position: "relative" }}
              >
                <div className="shawdow-box-brdr-offer"></div>
                <div
                  className="border-golden d-flex justify-content-between align-items-center p-lg-4 p-md-4 p-2"
                  style={{ position: "relative" }}
                >
                  <div className="offer-content-on text-start">
                    <h1>BEAUTY CARE</h1>
                    <h2>PERSONLISED HALAL MAKEUP</h2>
                    <Link
                      className="btn-master-home-page btn-master-home-page-offer"
                      to="/Shop"
                    >
                      Shop Now
                    </Link>
                  </div>
                  <div className="w-40">
                    <img className="w-100" src={TryOntwo} alt="" />
                  </div>
                </div>
                <div className="shawdow-box-brdr-offer-bottom"></div>
              </div>
            </div>
            <div className="col-lg-12 my-4 px-4">
              <div
                className="bg-theme-box p-3"
                style={{ position: "relative" }}
              >
                <div className="shawdow-sec-brdr-offer"></div>
                <div
                  className="border-golden d-flex justify-content-between align-items-center p-lg-4 p-md-4 p-2 flex-column-reverse flex-lg-row"
                  style={{ position: "relative" }}
                >
                  <div className="offer-content-on sec-offer-content-on text-md-start text-center mt-md-0 mt-2">
                    <h1>STUNNING LOOKS</h1>
                    <h2>TO MAKE AN IMPRESSION</h2>
                    <p className="m-0 font-poppins">
                      Feel confident in your skin and create trendy and gorgeous
                      <br className="d-md-block d-none" />
                      looks with our high- performing customised halal makeup.
                    </p>
                    <Link
                      className="btn-master-home-page btn-master-home-page-offer mobile-mx-auto"
                      to="/Shop"
                    >
                      Shop Now
                    </Link>
                  </div>
                  <div className="resp-tab-w-100 w-50 mb-lg-0 mb-md-3">
                    <img className="w-100" src={OfferThdImg} alt="" />
                  </div>
                </div>
                <div className="shawdow-sec-brdr-offer-bottom"></div>
              </div>
            </div>
            {/* <div className="col-lg-4 px-4">
              <div className="prnt-cart-box px-4 pt-4 pb-5 position-relative">
                <img className="mb-3" src={CartImgOne} alt="" />
                <h3 className="pb-2">Let's Get Bolder With Makeup Looks</h3>
                <p className="font-poppins pb-3">
                  Our personalised halal makeup UK collection includes go-to
                  liquid eyeliner, brightest blush, and perfect lip colour to
                  achieve your desirable look. Play up with your eyes or lips
                  ...
                </p>
                <Link
                  className="position-absolute mt-4 font-poppins btn-gradient text-dark rounded px-4 mt-5 banner-btn-resp"
                  to="/Blog"
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="col-lg-4 px-4">
              <div className="prnt-cart-box px-4 pt-4 pb-5 mx-auto position-relative">
                <img className="mb-3" src={CartImgTwo} alt="" />
                <h3 className="pb-2">
                  Brushes Making A Difference In Makeup Looks
                </h3>
                <p className="font-poppins pb-3">
                  Our customised halal makeup UK collection is for everyone.
                  Whether you are a professional makeup artist, a makeup
                  enthusiast ...
                </p>
                <Link
                  className="position-absolute mt-4 font-poppins btn-gradient text-dark rounded px-4 mt-5 banner-btn-resp"
                  to="/Blog"
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="col-lg-4 px-4">
              <div className="prnt-cart-box px-4 pt-4 pb-5 ms-auto position-relative">
                <img className="mb-3" src={CartImgThree} alt="" />
                <h3 className="pb-2">Achieving A Sculpted Look</h3>
                <p className="font-poppins pb-3">
                  Contouring like a pro in no time with our flawless makeup with
                  skincare UK. Carving out your face seamlessly by blending our
                  makeup ...
                </p>
                <Link
                  className="position-absolute mt-4 font-poppins btn-gradient text-dark rounded px-4 mt-5 banner-btn-resp"
                  to="/Blog"
                >
                  Read More
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Offers;
