import React from "react";
function Warning() {
  return (
    <div className="war-policies">
      <p className="warning font-poppins text-center m-0 position-relative">
        Use Promo Code <b> "NLFirst10" </b>to get a flat 10% discount.
      </p>
    </div>
  );
}

export default Warning;
