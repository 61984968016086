import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProductImg from "../../../src/assets/img/admin/product1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faListUl,
  faPencil,
  faPlus,
  faTrash,
  faReply,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../Component/Sidebar";
import axios from "axios";
import Swal from "sweetalert2";
import { baseUrl } from "../../assets/Utils/Ip";

export default function AdminProduct() {
  const token = localStorage.getItem("root");

  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [allProducts, setAllProducts] = useState();
  const [deletedId, setDeletedId] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]); // Step 1
  const [length, setLength] = useState(0);

  const fetchData = async () => {
    try {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/get-all-products`,
      };
      const response = await axios.request(config);
      setAllProducts(response.data.results);
      setLength(response.data.results.length);
    } catch (error) {}
  };

  const handleDelete = (id) => {
    // Inside the handleDelete function, place your Axios request
    const config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${baseUrl}/delete-product/${id}`, // Replace with the appropriate URL
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setAllProducts((prevProd) =>
          prevProd.filter((product) => product.product_id !== id)
        );

        Swal.fire({
          icon: "success",
          title: "Product Successfully Deleted",
          text: "",
        });
      })
      .catch((error) => {
        // Handle errors, e.g., show an error message
      });
  };

  const handleSelectAll = () => {
    setSelectedProducts(
      allProducts?.map((products) => products?.product_id)
    );
  };

  const handleDeselectAll = () => {
    setSelectedProducts([]); // Step 3
  };

  const handleBrandSelect = (productID) => {
    // Step 4
    if (selectedProducts.includes(productID)) {
      setSelectedProducts(selectedProducts.filter((id) => id !== productID));
    } else {
      setSelectedProducts([...selectedProducts, productID]);
    }
  };

  const deleteAll = () => {
    const productIdsToDelete = selectedProducts; // Replace with the product IDs you want to delete

    if (productIdsToDelete.length === 0) {
      Swal.fire({
        icon: "error", // Use "success" for the icon
        title: "Kindly select an item before deletion",
        text: "",
        customClass: {
          // Assign your custom class to the popup container
          container: "my-custom-popup",
        },
      });

      return;
    }
    axios
      .delete(`${baseUrl}/delete-product`, {
        data: { productIds: productIdsToDelete },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Product Successfully Deleted",
          text: "Thankyou",
        });
        // Handle any further actions after successful deletion.
      })
      .catch((error) => {
        // Handle errors or display an error message.
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Pagination start
  const [currentPage, setCurrentPage] = useState(1);

  // Check if allProducts is defined
  const productsExist = allProducts && allProducts.length > 0;

  // If allProducts is not defined, initialize it as an empty array
  const allProductsArray = productsExist ? allProducts : [];

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = allProductsArray.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Pagination end

  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>PRODUCTS</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 cate-icon-back justify-content-center bg-yellow w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/AddProduct"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Link>
                  <Link
                    className="p-3 cate-icon-back justify-content-center bg-yellow w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to=""
                  >
                    <FontAwesomeIcon
                      color="red"
                      icon={faTrash}
                      onClick={deleteAll}
                    />
                  </Link>
                  <Link
                    className="p-3 cate-icon-back justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/Variation"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <div className="d-flex justify-content-end mt-4">
              <Link
                className="  add-categorie-btn text-dark rounded-2 px-5 py-2 font-bebas"
                to="/AddProduct"
              >
                Add Product
              </Link>
              <Link
                className="add-categorie-btn text-dark rounded-2  px-5 py-2 font-bebas"
                to="/DeletedProducts"
                style={{ marginLeft: "10px" }}
              >
                Deleted Products
              </Link>

              <div className="dropdown drop-cust ">
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item">In Stock</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item">Out Of Stock</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <div className="add-categorie-list">
                <FontAwesomeIcon color="#fff" icon={faListUl} />
                <span className="mx-2">Product List</span>
              </div>
              <div className="pagin">
                <select
                  aria-label="Default select example"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(e.target.value)}
                >
                  <option value={5} selected>
                    5
                  </option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
              </div>
            </div>
            <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                <div className="product-table-parent">
                  <div className="product-grid-divide w-100 align-items-center rounded-3 my-3">
                    <div className="categori-name p-3">
                      <input
                        type="checkbox"
                        onChange={() =>
                          selectedProducts?.length < allProducts?.length
                            ? handleSelectAll()
                            : handleDeselectAll()
                        }
                        checked={
                          selectedProducts.length === allProducts?.length
                        }
                      />
                    </div>
                    <div className="categori-name p-3">
                      <p className="mx-3 mb-0 ">Images</p>
                    </div>
                    <div className="categori-name d-flex align-items-center p-3">
                      <p className="mx-3 mb-0 ">Product Name </p>
                      <FontAwesomeIcon icon={faChevronUp} />
                    </div>
                    <div className="categori-name border-end p-3 text-start">
                      <p className="mb-0">Category</p>
                    </div>
                    <div className="categori-name border-end p-3 text-center">
                      <p className="mb-0">Price</p>
                    </div>
                    <div className="categori-name border-end p-3 text-center">
                      <p className="mb-0">Quantity</p>
                    </div>
                    <div className="categori-name p-3 text-end border-end-0">
                      <p className="mb-0">Action</p>
                    </div>
                  </div>
                  {currentProducts?.map((product) => (
                    <div
                      key={product.id}
                      className="product-grid-divide-box w-100 align-items-center rounded-3 my-3 text-light"
                    >
                      <div className="h-100 d-flex align-items-center categori-name p-2">
                        <input
                          type="checkbox"
                          onChange={() => handleBrandSelect(product.product_id)}
                          checked={selectedProducts.includes(
                            product.product_id
                          )}
                        />
                      </div>
                      <div className="h-100 categori-name text-center p-2">
                        <img
                          className=" "
                          src={`${baseUrl}/${product.main_image}`}
                          alt=""
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <div className="h-100 categori-name justify-content-between d-flex align-items-center p-2">
                        <p className="mx-3">{product.product_name}</p>
                      </div>
                      <div className="h-100 d-flex align-items-center categori-name border-end p-2 text-start ">
                        <p>{product.category_name}</p>
                      </div>

                      <div className="h-100 categori-name border-end px-2 py-3 text-center">
                        {product.sale_price === "" ||
                        product.sale_price === "0" ? (
                          <p className="text-warning">
                            £{product.regular_price}
                          </p>
                        ) : (
                          <>
                            {" "}
                            <p className="text-decoration-line-through">
                              £{product.regular_price}
                            </p>
                            <p className="text-warning">
                              £{product.sale_price}
                            </p>{" "}
                          </>
                        )}
                      </div>
                      <div className="h-100 d-flex align-items-center categori-name border-end p-2 justify-content-center">
                        <p>{product.quantity}</p>
                      </div>
                      <div className="h-100 d-flex align-items-center justify-content-end categori-name p-2 px-3 text-end">
                        {/* Button trigger modal */}
                        <button
                          type="button"
                          className="btn bg-yellow me-2"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => setDeletedId(product.product_id)}
                        >
                          <FontAwesomeIcon color="red" icon={faTrash} />
                        </button>
                        <Link
                          to={`/EditProduct/${product.product_id}`}
                          className="btn ms-3 bg-yellow"
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </Link>
                      </div>
                    </div>
                  ))}
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="d-flex align-items-end">
                      {/* <div className="Pagination mt-4 ">
                    <div className="nmbering d-flex">
                      {generatePaginationNumbers(
                        itemsPerPage,
                        length,
                        currentPage
                      )}
                    </div>
                    <div className="linepag">
                      <span></span>
                    </div>
                  </div> */}
                      {/* <div className="left-right-arrow-pag">
                    <span
                      onClick={() => {
                        currentPage !== 1
                          ? setCurrentPage(currentPage - 1)
                          : setCurrentPage(currentPage);
                      }}
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </span>
                    <span
                      onClick={() => {
                        currentPage !== Math.ceil(length / itemsPerPage)
                          ? setCurrentPage(currentPage + 1)
                          : setCurrentPage(currentPage);
                      }}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                  </div> */}
                    </div>
                  </div>
                  {/* pagi */}
                </div>
              </div>
            </div>
            {/* Pagination start */}
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex align-items-end">
                <div className="Pagination mt-4 ">
                  <div className="nmbering d-flex">
                    {Array.from({
                      length: Math.ceil(allProductsArray.length / itemsPerPage),
                    }).map((_, index) => (
                      <span
                        key={index}
                        className={
                          currentPage === index + 1 ? "active" : "pginte-curser"
                        }
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </span>
                    ))}
                  </div>
                  <div className="linepag">
                    <span></span>
                  </div>
                </div>
                <div className="left-right-arrow-pag">
                  <span
                    onClick={() => currentPage > 1 && paginate(currentPage - 1)}
                  >
                    <i
                      className="fa-solid fa-angle-left"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                  <span
                    onClick={() =>
                      currentPage <
                        Math.ceil(allProductsArray.length / itemsPerPage) &&
                      paginate(currentPage + 1)
                    }
                  >
                    <i
                      className="fa-solid fa-angle-right"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
            {/* Pagination end */}
            {/* Pagination end */}

            {/* <div className="text-center mt-3">
                    {visibleProducts < allProducts?.length && (
                      <button onClick={loadMoreProducts}>Load More</button>
                    )}
                  </div> */}
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100">
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light position-relative"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light">
                You are about to delete this product
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-danger"
                onClick={() => handleDelete(deletedId)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Modal title
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">..awdawdawdawd.</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
