import React, { useEffect, useState } from "react";
import Sidebar from "../Component/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash, faReply } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { baseUrl } from "../../assets/Utils/Ip";
import Swal from "sweetalert2";

export default function EditShade() {

  const [count, setCount] = useState(1);
  const [ShadeName, setShadeName] = useState(null);

  const handleCountplus = () => {
    if(count < 7){
    setCount(count+1);
    setColor([...color, { ColorCode: '#000000' }]); // Add a new color input
  }};

  const handleCountminus = () => {
    if(count > 1){
    setCount(count-1);
    setColor(color.slice(0, -1));
  }
  };
  const handleColorChange = (index, newColor) => {
    const oldColors = [...color];
    oldColors[index].ColorCode = newColor;
    setColor(oldColors);
  };

  const countnumber = Array.from({ length: count });
  const [color, setColor] = useState([{ ColorCode: '#000000' }]);

  const token = localStorage.getItem("root");
  const { id } = useParams();
  const navigate = useNavigate();
  const [variationData, setVariationData] = useState({
    shade_name: "",
    shade: "",
  });

  useEffect(() => {
    console.log("useEffect")
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-shade/${id}`);
        setVariationData(response.data);
        if(response.data.shade.startsWith("[")){
        setCount(JSON.parse(response.data.shade).length)
        console.log(1,JSON.parse(response.data.shade))
        setColor(JSON.parse(response.data.shade));}
        else{
          console.log(2,[{ColorCode:response.data.shade}])
        setColor([{ColorCode:response.data.shade}]);
        }

      } catch (error) {
        console.log(error,"errorr")
      }
    };

    fetchData(); // Call the function when the component mounts
  }, [id]);

  const handleSaveData = async (e) => {
    e.preventDefault();
    try {
      let data = {
        shade_name: variationData.shade_name.trim(),
        shade: JSON.stringify(color),
      };

      console.log(data);

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${baseUrl}/update-variation/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      const response = await axios.request(config);

      Swal.fire({
        icon: "success", // Use "success" for the icon
        title: "Edit Variation",
        text: "Your Variation Edit Successfully",
        customClass: {
          // Assign your custom class to the popup container
          container: "my-custom-popup",
        },
      });
      navigate("/Variation");
    } catch (error) {
      if (error?.response?.status === 409) {
        Swal.fire({
          icon: "error", // Use "success" for the icon
          title: "Variation with the same name exists",
          // text: "Your Category Edit Successfully",
          customClass: {
            // Assign your custom class to the popup container
            container: "my-custom-popup",
          },
        });

        return;
      }

      Swal.fire({
        icon: "error", // Use "success" for the icon
        title: "Error updating variation",
        // text: "Your Category Edit Successfully",
        customClass: {
          // Assign your custom class to the popup container
          container: "my-custom-popup",
        },
      });
    }
  };

  const handleNameChange = (e) => {
    // let regex = new RegExp("^[A-Za-z0-9\\s]+$");
    const value = e.target.value;
    if (value.length > 25) {
      return;
    }
    setVariationData({
      ...variationData,
      shade_name: e.target.value,
    });

    // if (value === "") {

    // }
  };
  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side variationsec">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Edit Shade</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/Variation"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>

            <div className="add-categorie-list">
              <FontAwesomeIcon color="#fff" icon={faPencil} />
              <span className="mx-2">Edit Shade</span>
            </div>
            <div className="addshape ">
              <form action="" onSubmit={handleSaveData}>
                <div className="grid-add2 d-flex align-items-center my-4">
                  <label
                    className="text-light d-block w-25
"
                    htmlFor=""
                  >
                    <span style={{ color: "#F7F09F" }}>*</span> Shade Name
                  </label>
                  <input
                    className="py-1 px-2 w-75"
                    type="text"
                    value={variationData.shade_name}
                    onChange={handleNameChange}
                  />
                </div>
                <div className="grid-add2 d-flex align-items-center my-4">
                  <label
                    className="text-light d-block w-25
"
                    htmlFor=""
                  >
                    <span style={{ color: "#F7F09F" }}>*</span>Select Shade
                  </label>
                {countnumber.map((_, index) => (
                <div key={index} className="color-mean">
                <input
                className="colortheme"
                type="color"
                value={color[index]?.ColorCode}
                onChange={(e) => handleColorChange(index, e.target.value)}
                />
                </div>
                ))
                }
                </div>
                {/* button + - */}
                <div id="field1">
                        <button
                          type="button"
                          id="add"
                          className="plus add-minus-btns"
                          onClick={handleCountplus}
                        >
                          +
                        </button>
                        <span>&nbsp;</span>
                        <button
                            type="button"
                            id="sub"
                            className="minus add-minus-btns"
                            onClick={handleCountminus}
                          >
                            -
                        </button>
                        
                      </div>
                
                <div className="d-flex justify-content-end mt-4">
                  <button
                    className="add-categorie-btn text-dark rounded-2 px-5 py-2 font-bebas"
                    type="submit"
                  >
                    Update shade
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        size="lg"
      >
        <div
          className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100 w-100"
          size="lg"
        >
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light my-3">
                You are about to delete this Category
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
