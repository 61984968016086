import React, { useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply, faPencil } from "@fortawesome/free-solid-svg-icons";

import Sidebar from "../Component/Sidebar";
import { baseUrl } from "../../assets/Utils/Ip";
import axios from "axios";
import Swal from "sweetalert2";

export default function CategoriesForm() {
  const token = localStorage.getItem("root");
  const { id } = useParams();
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState({
    category_name: "",
    meta_tag_title: "",
    meta_tag_description: "",
    meta_tag_keyword: "",
  });

  const handleSaveData = async (e) => {
    e.preventDefault();
    try {
      let data = {
        category_name: categoryData.category_name.trim(),
        meta_tag_title: categoryData.meta_tag_title,
        meta_tag_description: categoryData.meta_tag_description,
        meta_tag_keyword: categoryData.meta_tag_keyword,
      };

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${baseUrl}/category-update/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      const response = await axios.request(config);

      Swal.fire({
        icon: "success", // Use "success" for the icon
        title: "Edit Category",
        text: "Your Category Updated Successfully",
        customClass: {
          // Assign your custom class to the popup container
          container: "my-custom-popup",
        },
      });
      navigate("/Categories");
    } catch (error) {
      if (error?.response?.status === 409) {
        Swal.fire({
          icon: "error", // Use "success" for the icon
          title: "Updating Category",
          text: "Category with same name exists",
          customClass: {
            // Assign your custom class to the popup container
            container: "my-custom-popup",
          },
        });
        return;
      }
      Swal.fire({
        icon: "error", // Use "success" for the icon
        title: "Updating Category",
        text: "Error occured while updating category",
        customClass: {
          // Assign your custom class to the popup container
          container: "my-custom-popup",
        },
      });
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/get-categoryById/${id}`);
      setCategoryData(response.data);
    } catch (error) {}
  };

  // validation
  let regex;

  const handleName = (value) => {
    // regex = new RegExp("^[A-Za-z0-9\\s]+$");
    if (value.length > 25) {
      return;
    }

    setCategoryData({ ...categoryData, category_name: value });
  };
  const handlemetaname = (value) => {
    // regex = new RegExp("^[A-Za-z0-9\\s]+$");
    if (value.length > 25) {
      return;
    }

    setCategoryData({ ...categoryData, meta_tag_title: value });
  };
  const handlemetaDiscription = (value) => {
    // regex = new RegExp("^[A-Za-z0-9\\s]+$");
    if (value.length > 150) {
      return;
    }

    setCategoryData({ ...categoryData, meta_tag_description: value });
  };
  const handlemetaKeyword = (value) => {
    // regex = new RegExp("^[A-Za-z0-9\\s]+$");
    if (value.length > 100) {
      return;
    }

    setCategoryData({ ...categoryData, meta_tag_keyword: value });
  };

  useEffect(() => {
    fetchData(); // Call the function when the component mounts
  }, [id]);

  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>CaTEGORIES</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/Categories"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <div className="add-categorie-list mt-5">
              <FontAwesomeIcon color="#fff" icon={faPencil} />
              <span className="mx-2">Edit Category</span>
            </div>
            <form action="" onSubmit={handleSaveData}>
              <div className="grid-add d-grid align-items-center my-4">
                <label className="text-light d-block text-end" htmlFor="">
                  Category Name
                </label>
                <input
                  className="py-1 px-2"
                  type="text"
                  name="category_name"
                  value={categoryData.category_name}
                  onChange={(e) => {
                    handleName(e.target.value);
                  }}
                />
              </div>
              <hr className="text-light my-4" />
              <div className="grid-add d-grid my-4">
                <label className="text-light d-block text-end" htmlFor="">
                  Meta Tag Title
                </label>
                <input
                  className="py-1 px-2"
                  type="text"
                  name="meta_tag_title"
                  value={categoryData.meta_tag_title}
                  onChange={(e) => {
                    handlemetaname(e.target.value);
                  }}
                />
              </div>
              <hr className="text-light my-4" />

              <div className="grid-add d-grid my-4">
                <label className="text-light d-block text-end" htmlFor="">
                  Meta Tag Description
                </label>
                <textarea
                  rows="5"
                  className="py-1 px-2"
                  type="text"
                  name="meta_tag_description"
                  value={categoryData.meta_tag_description}
                  onChange={(e) => {
                    handlemetaDiscription(e.target.value);
                  }}
                ></textarea>
              </div>
              <hr className="text-light my-4" />

              <div className="grid-add d-grid my-4">
                <label className="text-light d-block text-end" htmlFor="">
                  Meta Tag Keyword
                </label>
                <textarea
                  rows="5"
                  className="py-1 px-2"
                  type="text"
                  name="meta_tag_keyword"
                  value={categoryData.meta_tag_keyword}
                  onChange={(e) => {
                    handlemetaKeyword(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="grid-add d-grid my-4">
                <label htmlFor=""></label>
                <input
                  className="fw-bold font-bebas  add-save-btn rounded-3 border-0 ms-auto"
                  type="submit"
                  value="Save"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
