import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import AboutTestimonial from "../Components/AboutTestimonial";
import Slider from "react-slick";
import Rating from "../Components/Rating";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import modallogo from "../assets/img/AboutLogo.png";
import cohibalighter from "../assets/img/bestSellingProduct1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faHourglass1,
  faStar,
  faTruckFast,
} from "@fortawesome/free-solid-svg-icons";
import "../assets/css/style.css";
import Subscribe from "../Components/Subscribe";
import { baseUrl } from "../assets/Utils/Ip";
import axios from "axios";
import { useContext } from "react";
import { CartContext } from "../Components/Cart";
import Swal from "sweetalert2";
import GetStars from "../Components/GetStars";
import conealer1 from "../assets/img/concealer1.png";
import conealer2 from "../assets/img/concealer2.png";
import conealer3 from "../assets/img/concealer3.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function Buynow() {
  const handleclasstyle = (event) => {
    const elements = document.querySelectorAll(".clickedContainerClass");
    elements.forEach((element) => {
      element.classList.remove("clickedContainerClass");
    });
    event.target.classList.add("clickedContainerClass");
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { updateQuantity, getProductById } = useContext(CartContext);
  const [colorNames, setColorNames] = useState([]);
  const [p_quantity, setQuantity] = useState();
  const [parentStars, setParentStars] = useState(null);
  const [currentRating, setCurrentRating] = useState(null);
  const [reviewEmail, setReviewEmail] = useState(null);
  const [reviewMessage, setReviewMessage] = useState(null);
  const [reviewTitle, setReviewTitle] = useState(null);
  const [reviewName, setReviewName] = useState(null);
  const [tips, setTips] = useState([]);
  const [toggle, setToggle] = useState(0);
  const [cartvalue, setcartvalue] = useState(1);
  const [products, setProducts] = useState([]);
  const [selectedColor, setSelectedColor] = useState(0);

  const { id } = useParams();

  const imgs = products?.gallery_images
    ? products?.gallery_images?.split(`,`)
    : [];

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3, // Show only 1 slide at a time
    slidesToScroll: 1,
    autoplay: true, // Enable auto sliding
    autoplaySpeed: 4000, // Set the time interval in milliseconds (e.g., 2000ms = 2 seconds)
  };
  const value = getProductById(products?.product_id);
  let count = value?.p_quantity;

  const handleShow = () => {
    console.log("add to cart", selectedColor);

    if (!selectedColor && products.shades[0]) {
      Swal.fire({
        icon: "error",
        title: "Select color before adding to cart",
        text: "",
      });

      return;
    }

    const price =
      products?.sale_price === "" || products?.sale_price === "0"
        ? products?.regular_price
        : products?.sale_price;

    let list = [];

    if (Array.isArray(selectedColor)) {
      list.push(...selectedColor);
    } else {
      list.push(selectedColor);
    }

    updateQuantity(
      {
        product_id: products?.product_id,
        product_name: products?.product_name,
        product_description: products?.description_1,
        price: price,
        main_image: products?.main_image,
        quantity: products?.quantity,
      },
      p_quantity,
      list
    );
    setShow(true);
  };

  const handleTitle = (value) => {
    if (value.length > 40) {
      return;
    } else {
      setReviewTitle(value);
    }
  };

  const handleThinking = (value) => {
    if (value.length > 255) {
      return;
    } else {
      setReviewMessage(value);
    }
  };

  const handleName = (value) => {
    if (value.length > 50) {
      return;
    } else {
      setReviewName(value);
    }
  };

  const handleEmail = (value) => {
    if (value.length > 100) {
      return;
    } else {
      setReviewEmail(value);
    }
  };

  const handleReviewSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isValidEmail = emailRegex.test(reviewEmail);

    if (
      (reviewTitle !== "" && !reviewTitle) ||
      !reviewMessage ||
      !reviewMessage ||
      !parentStars ||
      !reviewEmail ||
      !reviewName
    ) {
      Swal.fire({
        icon: "error",
        title: "All fields are required",
        text: "",
      });
      return;
    } else if (!isValidEmail) {
      Swal.fire({
        icon: "error",
        title: "Invalid email",
        text: "Write valid email",
      });
      return;
    }

    let data = JSON.stringify({
      product_id: id,
      review_title: reviewTitle,
      review_rating: parentStars,
      review_message: reviewMessage,
      name: reviewName,
      email: reviewEmail,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/create-review`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "sucess",
          title: "Review",
          text: "Your Review is added",
        });
        setReviewTitle("");
        setReviewEmail("");
        setReviewMessage("");
        setReviewName("");
        setParentStars("");
        // You can perform any additional actions with the response here.
      })

      .catch((error) => {});
  };

  const incrementQuantity = () => {
    if (p_quantity < products?.quantity) {
      setQuantity(p_quantity + 1);
    } else {
      Swal.fire({
        icon: "error",
        title: "Maximum limit reached",
        text: "",
      });
    }
  };

  const decrementQuantity = () => {
    setQuantity(p_quantity - 1);
  };

  const handleRatingChange = (newRating) => {
    setCurrentRating(newRating);
  };
  const handleStarsSelected = (selectedStars) => {
    setParentStars(selectedStars + 1);
  };

  const makeColorNamesUnique = (colo) => {
    const list = [];
    const list2 = [];

    colo.map((item, index) => {
      // console.log(item, list, index);
      if (list.includes(item.shade_name)) {
      } else {
        list.push(item.shade_name);
        list2.push(item);
      }
    });

    // console.log(list);

    return list2;
  };

  useEffect(() => {
    // Define the Axios configuration
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-productById/${id}`,
      headers: {},
    };

    // Make the Axios request within the useEffect
    axios
      .request(config)
      .then((response) => {
        setProducts(response.data);

        // "[{\"ColorCode\"

        const tips = response.data.tips.split(",");

        setTips(tips);

        const data = response?.data?.shades;
        // const data = JSON.parse(response?.data?.shades);

        const uniqueColorNames = makeColorNamesUnique(data);

        setColorNames(uniqueColorNames);
      })
      .catch((error) => {
        // Handle errors here.
      });

    // If you want to use this as a one-time request, you can omit the dependency array ([]) in useEffect.
  }, [id]);

  useEffect(() => {
    setQuantity(count || 1);
  }, [count]);
  const [clickedIndexes, setClickedIndexes] = useState([]);
  const [clickedContainer, setClickedContainer] = useState(false);

  // Function to handle click event for a specific index
  const handleClick = (index) => {
    setClickedIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((clickedIndex) => clickedIndex !== index);
      } else {
        return [...prevIndexes, index];
      }
    });

    setClickedContainer(!clickedContainer);
  };
  return (
    <div>
      <Header />

      <section className="buynowsec font-bebas">
        <div className="container-1640">
          <div className="toplinecon"></div>
          <div className="row buyrowone">
            <div className="col-lg-4 col-md-6 p-0">
              <div className="buyimg buyimg-resp-top">
                {toggle !== null ? (
                  <img
                    className="buyimg-resp-top"
                    src={baseUrl + `/` + imgs[toggle]}
                    alt=""
                  />
                ) : null}
                <div className="upcomingpro">
                  {/* <Slider {...settings}>
                    {console.log("imgsimgs", imgs)}
                    <div>
                      {imgs?.map((images, ind) => (
                        <img
                          key={ind}
                          src={baseUrl + `/` + images}
                          alt=""
                          onClick={() => setToggle(ind)}
                        />
                      ))}
                    </div>
                  </Slider> */}
                  {/* <h2>hbscdjadsj</h2> */}
                  <Slider {...settings}>
                    {imgs && imgs.length > 0 ? (
                      imgs.map((image, ind) => (
                        <img
                          key={ind}
                          src={baseUrl + "/" + image}
                          alt=""
                          onClick={() => setToggle(ind)}
                        />
                      ))
                    ) : (
                      <img
                        src={baseUrl + "/" + imgs[0]} // Assuming imgs is an array with only one image
                        alt=""
                        onClick={() => setToggle(0)}
                      />
                    )}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-6 mt-md-0 mt-5">
              <div className="d-flex flex-column justify-content-between productdetail h-100">
                <div className="border-bottom d-flex mb-2 pb-2 protitle-name">
                  <div className="d-flex gap-2 align-items-center flex-lg-row flex-column">
                    <h2 className="m-0 theme-text-color font-poppins me-3">
                      {products?.product_name}
                    </h2>
                    <div className="star-rating me-lg-4 width-resp-100">
                      <GetStars
                        value={
                          products?.average_rating
                            ? products?.average_rating
                            : 0
                        }
                        color={"orange"}
                      />
                    </div>
                  </div>
                  <div className="rating-whishlist d-flex align-items-center">
                    <div className="cohib-add ">
                      <span></span>
                      <Link className="theme-text-yellow-light">
                        <p
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <FontAwesomeIcon color="orange" icon={faStar} /> Write
                          Review
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="prodetail-price">
                  <p className="theme-text-yellow-light font-poppins fs-4">
                    {products?.description_1}
                  </p>
                  <p className="fs-2 theme-text-color font-poppins m-0 fw-medium">
                    £
                    {products?.sale_price !== "" && products?.sale_price !== "0"
                      ? products?.sale_price
                      : products?.regular_price}{" "}
                    <small className="fs-7">incl. tax</small>
                  </p>
                  <h5 className="theme-text-color font-poppins">Shades</h5>
                  <div className="d-flex">
                    {products?.product_id == 250 ||
                    products?.product_id == 251 ||
                    products?.product_id == 258 ||
                    products?.product_id == 262 ? (
                      <>
                        <span className="concealer-before position-relative hover-class-shades-parent">
                          <img
                            src={conealer1}
                            className="shade-img-width hover-class-shades"
                            tabindex="0"
                            onClick={() => {
                              setSelectedColor([
                                "#78D4B2",
                                "#E9C593",
                                "#E9C593",
                              ]);
                            }}
                            alt=""
                          />
                          <span className="bg-theme-light-yellow fs-9 px-2 opacity-0 hover-color-show-two font-poppins ">
                            2 - New Buffed Cool
                          </span>
                        </span>
                        <span className="concealer-before position-relative hover-class-shades-parent">
                          <img
                            src={conealer2}
                            className="shade-img-width hover-class-shades"
                            tabindex="0"
                            onClick={() => {
                              setSelectedColor([
                                "#E9C593",
                                "#E9C593",
                                "#CD9C75",
                              ]);
                            }}
                            alt=""
                          />
                          <span className="bg-theme-light-yellow fs-9 px-2 opacity-0 hover-color-show-two font-poppins ">
                            {/* {color.shade_name} */}5 - Warm Front
                          </span>
                        </span>
                      </>
                    ) : products?.product_id == 253 ||
                      products?.product_id == 254 ||
                      products?.product_id == 259 ? (
                      <>
                        <span className="concealer-before position-relative hover-class-shades-parent">
                          <img
                            src={conealer3}
                            className="shade-img-width hover-class-shades"
                            tabindex="0"
                            onClick={() => {
                              setSelectedColor([
                                "#DFB6A9",
                                "#EFA396",
                                "#CDB2A9",
                              ]);
                            }}
                            alt=""
                          />
                          <span className="bg-theme-light-yellow fs-9 px-2 opacity-0 hover-color-show-two font-poppins ">
                            {/* {color.shade_name} */}3 Well Strobing Palette
                          </span>
                        </span>
                      </>
                    ) : (
                      <>
                        {colorNames &&
                          Array.isArray(colorNames) &&
                          colorNames.map((color, index) => (
                            <div className="me-2" key={index}>
                              <input
                                type="radio"
                                className="radio"
                                id={`radio-${index}`}
                                name="group"
                                onChange={(e) => {
                                  setSelectedColor(color.shade_color);
                                }}
                              />
                              {console.log(colorNames, "tyeye")}
                              <div
                                onClick={handleclasstyle}
                                style={{ zIndex: 3 }}
                              >
                                <label
                                  htmlFor={`radio-${index}`}
                                  style={{
                                    position: "relative",
                                    marginRight: "10px",
                                    display: "flex",
                                    padding: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {color.shade_color.startsWith("[") ? (
                                    JSON.parse(color.shade_color) // Limit to first three elements
                                      .map((colorset, index2) => (
                                        <>
                                          <span class="bg-theme-light-yellow fs-9 px-2  hover-color-show-two font-poppins hover-title ">
                                            {color.shade_name}
                                          </span>
                                          <span
                                            key={index2}
                                            style={{
                                              background: colorset.ColorCode,
                                              zIndex: -2,
                                            }}
                                            className={`label-shades hover-class-shades bg-theme-light-yellow font-poppins ${
                                              clickedIndexes.includes(index)
                                                ? "clicked1122"
                                                : ""
                                            }`}
                                            onClick={() => handleClick(index)}
                                          ></span>
                                        </>
                                      ))
                                  ) : (
                                    <>
                                      <span class="bg-theme-light-yellow fs-9 px-2  hover-color-show-two font-poppins hover-title ">
                                        {color.shade_name}
                                      </span>
                                      <span
                                        style={{
                                          background: color.shade_color,
                                          zIndex: -2,
                                        }}
                                        className={`label-shades hover-class-shades bg-theme-light-yellow font-poppins ${
                                          clickedIndexes.includes(index)
                                            ? "clicked1122"
                                            : ""
                                        }`}
                                        onClick={() => handleClick(index)}
                                      ></span>
                                    </>
                                  )}
                                </label>
                              </div>
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                  <p className="text-red font-poppins fw-bold fs-3">
                    LIVE <FontAwesomeIcon icon={faCamera} />
                  </p>
                  <Link
                    className="fs-4 btn-gradient text-dark rounded px-5 fw-bold position-relative"
                    to=""
                  >
                    Try On
                  </Link>
                  {/* <div className="d-flex font-poppins align-items-center mt-4">
                     <FontAwesomeIcon
                      className="theme-text-color fs-3 me-2"
                      icon={faTruckFast}
                    />
                    <div>
                      <p className="theme-text-color m-0 fs-5 -line-height">
                        Free Shipping
                      </p>
                      <span className="theme-text-yellow-light fs-7">
                        Free standard shipping on orders over £45.{" "}
                      </span>
                    </div>
                   </div> */}
                </div>

                {products?.quantity != null && products?.quantity > 0 ? (
                  <div className="d-flex align-items-baseline gap-3">
                    <div className="addtocart">
                      <Link onClick={handleShow}>Add to cart</Link>
                    </div>

                    <div className="quantity-parent-prdct brdr-left-prdct">
                      <div id="field1">
                        <button
                          type="button"
                          id="add"
                          className="plus add-minus-btns"
                          onClick={incrementQuantity}
                        >
                          +
                        </button>
                        <input
                          type="number"
                          value={p_quantity ? p_quantity : 1}
                          min={1}
                          max={products?.quantity}
                          className="quantity input-add-prdct"
                          readOnly
                          style={{ color: "black" }}
                        />
                        {p_quantity > 1 && (
                          <button
                            type="button"
                            id="sub"
                            className="minus add-minus-btns"
                            onClick={decrementQuantity}
                          >
                            -
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="addtocart">
                    <Link>OUT OF STOCK</Link>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row Description font-poppins mt-5">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item bg-transparent theme-text-color border-0">
                <h2
                  className="accordion-header bg-transparent theme-text-color border-0"
                  id="flush-headingOne"
                >
                  <button
                    className="gap-3 accordion-button collapsed bg-transparent theme-text-color fs-4 pb-2 w-15"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Description
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body pt-0"
                    dangerouslySetInnerHTML={{
                      __html: products?.description_2,
                    }}
                    style={{ color: "#FFE48E" }}
                  ></div>
                </div>
              </div>
              <div className="accordion-item bg-transparent theme-text-color border-0">
                <h2
                  className="accordion-header bg-transparent theme-text-color border-0"
                  id="flush-headingOne"
                >
                  <button
                    className="accordion-button collapsed bg-transparent theme-text-color fs-4 pb-2 w-15"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Tips
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body pt-0">
                    {Array.isArray(tips) &&
                      tips.map((item) =>
                        item.trim().length !== 0 ? (
                          <li
                            className="list-des theme-text-yellow-light"
                            style={{ color: "#FFE48E" }}
                            key={item} // Add a key if `item` is not a unique string or number
                          >
                            {item}
                          </li>
                        ) : (
                          <React.Fragment key={item} />
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AboutTestimonial />
      {/* <RelatedProducts /> */}
      <Subscribe />
      <Footer top={5} />

      {/* modale 1 */}
      <div
        class="modal cohibeproduct fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <span className="modallogo">
                <img src={modallogo} alt="" />
              </span>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa-solid fa-circle-xmark"></i>
              </button>
            </div>
            <div class="modal-body">
              <div className="cohibamodalcontetn">
                <div>
                  <img src={`${baseUrl}/${products?.main_image}`} />
                </div>
                <div className="managerating">
                  <h2>{products?.product_name}</h2>
                  <Rating
                    onStarsSelected={handleStarsSelected}
                    rating={currentRating}
                    onRatingChange={handleRatingChange}
                  />
                </div>
              </div>

              <div className="cohibinput">
                <input
                  type="text"
                  placeholder="Review Title"
                  value={reviewTitle}
                  onChange={(e) => handleTitle(e.target.value)}
                />
                <textarea
                  cols="30"
                  rows="10"
                  placeholder="What did you think about this product?"
                  value={reviewMessage}
                  onChange={(e) => handleThinking(e.target.value)}
                ></textarea>

                <input
                  type="text"
                  required
                  placeholder="Name"
                  value={reviewName}
                  onChange={(e) => handleName(e.target.value)}
                />
                <input
                  type="email"
                  placeholder="Email Address"
                  value={reviewEmail}
                  onChange={(e) => handleEmail(e.target.value)}
                />
              </div>
              <div className="btnaggrement">
                <p>
                  By continuing you agree to our
                  <span className="px-1">
                    <Link>Terms and Conditions</Link>
                  </span>
                  <span>and</span>
                  <span>
                    <Link to="/Privacy-Policy"> Privacy Policy </Link>
                  </span>
                </p>
                <Link
                  class="btn btn-secondary fw-bolder"
                  data-bs-dismiss="modal"
                  onClick={handleReviewSubmit}
                >
                  Agree & Submit
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modale 2 */}
      <div
        class="modal cohibeproduct fade"
        id="exampleModal1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <span className="modallogo">
                <img src={modallogo} />
              </span>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa-solid fa-circle-xmark"></i>
              </button>
            </div>
            <div class="modal-body">
              <div className="cohibamodalcontetn">
                <div>
                  <img src={cohibalighter} />
                </div>
                <div className="managerating font-poppins">
                  <h2>BB Cream</h2>
                </div>
              </div>

              <div className="cohibinput">
                <textarea
                  cols="30"
                  rows="10"
                  placeholder="What would you know about Cohiba Lighter ?"
                ></textarea>
                <input type="text" placeholder="Name" required />
                <input type="email" placeholder="Email Address" />
              </div>
              <div className="btnaggrement">
                <p>
                  By continuing you agree to our
                  <span>
                    <Link>Terms and Conditions</Link>
                  </span>
                  <span className="ps-1">and</span>
                  <span>
                    <Link> Privacy Policy </Link>
                  </span>
                </p>
                <Link
                  to="/buynow"
                  class="btn btn-secondary fw-bolder"
                  data-bs-dismiss="modal"
                >
                  Agree & Submit
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="accountcreated continuemodl addtocart-modal"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="pb-3">
          <div className="prnt-header-modal">
            <h2 className="mb-0">{products?.product_name}</h2>
            <Button onClick={handleClose} className="p-0">
              <i class="fa-solid fa-circle-xmark"></i>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-lg-0 px-md-3">
            <div className="col-md-5 p-0 prnt-img-col">
              <img src={`${baseUrl}/${products?.main_image}`} alt="Smoke" />
            </div>
            <div className="col-md-7">
              <div className="mx-2 my-4">
                <h2>
                  <i class="fa-solid fa-check"></i> {products?.product_name} was
                  added to <br />
                  your shopping cart.
                </h2>

                {products?.sale_price == 0 ? (
                  <p class="pricesku">
                    £{cartvalue * products?.regular_price}{" "}
                    <span>incl. tax</span>
                  </p>
                ) : (
                  <p class="pricesku">
                    £{cartvalue * products?.sale_price} <span>incl. tax</span>
                  </p>
                )}
              </div>
              <div class="signinbtn d-flex">
                <Link to="/shop" className="me-4 px-4 py-2">
                  <i class="fa-solid fa-chevron-left"></i> Continue Shopping
                </Link>
                <Link to={"/cart"} className="px-4 py-2">
                  Proceed to checkout
                </Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Buynow;
