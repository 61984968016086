import React from "react";
import ComingSoonBanner from "../Components/ComingSoonBanner";
import Header from "../Components/Header/Header";
import Subscribe from "../Components/Subscribe";
import Footer from "../Components/Footer/Footer";
import Loader from "../Components/Loader";

const Blogs = () => {
  return (
    <div className="blog-hover">
      <Loader />
      <Header />
      <ComingSoonBanner />
      <Subscribe />
      <Footer top={5} />
    </div>
  );
};

export default Blogs;
