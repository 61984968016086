import React from "react";
// import { Link } from "react-router-dom";
function Copyright() {
  return (
    <div className="warning copyright" style={{ justifyContent: "center" }}>
      <p className="m-0 text-center">
        © 2024 Nisa Cosmetics London | All Rights Reserved
        {/* | <Link className="text-dark" to="https://webdesignjack.com/" target="blank"> Who Design This Website ? </Link> */}
      </p>
    </div>
  );
}

export default Copyright;
