import React, { useContext, useEffect, useState } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import { CartContext } from "../Components/Cart";
import { baseUrl } from "../assets/Utils/Ip";
import axios from "axios";

function Cart() {
  const {
    cartItems,
    getCartTotal,
    CancelProduct,
    getCartProductIds,
    incrementQuantity,
    decrementQuantity,
  } = useContext(CartContext);
  const [allProductIds, setAllProductIds] = useState([]);

  const UserID = localStorage.getItem("userId");
  const minValue = 1;
  const maxValue = 50;

  const handleClick = (i) => {
    CancelProduct(i);
  };

  useEffect(() => {
    setAllProductIds(getCartProductIds());
  }, [cartItems]);

  const verifyProductQuantity = async () => {
    try {
      if (allProductIds.length > 0) {
        const res = await axios.post(`${baseUrl}/get-all-productIds`, {
          productIds: allProductIds,
        });

        return res?.data;
      } else {
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching product information:", error);
    }
  };
  const handleIncrement = async (item) => {
    try {
      // Verify product quantity
      const verifiedProductQuantities = await verifyProductQuantity();

      if (verifiedProductQuantities && verifiedProductQuantities.length > 0) {
        // Find the verified quantity for the current item
        const verifiedQuantityObject = verifiedProductQuantities.find(
          (verifiedItem) => verifiedItem.product_id === item.product_id
        );

        // Convert the quantity to a number
        const verifiedQuantity = verifiedQuantityObject
          ? Number(verifiedQuantityObject.quantity)
          : 0;

        // Compare the quantity before incrementing
        if (item.p_quantity >= verifiedQuantity) {
          // Show an error or handle it accordingly

          Swal.fire({
            icon: "error",
            title: "Maximum limit reached",
            text: "",
          });
        } else {
          incrementQuantity(item);
        }
      }
    } catch (error) {
      // Handle any errors
      console.error("Error in handleIncrement:", error);
    }
  };

  // useEffect to call verifyProductQuantity when allProductIds change
  useEffect(() => {
    verifyProductQuantity();
  }, [allProductIds]);

  return (
    <div className="font-bebas">
      <Header />
      <div className="container-1640">
        <div className="toplinecon">
          <div className="hamicons">
            <ul>
              <li>
                <Link className="theme-text-yellow-light">Home</Link>
              </li>
              <li>
                <Link className="theme-text-yellow-light">
                  <i class="fa-solid fa-chevron-right"></i>
                </Link>
              </li>
              <li>
                <Link className="theme-text-yellow-light" to="/shop">
                  Shop
                </Link>
              </li>
              <li>
                <Link className="theme-text-yellow-light">
                  <i class="fa-solid fa-chevron-right"></i>
                </Link>
              </li>
              <li>
                <Link className="theme-text-yellow-light">Product Name</Link>
              </li>
              <li>
                <Link className="theme-text-yellow-light">
                  <i class="fa-solid fa-chevron-right"></i>
                </Link>
              </li>
              <li>
                <Link className="theme-text-yellow-light">Shopping Cart</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <section className="cartsection1">
        <div className="container-1640">
          <h1 className="carttitle" style={{ color: "#FFE48E" }}>
            <i class="fa-solid fa-cart-shopping"></i> Your Shopping Cart
          </h1>

          { cartItems?.map((item, index) => (
          
            <div key={index} className="cartdiscription mb-4">
              {console.log(item,"item selected colors")}
              <i
                class="fa-solid fa-circle-xmark"
                onClick={() => handleClick(item)}
              ></i>
              <img
                src={`${baseUrl}/${item?.main_image}`}
                alt={`Product ${index}`}
              />
              <div className="">
                <h1 className="font-poppins m-0 theme-text-color position-relative">
                  {console.log(item,"item")}
                  {
                  item?.selectedColor.map((setcolor2,index3)=>(
                    String(setcolor2)?.startsWith("[")?JSON.parse(item?.selectedColor).map((colorset,index2)=>
                      <div key={index2}
                      className="color-box"
                      style={{ backgroundColor: `${colorset.ColorCode}`,marginRight:(((index2+1)*-25)+"px")}}
                    >
                      {console.log(typeof setcolor2 === "string","euien233")}
                    </div>
                    
                  ):<div
                  className="color-box"
                  style={{ backgroundColor: `${item?.selectedColor}` }}
                ></div>
                ))}

                  {item.product_name}
                </h1>
                <h1 className="font-poppins theme-text-yellow-light">
                  £{item.price} x {item.p_quantity}
                </h1>

                <div class="addtocart">
                  <div className="quantity-parent-prdct brdr-left-prdct">
                    <div id="field1">
                      <button
                        type="button"
                        id="add"
                        className="plus add-minus-btns"
                        onClick={() => handleIncrement(item)}
                      >
                        +
                      </button>
                      <input
                        type="number"
                        value={item.p_quantity}
                        min={minValue}
                        max={maxValue}
                        className="quantity input-add-prdct"
                        readOnly
                      />
                      <button
                        type="button"
                        id="sub"
                        className="minus add-minus-btns"
                        onClick={() => decrementQuantity(item)}
                      >
                        -
                      </button>

                      {/* <Link to="/checkout">Checkout</Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="subtotal">
            <h6>Sub Total:</h6>
            <p>£{parseFloat(getCartTotal()).toFixed(2)}</p>
            {getCartTotal() === 0 ? (
              <div className="subtotalbtn mt-4">
                <Link to="/shop">
                  <i class="fa-solid fa-chevron-left"></i> Continue Shopping
                </Link>
              </div>
            ) : (
              <div className="subtotalbtn mt-4">
                <Link to={UserID ? "/shipping-cart" : "/checkout"}>
                  <i class="fa-solid fa-cart-shopping"></i> Checkout
                </Link>
                <Link to="/shop">
                  <i class="fa-solid fa-chevron-left"></i> Continue Shopping
                </Link>
              </div>
            )}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Cart;
