import React, { useEffect, useState } from "react";
import Sidebar from "../Component/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListUl,
  faPlus,
  faTrash,
  faReply,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { baseUrl } from "../../assets/Utils/Ip";
import Swal from "sweetalert2";

export default function DeletedVariation() {
  const navigate = useNavigate();
  const [visibleProducts, setVisibleProducts] = useState(10);
  const [selectedvariation, setSelectedvariation] = useState([]); // Step 1
  const [variation, setVariation] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${baseUrl}/get-all-deleted-variation`,
        };

        const response = await axios.request(config);

        setVariation(response?.data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const handleRestore = (variationId) => {
    // Inside the handleDelete function, place your Axios request
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${baseUrl}/restore-variation/${variationId}`, // Replace with the appropriate URL
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "success", // Use "success" for the icon
          title: "Variations restored successfully ",
          text: "Your Variation has been restored successfully",
        });
        navigate("/Variation");
        // Handle success, e.g., show a success message
        setVariation((prevProd) =>
          prevProd.filter((variation) => variation.shade_id !== variationId)
        );
      })
      .catch((error) => {});
  };

  const displayedvariation = variation?.slice(0, visibleProducts);
  const handleSelectAll = () => {
    setSelectedvariation(variation?.map((variation) => variation?.shade_id)); // Step 2
  };

  const handleDeselectAll = () => {
    setSelectedvariation([]); // Step 3
  };

  const handleBrandSelect = (variationId) => {
    // Step 4
    if (setSelectedvariation.includes(variationId)) {
      setSelectedvariation(
        setSelectedvariation.filter((id) => id !== variationId)
      );
    } else {
      setSelectedvariation([...setSelectedvariation, variationId]);
    }
  };

  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side variationsec">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Deleted Variation</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-yellow w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/AddCategories"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Link>
                  <Link
                    className="p-3 justify-content-center bg-yellow w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to=""
                  >
                    <FontAwesomeIcon color="red" icon={faTrash} />
                  </Link>
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/Variation  "
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>

            <div className="add-categorie-list">
              <FontAwesomeIcon color="#fff" icon={faListUl} />
              <span className="mx-2">Variation List</span>
            </div>
            <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                <div className="categories-parent-table-box">
                  <div className="brand-categorie-table-box w-100 align-items-center rounded-3 my-3">
                    <div className="text-center">
                      <input
                        type="checkbox"
                        onChange={() =>
                          selectedvariation?.length < variation?.length
                            ? handleSelectAll()
                            : handleDeselectAll()
                        }
                        checked={selectedvariation.length === variation?.length}
                      />
                    </div>
                    <div className=" d-flex align-items-center p-3">
                      <p className="mx-3">Category</p>
                    </div>

                    <div className="border-end p-3 ">
                      <p>Shade Name</p>
                    </div>
                    <div className="p-3 text-center">
                      <p>Action</p>
                    </div>
                  </div>
                  {displayedvariation?.map((variation) => (
                    <div
                      key={variation.shade_id}
                      className="brand-categorie-table-box-sec text-light w-100 align-items-center rounded-3 my-2"
                    >
                      <div className="text-center">
                        <input
                          type="checkbox"
                          onChange={() => handleBrandSelect(variation.shade_id)}
                          checked={selectedvariation.includes(
                            variation.shade_id
                          )}
                        />
                      </div>
                      <div className=" d-flex align-items-center justify-content-between py-1 ps-4 pd-1">
                        <span
                          className="shadecolor ml"
                          style={{ background: variation.shade }}
                        ></span>
                      </div>

                      <div className="border-end p-3">
                        <p>{variation.shade_name}</p>
                      </div>
                      <div className="p-2 px-3 text-center">
                        <Link
                          className="add-categorie-btn text-dark rounded-2 px-5 py-2 font-bebas"
                          onClick={() => handleRestore(variation.shade_id)}
                        >
                          Restore
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        size="lg"
      >
        <div
          className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100 w-100"
          size="lg"
        >
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light my-3">
                You are about to delete this Category
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
