import React from "react";
import Header from "../Components/Header/Header";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer/Footer";

const RefundPolicy = () => {
  return (
    <div>
      <Header />
      <div className="bef-aft">
        <section className="policiessection">
          <div className="container-1640 pt-3">
            <div className="refundcontent">
              <h1 class="carttitle">Refund Policy</h1>
              <div>
                <p className="font-poppins text-white">
                  We want you to be completely satisfied, so we will gladly
                  accept returns in accordance with the Return Policy:
                </p>
              </div>
              <p className="paratitle bold" style={{ marginBottom: "20px" }}>
                Defective, damaged or incorrect items:
              </p>
              <p className="paratitle">
                All returns and exchanges must be pre-approved by Customer
                Service and must include a return authorization number. Please
                email us at info@nisacosmeticslondon.com if you have any
                questions. To receive your return authorization and instructions
                (if applicable).
              </p>
              <p className="paratitle">
                If you received a damaged or defective item from us, or if the
                incorrect item was shipped to you, you will not be charged for
                return shipping, and we will not charge any restocking or other
                fees in connection with the return. In addition, as described in
                our Refund Procedures, you will receive a full refund for the
                item.
              </p>
              <p className="paratitle">
                To obtain a refund, the item must be returned to us within 15
                days of the date we provided the item to the carrier for
                shipment to you, unopened (unless the item was received by you
                in a defective or damaged condition), in its original packaging,
                with all accessories. You must also adhere to the Return
                Procedures outlined below.
              </p>
              <p className="paratitle">
                We cannot accept items that have been opened or used unless they
                were damaged or defective when you received them.We cannot
                accept items that have been opened or used unless they were
                damaged or defective when you received them.
              </p>
              <p className="paratitle">
                Items that are damaged or defective and are returned without
                their original packaging will not be refunded.
              </p>

              <p className="paratitle bold" style={{ marginBottom: "20px" }}>
                Procedures for Refunds
              </p>
              <p className="paratitle">
                If you are eligible for a refund as a result of a return, the
                following conditions will apply:
              </p>
              <p className="paratitle">
                Your refund will be issued in the same method of payment that
                you used to make the purchase.
              </p>
              <p className="paratitle">
                The amount refunded will be the purchase price paid by you for
                the returned item (which includes any discounts, promotions, or
                coupons you received), plus any applicable sales tax.
              </p>
              <p className="paratitle">
                Credits will be issued once your return has been received,
                inspected, and processed. Please allow time for the returned
                item to arrive at our office, for us to inspect and process the
                return, and for the credit card company to credit your account.
                When the credit will appear on your statement is determined by
                your credit card company. The credit may not appear on your
                account for one billing cycle.
              </p>

              <p className="paratitle bold" style={{ marginBottom: "30px" }}>
                Code of Ethics
              </p>

              <p className="paratitle">
                You agree to abide by the following standards of conduct by
                accessing or using any Services, including but not limited to
                when submitting any of Your Content to the Services. You agree
                that you will not, and will not authorize or facilitate any
                other person’s or entity’s attempt to:
              </p>
              <p className="paratitle">
                Post any information that you believe is false, inaccurate, or
                misleading.
              </p>
              <p className="paratitle">
                Post or transmit any content that, in whole or in part, is
                unlawful, harmful, threatening, abusive, harassing, defamatory,
                vulgar, offensive, obscene, pornographic, lewd, lascivious, or
                otherwise objectionable, as determined by us, or that interferes
                with anyone’s use or enjoyment of the Services.
              </p>
              <p className="paratitle">
                Post or send defamatory, libellous, or slanderous material.
              </p>
              <p className="paratitle">
                Post or transmit any content that infringes on the copyright,
                trademark, right of publicity, or other proprietary rights of
                another person or entity, or that violates any applicable law.
                Anyone found to have repeatedly violated the intellectual
                property or other rights of others by us, in our sole
                discretion, will be barred from submitting or posting any
                further material on the Services.
              </p>
              <p className="paratitle">
                Post any content that is against the law, statute, ordinance, or
                regulation (including, but not limited to, those governing
                export control, consumer protection, unfair competition,
                anti-discrimination, and false advertising).
              </p>
              <p className="paratitle">
                Post any content that depicts a health condition in a derogatory
                or inflammatory manner, or that in any way misrepresents a
                health condition.
              </p>
              <p className="paratitle">
                Post any content for which you have been compensated or
                otherwise compensated by a third party.
              </p>
              <p className="paratitle">
                Any third-party websites, addresses, email addresses, contact
                information, or phone numbers should be posted.
              </p>
              <p className="paratitle">
                Reproduce, duplicate, copy, sell, resell, or exploit any portion
                of the Services, or use or access to the Services, for
                commercial purposes.
              </p>
              <p className="paratitle">
                Post unsolicited advertising or promote products or services
                illegally, including through unsolicited email advertisements.
              </p>
              <p className="paratitle">
                Harass, threaten, stalk, or purposefully embarrass or distress
                another person or entity.
              </p>
              <p className="paratitle">
                Take on the identity of another person or entity.
              </p>
              <p className="paratitle">
                Any multilevel marketing or pyramid scheme should be promoted,
                solicited, or participated in.
              </p>
              <p className="paratitle">
                Solicit or exploit personally identifiable information from
                children.
              </p>
              <p className="paratitle">
                Engage in disruptive behaviour, such as sending multiple
                messages in an attempt to monopolize the forum or posting on a
                topic or theme that is unrelated to the forum’s designated topic
                or theme.
              </p>
              <p className="paratitle">
                Introduce viruses, worms, Trojan horses, malicious code, or any
                other software or materials containing a component that is
                harmful to the Site.
              </p>
              <p className="paratitle">
                Obtain unauthorised access to any computer system or non-public
                portion of the Services, or interfere with or disrupt the
                Services or the servers or networks to which the Services are
                connected.
              </p>
              <p className="paratitle">
                Invade anyone’s privacy by posting personally identifying or
                otherwise private or sensitive information about them without
                their consent, or harvesting personally identifiable information
                about the Services’ users.
              </p>
              <p className="paratitle">
                Engage in, encourage, advocate for, provide instructions for, or
                discuss conduct that would constitute a criminal or civil
                offence or would otherwise violate any federal, state, local, or
                international law or regulation.
              </p>
              <p className="paratitle">
                We cannot and do not guarantee that other users will comply with
                the foregoing Code of Conduct or any other provisions of these
                Terms, and you hereby assume all risk of harm or injury
                resulting from any such failure to comply.
              </p>

              <p className="paratitle bold" style={{ marginBottom: "30px" }}>
                Copyright Protection
              </p>

              <ul className="policy-ul font-poppins text-white">
                <li>
                  Identification of the allegedly infringed copyrighted work
                  please describe the work and include a copy or the location
                  (e.g., URL) of an authorized version of the work where
                  possible.
                </li>
                <li>
                  Identification of the allegedly infringing material and its
                  location please describe the material and provide its URL or
                  any other relevant information that will help us locate it.
                </li>
                <li>
                  Name, address, phone number, and e-mail address (if
                  available).
                </li>
                <li>
                  A statement stating that you have a good faith belief that the
                  use of the materials complained about is not authorized by the
                  copyright owner, its agent, or the law.
                </li>
                <li>
                  A statement that the information you have provided is correct,
                  and that you are the copyright owner or are authorized to act
                  on the copyright owner’s behalf “under penalty of perjury.”
                </li>
                <li>
                  A copyright holder’s or authorized representative’s signature
                  or its electronic equivalent.
                </li>
              </ul>

              <p className="paratitle">
                To protect the rights of copyright holders, we maintain a policy
                that allows us to terminate repeat infringers in appropriate
                circumstances.
              </p>

              <p className="paratitle bold" style={{ marginBottom: "20px" }}>
                Contact Us
              </p>
              <p className="paratitle">
                If these Terms do not address your questions, you can contact us
                via email at
                <Link className="mx-2" style={{ color: "#ffe48e" }}>
                  info@nisacosmeticslondon.com,
                </Link>
                or inquiry form or live chat.
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default RefundPolicy;
