import { faCheck, faListUl, faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Sidebar from "../Component/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { baseUrl } from "../../assets/Utils/Ip";

function AddShipping() {
  const [shipping, setShipping] = useState({
    name: "",
    charges: "",
  });

  const token = localStorage.getItem("root");

  let regex;

  const navigate = useNavigate();

  const handleName = (e) => {
    // regex = new RegExp("^[A-Za-z ]+$");

    if (e.target.value === "") {
      setShipping({ ...shipping, name: "" });
    } else if (e.target.value.length >= 20) {
      return;
    }

    setShipping({ ...shipping, name: e.target.value });
  };

  const handleCharges = (e) => {
    regex = new RegExp("^[0-9]+(\\.[0-9]+)?$");

    if (e.target.value === "") {
      setShipping({ ...shipping, charges: "" });
    } else if (!regex.test(e.target.value)) {
      return;
    } else if (e.target.value.length <= 4) {
      setShipping({ ...shipping, charges: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!shipping.name || !shipping.charges) {
      Swal.fire({
        icon: "error", // Use "success" for the icon
        title: "Empty Fields",
        text: "Kindly fill all the fields",
        customClass: {
          // Assign your custom class to the popup container
          container: "my-custom-popup",
        },
      });
    }

    const charges = parseFloat(shipping.charges);

    try {
      const res = await axios.post(
        `${baseUrl}/create-shipping`,
        { shipping_name: shipping.name.trim(), shipping_charges: charges },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Adjust content type if needed
          },
        }
      );

      if (res.status === 201) {
        Swal.fire({
          icon: "success", // Use "success" for the icon
          title: "Method Created Successfully",
          text: "",
          customClass: {
            // Assign your custom class to the popup container
            container: "my-custom-popup",
          },
        });
      }

      setShipping({ name: "", charges: "" });

      navigate("/ManageShipping");
    } catch (err) {
      Swal.fire({
        icon: "error", // Use "success" for the icon
        title: "Error creating method",
        text: "",
        customClass: {
          // Assign your custom class to the popup container
          container: "my-custom-popup",
        },
      });

      return;
    }
  };

  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Manage Shipping</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/ManageShipping"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
              <div className="add-categorie-list mb-3 mb-5 pb-4 pt-4">
                <FontAwesomeIcon color="#fff" icon={faListUl} />
                <span className="mx-2">Add Shipping Method</span>
              </div>
            </div>
            <div className="edit-cust-grid">
              <div className="edit-cust-grid-inner text-end">
                <p>Shipping Method Name</p>
              </div>
              <div className="edit-cust-grid-inner">
                <input
                  type="text"
                  value={shipping.name}
                  onChange={(e) => handleName(e)}
                  placeholder="Shipping Method"
                />
              </div>
            </div>
            <hr className="hr-edit-cust bg-white" />
            <div className="edit-cust-grid">
              <div className="edit-cust-grid-inner text-end">
                <p>Shipping Charges</p>
              </div>
              <div className="edit-cust-grid-inner">
                <input
                  type="number"
                  value={shipping.charges}
                  onChange={(e) => handleCharges(e)}
                  placeholder="£10.00"
                />
              </div>
            </div>
            <div className="grid-add d-grid my-4">
              <label htmlFor=""></label>
              <button
                className="fw-bold add-save-btn rounded-3 border-0 ms-auto"
                onClick={handleSubmit}
              >
                Add
              </button>
              {/* <input
                className="fw-bold add-save-btn rounded-3 border-0 ms-auto"
                onClick={handleSubmit}
                value="Save"
              /> */}
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      {/* <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100">
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <Link
                to="/ManageReviews"
                className="btn-close text-dark position-relative bg-yellow d-flex align-items-center justify-content-center rounded-pill"
                data-bs-dismiss="modal"
              >
                X
              </Link>
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="green" fontSize="80px" icon={faCheck} />
              <h1 className="text-light">Shipping Method has been Generated</h1>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
}

export default AddShipping;
