import React from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { baseUrl } from "../assets/Utils/Ip";

function Subscribe() {
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    if (e.target.value === "") {
      setEmail("");
    } else if (e.target.value.length >= 50) {
      return;
    } else {
      setEmail(e.target.value);
    }
  };

  const handleSubmit = async () => {
    const regex = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$");

    if (!regex.test(email)) {
      Swal.fire({
        icon: "error",
        title: "Invalid email",
        text: "",
      });
      return;
    } else {
      try {
        const res = await axios.post(`${baseUrl}/add-newsletter`, {
          user_email: email,
        });

        if (res.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Email added successfully",
            text: "",
          });

          window.scrollTo(0, 0);

          setEmail("");
          return;
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Error Sending Email",
          text: "",
        });
      }
    }
  };

  return (
    <section className="home-subscirbe-section font-bebas">
      <div className="container-1640">
        <div className="row">
          <div className="col-lg-12">
            <div className="prnt-subscirbe-box">
              <h1>BEAUTY NEWS</h1>
              <p className="font-poppins">
                Be the first to know about the latest halal makeup launches and
                beauty secrets.
              </p>
              <div className="prnt-field">
                <input
                  className="font-poppins"
                  type="text"
                  placeholder="Your Email Address"
                  value={email}
                  onChange={(e) => handleChange(e)}
                />
                <button onClick={handleSubmit} required>
                  SEND
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Subscribe;
