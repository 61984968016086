import { faPencil, faTrash, faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Editor from "../Component/CKeditor";
import ImageUploader from "../Component/ImageUploader";
import Sidebar from "../Component/Sidebar";
import { MultiSelect } from "react-multi-select-component";
import { baseUrl } from "../../assets/Utils/Ip";
import axios from "axios";
import Swal from "sweetalert2";

export default function EditProduct() {
  const token = localStorage.getItem("root");
  const [variation, setVariation] = useState([]);
  const [isChecked, setisChecked] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const imageRef = useRef();
  const [category, setCategory] = useState([]);

  const [selectedImages, setSelectedImages] = useState([]);

  const navigate = useNavigate();
  const prodId = useParams();
  const [selected, setSelected] = useState([]);
  const [product, setProduct] = useState({
    product_name: "",
    category_id: "",
    description_1: "",
    description_2: "",
    regular_price: "",
    sale_price: "",
    quantity: "",
    tips: "",
    gallery_images: [],
  });

  // validation
  let regex;

  const handleName = (value) => {
    // regex = new RegExp("^[A-Za-z0-9\\s-]+$");
    if (value.length > 25) {
      return;
    }

    setProduct({ ...product, product_name: value });
  };
  const descriptionone = (value) => {
    // regex = new RegExp("^[A-Za-z\\s]+$");
    if (value.length > 90) {
      return;
    }

    setProduct({ ...product, description_1: value });
  };

  const regular = (value) => {
    regex = new RegExp("^[0-9]+(\\.[0-9]+)?$");
    if (value.length > 10) {
      return;
    }
    if (regex.test(value) || value === "") {
      setProduct({ ...product, regular_price: value });
    }
  };

  const sale = (value) => {
    regex = new RegExp("^[0-9]+(\\.[0-9]+)?$");
    if (value.length > 10) {
      return;
    }
    if (regex.test(value) || value === "") {
      setProduct({ ...product, sale_price: value });
    }
  };

  const quantity = (value) => {
    regex = new RegExp("^[0-9]+$");
    if (value.length > 5) {
      return;
    }
    if (regex.test(value) || value === "") {
      setProduct({ ...product, quantity: value });
    }
  };

  const extractIds = () => {
    let list = [];

    selected?.map((item) => {
      list.push(item.value);
    });

    return list;
  };

  const handleCheckBox = (e) => {
    setisChecked(e.target.checked);
  };

  const handleUpdate = async (e) => {
    console.log(product.gallery_images, "gallery images");

    e.preventDefault();

    if (
      product.gallery_images > 10 ||
      (!selectedImage && !product?.main_image)
    ) {
      Swal.fire({
        icon: "error", // Use "success" for the icon
        title: "Maximum 10 Images Required",
        text: "Kindly add Maximum of 10 images & 1 Main Image too",
      });
      return;
    } else if (product.gallery_images?.length < 3) {
      Swal.fire({
        icon: "error",
        title: "Minimum 3 Images Required",
        text: "Kindly add Minimum of 3 images & 1 Main Image too",
      });
      return;
    } else if (
      !product?.product_name ||
      !product?.category_id ||
      !product?.description_1 ||
      !product?.description_2 ||
      !data ||
      !product?.regular_price ||
      !product?.gallery_images
    ) {
      Swal.fire({
        icon: "error",
        title: "Empty Fields",
        text: "Kindly Fill All The Fields",
      });
      return;
    }

    try {
      const formData = new FormData();
      const items = extractIds();
      // Append product data to the FormData object
      formData.append("product_name", product.product_name);
      formData.append("category_id", product.category_id);
      formData.append("description_1", product.description_1);
      formData.append("description_2", data);
      formData.append("regular_price", product.regular_price);
      formData.append("sale_price", product.sale_price);
      formData.append("quantity", product.quantity);
      formData.append("shadeItems", items);
      formData.append("tips", product.tips);
      formData.append(
        "product_image",
        selectedImage ? selectedImage : product?.main_image
      );

      if (product?.gallery_images) {
        if (typeof product.gallery_images === "string") {
          let list = [];
          list = product.gallery_images.split(",");
          setProduct({ ...product, gallery_images: list });
          list?.map((item) => {
            // console.log("item item",item)
            formData.append(`gallery_images`, item);
          });
        } else {
          product?.gallery_images.forEach((elem, ind) => {
            formData.append(`gallery_images`, product.gallery_images[ind]);
          });
        }
      }

      if (parseFloat(product.sale_price) >= parseFloat(product.regular_price)) {
        Swal.fire({
          icon: "error", // Use "success" for the icon
          title: "Price not set properly",
          text: "Sale price should be less than regular price",
        });
      } else {
        const res = await axios.put(
          `${baseUrl}/update-product/${prodId.id}`,

          formData,

          {
            headers: {
              Authorization: `Bearer ${token}`,
              // Other headers if needed
            },
          }
        );

        Swal.fire({
          icon: "success",
          title: "Product updated",
          text: "ThankYou",
        });
        navigate("/AdminProduct");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "something went wrong",
        text: "Product not update",
      });
    }
  };

  const handleImageChange = (newImage) => {
    const { gallery_images, ...rest } = product;
    setProduct({ ...rest, gallery_images: newImage });
  };

  const handleImageUpload = (images) => {
  //   const maxFileSize = 5 * 1024 * 1024;
  //   const validImages = images.filter(image => {
  //     if (typeof image === 'object' && image.size) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "something went wrong",
  //         text: "Product not update",
  //       });
  //         return ;
  //     }
  //     return false;
  // });
    console.log("imagess",images)
    // setGalleryImage(images)
    setProduct({ ...product, gallery_images: images });
  };
 

  const handleTips = (e) => {
    if (e.target.value.length > 255) {
      return;
    } else {
      setProduct({ ...product, tips: e.target.value });
    }
  };

  const getAllCategory = async () => {
    try {
      const res = await axios.get(`${baseUrl}/get-all-category`);

      setCategory(res?.data);
    } catch (error) {}
  };

  const getVariation = async () => {
    try {
      const res = await axios.get(`${baseUrl}/get-all-shade`);

      const transformedData =
        res?.data?.map(({ shade_id: value, shade_name: label, ...rest }) => ({
          value,
          label,
          ...rest, // include the remaining properties
        })) || [];

      setVariation(transformedData);

      return transformedData;
    } catch (error) {
      // Handle error
    }
  };

  const check = async () => {
    try {
      await getVariation();

      // Axios request configuration
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/get-productById/${prodId.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Make the Axios request
      const response = await axios.request(config);

      if (response.data.shades.length <= 0) {
        setisChecked(false);
      } else {
        setisChecked(true);
      }
      setProduct(response.data);

      const shades = response?.data?.shades;

      const filteredVariations = [];
      // Filtering variations
      shades?.map((shade) => {
        filteredVariations.push({
          value: shade.shade_id,
          label: shade.shade_name,
        });
      });

      setSelected(filteredVariations);

      setSelectedImages(response.data.main_image);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    check();
  }, []);

  //sub categories useEffect
  useEffect(() => {
    getAllCategory();
  }, []);

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  return (
    <section className="bg-main font-poppins">
      <Sidebar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-3 p-md-5">
          <div className="blur-pages px-59">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Edit Products</h1>
                <div className="boxs-flex">
                  {/* <Link
                    className="p-3 justify-content-center bg-yellow w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to=""
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <FontAwesomeIcon color="red" icon={faTrash} />
                  </Link> */}
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/AdminProduct"
                  >
                    <FontAwesomeIcon icon={faReply} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <div className="add-categorie-list mt-5">
              <FontAwesomeIcon color="#fff" icon={faPencil} />
              <span className="mx-2">Edit Product</span>
            </div>
            <form action="" onSubmit={handleUpdate}>
              <div className="row">
                <div className="col-lg-8">
                  <div className="grid-add d-grid align-items-center my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      <span className="text-warning">*</span>Product Name
                    </label>
                    <input
                      className="py-1 px-2"
                      type="text"
                      name="product_name"
                      value={product.product_name}
                      onChange={(e) => {
                        handleName(e.target.value);
                      }}
                    />
                  </div>
                  <hr className="text-light my-4" />
                  <div className="grid-add d-grid align-items-center my-4">
                    <label
                      className="text-light d-block text-end"
                      htmlFor="category"
                    >
                      Select Category
                    </label>
                    <select
                      className="py-1 px-2 border Select-Category-wit"
                      name="category"
                      id="category"
                      value={product?.category_id}
                      onChange={(e) =>
                        setProduct({ ...product, category_id: e.target.value })
                      }
                    >
                      <option value="">Select Category</option>
                      {category?.map((category) => (
                        <option
                          key={category.category_id}
                          value={category.category_id}
                        >
                          {category.category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label
                      className="text-light d-block text-end p-"
                      htmlFor=""
                    >
                      Short Description
                    </label>
                    <textarea
                      name="description_1"
                      className="p-1"
                      rows="3"
                      defaultValue={product.description_1}
                      onChange={(e) => {
                        descriptionone(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Long Description
                    </label>
                    <Editor
                      name="description"
                      value={product.description_2}
                      onChange={(data) => {
                        setData(data);
                      }}
                      editorLoaded={editorLoaded}
                    />
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label
                      className="text-light d-block text-end p-"
                      htmlFor=""
                    >
                      Tips (Comma Seperated)
                    </label>

                    <input
                      className="py-1 px-2"
                      type="text"
                      value={product.tips}
                      onChange={(e) => {
                        handleTips(e);
                      }}
                    />
                  </div>
                  <hr className="text-light my-4" />
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Variation
                    </label>
                    <div className="text-start">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckBox}
                      />
                    </div>
                  </div>
                  {isChecked ? (
                    <div className="grid-add d-grid my-4">
                      <label
                        className="text-light d-block text-end"
                        htmlFor=""
                        style={{ opacity: "0" }}
                      >
                        Variation
                      </label>

                      <MultiSelect
                        options={variation}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                      />
                    </div>
                  ) : (
                    ""
                  )}{" "}
                  <hr className="text-light my-4" />
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      <span className="text-warning">*</span> Regular Price
                    </label>
                    <input
                      type="text"
                      name="regular_price"
                      value={product.regular_price}
                      onChange={(e) => {
                        regular(e.target.value);
                      }}
                    />
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Sale Price
                    </label>
                    <input
                      type="text"
                      name="sale_price"
                      value={product.sale_price}
                      onChange={(e) => {
                        sale(e.target.value);
                      }}
                    />
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Quantity
                    </label>
                    <input
                      type="text"
                      value={product.quantity}
                      className="py-1 px-2"
                      placeholder="5"
                      onChange={(e) => {
                        quantity(e.target.value);
                      }}
                    />
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label htmlFor=""></label>
                    <input
                      className="fw-bold add-save-btn rounded-3 border-0 ms-auto"
                      type="submit"
                      value="Save"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row">
                    <div className="border-bottom border-secondary col-lg-12 mb-3 pb-2">
                      {imageUrl && selectedImage ? (
                        <div className="mb-3" textAlign="center">
                          <img
                            ref={imageRef}
                            src={imageUrl}
                            alt={selectedImage.name}
                            width="100%"
                            className="border border-secondary p-1 rounded-3 prod-admin-img"
                          />
                        </div>
                      ) : (
                        <div className="mb-3" textAlign="center">
                          <img
                            src={`${baseUrl}/${product.main_image}`}
                            alt={selectedImage}
                            width="100%"
                            className="border border-secondary p-1 rounded-3 prod-admin-img height-mx-img"
                          />
                        </div>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        onChange={(e) => setSelectedImage(e.target.files[0])}
                      />
                      <label
                        htmlFor="select-image"
                        className="bg-gradient cursor-pointer mb-2 px-3 py-3 rounded-4 text-white "
                      >
                        {!imageUrl && !selectedImage
                          ? "Upload Featured Image"
                          : "Change Featured Image"}
                      </label>
                    </div>
                    <div className="col-lg-12">
                      <ImageUploader
                        onImagesChange={handleImageUpload}
                        title="Upload Multiple Images"
                        getGalleryImages={product.gallery_images}
                        onRemoveImage={handleImageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        size="lg"
      >
        <div
          className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100 w-100"
          size="lg"
        >
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light my-3">
                You are about to delete this Category
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        size="lg"
      >
        <div
          className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100 w-100"
          size="lg"
        >
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light my-3">
                You are about to delete this Category
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
