import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: ["Sales Analytics", "Position"],
    },
  },
};

const sortMonthlyData = (labels) => {
  return labels
    .sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateA - dateB;
    })
    .reverse();
};

function App({ data }) {
  const sortedData = sortMonthlyData(data.labels);

  const customData = {
    ...data,
    labels: sortedData,
    datasets: [
      {
        ...data.datasets[0], // Assuming you have only one dataset
        barThickness: 50, // Adjust the value according to your preference
      },
    ],
  };

  return <Bar options={options} data={customData} />;
}
export default App;
