import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import { loadSlim } from "tsparticles-slim";
import Particles from "react-particles";
// import bannersmoke from "../assets/video/bannersmoke.mp4";
import Banner1 from "../assets/img/banner-picture-1.png";
import Banner2 from "../assets/img/Banner-change1.png";
import Banner3 from "../assets/img/Banner-change-2.png";
import { Link } from "react-router-dom";
import Warning from "./Warning";
function Banner() {
  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {}, []);

  // Assuming you have an array of image sources
  const bannerImages = [Banner1, Banner2, Banner3];

  // State to track the current index of the bannerImages array
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to rotate the images
  const rotateImages = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerImages.length);
  };

  // Effect to handle the image rotation at the specified interval
  useEffect(() => {
    const intervalId = setInterval(rotateImages, 3000); // Adjust the interval time (in milliseconds) as needed

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures that the effect runs only once after the initial render

  return (
    <>
      <div className="top-banner w-100 ">
        <Warning />

        <section className="bannersec font-bebas">
          <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              background: {
                color: {
                  value: "#000000",
                },
              },
              particles: {
                number: {
                  value: 500,
                  density: { enable: true, value_area: 700 },
                },
                color: { value: "#e9af40" },
                shape: {
                  type: "circle",
                  // stroke: { width: 0, color: "#000000" },
                  // polygon: { nb_sides: 5 },
                },
                opacity: {
                  random: true,
                  anim: {
                    enable: true,
                    speed: 5.8,
                    opacity_min: 0.2,
                    sync: true,
                  },
                  value: 0.18,
                },
                size: {
                  value: 8,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 60,
                    size_min: 0.1,
                    sync: false,
                  },
                },
                line_linked: {
                  enable: false,
                  distance: 500,
                  color: "#ffffff",
                  opacity: 0.4,
                  width: 1,
                },
                move: {
                  enable: true,
                  speed: 6,
                  direction: "top",
                  random: true,
                  straight: false,
                  out_mode: "out",
                  bounce: false,
                  attract: {
                    enable: false,
                    rotateX: 1183.721462448409,
                    rotateY: 2683.101981549727,
                  },
                },
              },
              interactivity: {
                detect_on: "canvas",
                events: {
                  onhover: { enable: true, mode: "bubble" },
                  onclick: { enable: true, mode: "repulse" },
                  resize: true,
                },
                modes: {
                  grab: { distance: 400, line_linked: { opacity: 0.5 } },
                  bubble: {
                    distance: 400,
                    size: 4,
                    duration: 0.3,
                    opacity: 1,
                    speed: 3,
                  },
                  repulse: { distance: 200, duration: 0.4 },
                  push: { particles_nb: 4 },
                  remove: { particles_nb: 2 },
                },
              },
              background_color: "#000",
              retina_detect: true,
            }}
          />
          {/* <div id="particles-js" /> stats - count particles
           <div className="count-particles">
            <span className="js-count-particles">--</span> particles
          </div> */}
          <div class="content-banner position-relative vh-100 w-100 ">
            <div className="row align-items-center content-banner-row-gap ">
              <div className="col-lg-6 col-md-7 m-auto text-lg-start text-center">
                <h1 className="fs-150 m-0 theme-text-color">PREMIUM QUALITY</h1>
                <h4 className="mb-0 banner-sub-heading theme-text-yellow-light -mt-3">
                  makeup Products
                </h4>
                <p className="theme-text-yellow-light font-poppins banner-para banner-para-resp">
                  Find the perfect color match through a range of our makeup
                  products.
                  <br />
                  we are providing the best value for money to customers
                  globally. From fair to very <br /> deep and in warm, cool, or
                  neutral shades, we have them all for your beauty needs.
                </p>
                <Link
                  className="btn-master-home-page banner-btn-mob"
                  to="/Shop"
                >
                  Shop Now
                </Link>
              </div>
              <div className="col-lg-6 col-md-5 blank">
                <div className="d-flex align-items-center flex-column banner-image-responsive">
                  <img
                    className="shadow-animation banner-1-img"
                    src={bannerImages[currentIndex]}
                    alt="Premium Quality Banner Images"
                  />
                  <img
                    className="shadow-animation banner-2-img"
                    src={bannerImages[(currentIndex + 1) % bannerImages.length]}
                    alt="Premium Quality Banner Images"
                  />
                  <img
                    className="shadow-animation banner-3-img"
                    src={bannerImages[(currentIndex + 2) % bannerImages.length]}
                    alt="Premium Quality Banner Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="spacer"></div>
    </>
  );
}

export default Banner;
