import Header from "../Components/Header/Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from "../Components/Banner";
import Exquisite from "../Components/Exquisite";
import FeatureProduct from "../Components/FeatureProduct";
import Partner from "../Components/Partner";
import PopularProduct from "../Components/PopularProduct";
import Footer from "../Components/Footer/Footer";
import Offers from "../Components/Offers";
import Sponser from "../Components/Sponser";
import InstagramFeeds from "../Components/InstagramFeeds";
import Subscribe from "../Components/Subscribe";
import Loader from "../Components/Loader";

function Homepage() {
  return (
    <>
      <div className="home-hover tab-ovfX-hidden">
        <Loader />
        <Banner />
        <Header />

        <Exquisite />

        <FeatureProduct />

        <Partner />

        <PopularProduct />

        <Offers />

        <Sponser />

        <InstagramFeeds />

        <Subscribe />

        <Footer />
      </div>
    </>
  );
}

export default Homepage;
